import React from "react";
import { makeStyles } from "@material-ui/core";

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
    loaderContainer: {
        display: "flex",
        alignItems: "center",
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: "1999",
        background: "transparent",
    },
    loadingContent: {
        position: "absolute",
        left: "50%",
        top: "50%",
        marginTop: "-60px",
        marginLeft: "-60px",
    },
    loader: {
        border: "16px solid #f3f3f3",
        borderRadius: "50%",
        borderTop: "16px solid #3498db",
        width: "120px",
        height: "120px",
        // "-webkit-animation": "$spin 2s linear infinite", /* Safari */
        animation: "$spin 2s linear infinite",
    },
    /* Safari */
    // "@-webkit-keyframes spin": {
    //     "0%": { "-webkit-transform": "rotate(0deg)", },
    //     "100%": { "-webkit-transform": "rotate(360deg)", },
    // },
    "@keyframes spin": {
        "0%": { transform: "rotate(0deg)", },
        "100%": { transform: "rotate(360deg)", },
    },

}));
// **** useStyles ****


/*
 * **** component ****
 */
export const Loader = () => {
    const classes = useStyles();

    /*
     * render
     */
    return (
        <div id="loader-container" className={classes.loaderContainer}>
            <div className={classes.loadingContent}>
                <div className={classes.loader}></div>
                {/* <div className="text-center pt-2">見守り...</div> */}
            </div>
        </div>
    );

}
// **** component ****