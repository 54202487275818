import React, { useState, useEffect } from "react";
import { useAuth } from "../context/auth";
import { initializeApp } from "firebase/app";
import { app, auth, db } from "../modules/FirebaseModule"; // firebase.jsをインポート
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { doc, setDoc } from "firebase/firestore"; // Firestore関連のモジュールを追加
import { updateDocument } from "../modules/FirebaseModule";

const Notification = () => {
  //   const auth = useAuth();

  const [token, setToken] = useState("");
  const [initialized, setInitialized] = useState(false);

  //   const firebaseConfig = {
  //     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  //     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  //     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  //     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  //     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  //     appId: process.env.REACT_APP_FIREBASE_APP_ID,
  //     measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  //   };

  useEffect(() => {
    let unmounted = false;

    const initializeFCM = async () => {
      if (initialized || !auth.user || !auth.user.email) {
        return;
      }

      //   const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      try {
        const currentToken = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });

        if (!unmounted) {
          setToken(currentToken);

          // Firestoreの"owners"コレクションのroleフィールドを取得
          const userRef = doc("owners", auth.user.email); // Firestoreドキュメントへの参照を設定
          await setDoc(userRef, { fcmToken: currentToken }, { merge: true }); // Firestoreのドキュメントを更新
        }
      } catch (error) {
        console.error("Error getting FCM token:", error);
      }

      setInitialized(true);
    };

    initializeFCM();

    return () => {
      unmounted = true;
    };
  }, [auth.user, initialized]);

  useEffect(() => {
    if (!token || !auth.user) {
      return;
    }

    const messaging = getMessaging();

    const handleMessage = (payload) => {
      const message = `緊急通知: ${payload.data.userName}様との通信を確認してください`;
      console.log(message);
    };

    const unsubscribe = onMessage(messaging, handleMessage);

    return () => {
      unsubscribe();
    };
  }, [token, auth.user]);

  return null;
};

export default Notification;

// /*
//  * 参考
//  * https://qiita.com/Kazunori-Kimura/items/485b79bc6630453d853b
//  */
// import { useApp } from '../context'

// import React, { useState, useEffect } from 'react';
// import firebase from 'firebase/app';
// import 'firebase/messaging';

// import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
// import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

// import { updateDocument } from '../modules/FirebaseModule';

// const useStyles = makeStyles((theme) => ({
//     close: {
//         padding: theme.spacing(0.5),
//     },
// }));

// export const Notification = () => {
//     const { stateAuth } = useApp();
//     const classes = useStyles();

//     /**
//      * FCMトークン
//      * @type {String}
//      */
//     const [token, setToken] = useState('');
//     /**
//      * FCM初期化済みフラグ
//      * @type {Boolean}
//      */
//     const [initialized, setInitialized] = useState(false);

//     const [snackPack, setSnackPack] = useState([]);
//     const [open, setOpen] = useState(false);
//     const [messageInfo, setMessageInfo] = useState(undefined);

//     /**
//      * renderメソッドが呼ばれる前に実行される
//      * ライフサイクルメソッド
//      */
//     useEffect(() => {
//         let unmounted = false;
//         (async () => {
//             try {
//                 //アンマウントされていなければ
// 				if (!unmounted) {
//                     // FCM初期化処理
//                     if (!initialized) await initializeFCM();
//                     // dbを更新
//                     if (stateAuth.id) {
//                         console.log('notification stateAuth.id : ', stateAuth.id);
//                         console.log('notification token : ', token);
//                         await updateDocument('owners', stateAuth.id, {fcmToken: token});
//                     }
//                 };
//             } catch (e) {
//                 alert("Error : " + e);
//             }
//         })();

//         return () => {
// 			unmounted = true;
//             // dbを更新
//             if (stateAuth.id) updateDocument('owners', stateAuth.id, {fcmToken: ''});
// 		};
//     }, [stateAuth.id])

//     useEffect(() => {
//         console.log('useEffect snackPack.length : ', snackPack.length);
//         console.log('useEffect messageInfo : ', messageInfo);
//         if (snackPack.length && !messageInfo) {
//             // Set a new snack when we don't have an active one
//             setMessageInfo({ ...snackPack[0] });
//             setSnackPack((prev) => prev.slice(1));
//             setOpen(true);
//         } else if (snackPack.length && messageInfo && open) {
//             // Close an active snack when a new one is added
//             setOpen(false);
//         }
//     }, [snackPack, messageInfo, open]);

//     /**
//      * FCM初期化およびイベントリスナーのセット
//      */
//     const initializeFCM = async () => {
//         if (initialized) {
//             // 初期化済みなら何もしない
//             // 通常はcomponentWillMountは一度しか呼ばれないが、
//             // react-router-domを使用した場合に画面遷移に伴って
//             // 複数回呼ばれることがあったので、フラグで管理しておく
//             return;
//         }

//         if (!firebase.messaging.isSupported()) {
//             console.log('firebase messaging not supported');
//             // FCMサポート対象ブラウザでなければ何もしない ex) Safari
//             // firebase-messaging.js broken on iOS Safari 11.1.2 · Issue #1260 · firebase/firebase-js-sdk
//             // https://github.com/firebase/firebase-js-sdk/issues/1260
//             return;
//         }

//         const messaging = firebase.messaging();

//         // tokenを取得する
//         const token = await messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY });
//         // stateに保持
//         setToken(token);

//         // tokenは定期的に更新される
//         // tokenが更新されたらstateに反映する
//         messaging.onTokenRefresh(() => {
//             messaging.getToken({ vapidKey: process.env.REACT_APP_VAPID_KEY })
//                 .then((currentToken) => {
//                     // stateのtokenを更新
//                     setToken(currentToken);
//                 })
//                 .catch((err) => {
//                     console.error(err);
//                 });
//         });

//         // ブラウザがフォアグラウンド時に通知を受け取った場合は
//         // onMessageイベントが発火する
//         messaging.onMessage((payload) => {
//             console.log('onMessage Notif payload : ', payload);
//             handleClick(`緊急通知 : ${payload.data?.userName}様との通信を確認してください`);
//         });

//         // 初期化済みフラグをセット
//         setInitialized(true);
//     };

//     const handleClick = (message) => {
//         console.log('handle click message : ', message);
//         setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
//     };

//     const handleClose = (event, reason) => {
//         if (reason === 'clickaway') {
//             return;
//         }
//         setOpen(false);
//     };

//     const handleExited = () => {
//         setMessageInfo(undefined);
//     };

//     /**
//      * コンポーネントの描画メソッド
//      */
//     return (
//         <Snackbar
//             key={messageInfo ? messageInfo.key : undefined}
//             anchorOrigin={{
//             vertical: 'top',
//             horizontal: 'right',
//             }}
//             open={open}
//             autoHideDuration={600000}
//             onClose={handleClose}
//             onExited={handleExited}
//             message={messageInfo ? messageInfo.message : undefined}
//             action={
//                 <React.Fragment>
//                     <IconButton
//                         aria-label="close"
//                         color="inherit"
//                         className={classes.close}
//                         onClick={handleClose}
//                     >
//                         <CloseIcon />
//                     </IconButton>
//                 </React.Fragment>
//             }
//         />
//     );
// }
