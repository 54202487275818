// "react-hook-form": "^6.14.2",
import React, { useState, useEffect } from "react";
// import { Link, useHistory } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import { TextField, Button, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import { Loader } from "../../component/Loader";

import { useForm } from "react-hook-form";

import { sendPasswordResetEmail } from "../../modules/FirebaseModule";

import { useApp } from "../../context";

// スタイルの記述をする
const useStyles = makeStyles((theme) => ({
  outer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    width: 400,
    margin: `${theme.spacing(0)} auto`,
  },
  card: {
    minWidth: "540px",
    minHeight: "540px",
    marginTop: theme.spacing(10),
  },
  header: {
    // 2022/11/7　追加修正　h.suzuki
    textAlign: "center",
    backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
    // color: "#333",
    color: "#ffffff",
  },

  // textField: {
  //     border: "1px solid #ced4da",
  //     borderRadius: ".25rem",
  //     transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  // },
  signupbtn: {
    // backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
    // color: "#00000099",
    color: "#000099",
  },
  signupTbtn: {
    // backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
    // color: "#00000099",
    color: "#000099",
  },
}));
// **** useStyles ****

// **** component ****
export const ResetPassword = () => {
  const { stateAuth, dispatchAuth } = useApp();
  const classes = useStyles();
  // let history = useHistory();
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(false);
  const [mail, setMail] = useState("");
  // バリデーション
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur", // エラーになった後、空文字が入るのを防ぐ
  });
  const onSubmit = (data) => console.log(data);
  // console.log(watch('email'));

  const inputMail = (e) => {
    setMail(e.target.value);
  };

  const pushResetBtn = async () => {
    setIsLoader(true);
    try {
      const res = await sendPasswordResetEmail(mail);
      alert(res);
    } catch (e) {
      alert("メールアドレスが登録されていない可能性があります");
    }
    setIsLoader(false);
  };

  useEffect(() => {
    // ログインした状態でここにアクセスしたらホームへ飛ぶ
    // if (stateAuth.currentUser) history.replace('/');
    if (stateAuth.currentUser) navigate("/");
    return () => {};
  }, [stateAuth.currentUser, navigate]);

  return (
    <div className={classes.outer}>
      {isLoader === true ? <Loader /> : ""}
      <Card className={classes.card}>
        <CardHeader
          className={classes.header}
          title="パスワードをリセットする"
        />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p>メールアドレス</p>
            <TextField
              fullWidth
              margin="normal"
              type="text"
              value={mail}
              onChange={inputMail}
              name="mail"
              error={!!errors.mail}
              helperText={errors?.mail?.message}
              inputRef={register({
                required: "*必須項目",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "メールアドレスが正しくありません",
                },
              })}
            />
            {/* {errors.mail && <p>{errors.mail.message}</p>} */}

            {/* <br /><br /> */}
            <div>
              <p>パスワード再設定用メール</p>
            </div>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={pushResetBtn}
                type="submit"
                disabled={!formState.isValid}
                // disabled={!formState.dirty || formState.isSubmitting}
              >
                送信する
              </Button>
            </div>
          </form>

          <div
            // onClick={(e) => history.push('/login')}
            onClick={(e) => navigate("/login")}
            style={{
              color: "#087cc9",
              fontSize: "0.85em",
              fontweight: "bold",
              marginTop: "16px",
            }}
          >
            ログイン画面に戻る
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
// **** component ****
