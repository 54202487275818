import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import CloseIcon from '@material-ui/icons/Close';


/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: "90%",
        minWidth: "90%",
        position: "relative",
    },
    muiDCroot: {
        '&:first-child': {
            padding: "0px",
            overflow: "hidden",
        },
        // backgroundColor: theme.palette.secondary["900"],
        backgroundColor: "#111",
        height: "100vh",
    },
    // videoContainer: {
    //     // position: "absolute",
    //     backgroundColor: theme.palette.secondary["900"],
    //     // width: "100%",
    //     // height: "100%",
    //     height: "100vh",
    //     // overflow: "visible",
    // },
    video: {
        "-webkit-transform": "scaleX(-1)",
        // marginTop: "50px",
        width: "100%",
        height: "100%",
    },
    self: {
        position: "absolute",
        right: "30px",
        top: "50px",
        // backgroundColor: theme.palette.secondary["100"],
        width: "150px",
        height: "100px",
        zIndex: "999",
    },
    footer: {
        height: "120px",
        position: "absolute",
        bottom: 0,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
    },
    icon: {
        fontSize: "3em",
        height: "60px",
        width: "60px",
        borderRadius: "50%",
        "-moz-border-radius": "50%",
        "-webkit-border-radius": "50%",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
    },

}));
// **** useStyles ****
/*
 * **** component ****
 */
export const VideoDialog = ({ myVideo, streamVideo, callVideo, closeVideo }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [showFooter, setShowFooter] = useState(false);
    const [isAudioMute, setIsAudioMute] = useState(false);
    const [isVideoOff, setIsVideoOff] = useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const handleCall = () => {
        callVideo();
        // setOpen(false);
    };

    const handleClose = () => {
        closeVideo();
        // setOpen(false);
    };

    /*
     * **** footer method ****
     */
    const handleShowFooter = (e) => {
        // e.preventDefault();
        setShowFooter(!showFooter);
    }

    const onHangupClicked = (e) => {
        // setPeers([]);
    }

    const toggleVideoEnabled = (e) => {
        e.stopPropagation();
        if (myVideo.current && myVideo.current.srcObject) {
            myVideo.current.srcObject.getVideoTracks()[0].enabled = isVideoOff;
            setIsVideoOff(!isVideoOff);
        }
    }
    const toggleAudioEnabled = (e) => {
        e.stopPropagation();
        if (myVideo.current && myVideo.current.srcObject) {
            myVideo.current.srcObject.getAudioTracks()[0].enabled = isAudioMute;
            // if (isAudioMute) myVideo.current.muted = false;
            // if (isAudioMute == false) myVideo.current.muted = true;
            setIsAudioMute(!isAudioMute);
        }
    }

    return (
        <Dialog
            classes={{paper: classes.paper}}
            fullScreen={fullScreen}
            open={open}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            { callVideo ? <DialogTitle id="responsive-dialog-title">{"見守り"}</DialogTitle> : "" }
            <DialogContent
                // className={classes.videoContainer}
                classes={{root: classes.muiDCroot}}
                onClick={handleShowFooter}
            >
                {/* <DialogContentText></DialogContentText> */}
                {/* <div className={classes.videoContainer}
                    onClick={handleShowFooter}
                > */}
                    { callVideo ? "" : <video className={`${classes.video} ${classes.self}`} muted ref={myVideo} autoPlay playsInline /> }
                    <video className={`${classes.video}`} ref={streamVideo} autoPlay playsInline />
                    { callVideo ? "" :
                        <div className={classes.footer}>
                            {isVideoOff ? <div className={classes.icon} onClick={toggleVideoEnabled} style={{display: showFooter ? 'flex' : 'none', backgroundColor: '#ffffff33'}}><VideocamOffIcon style={{color: 'white'}} /></div> : <div className={classes.icon} onClick={toggleVideoEnabled} style={{display: showFooter ? 'flex' : 'none', backgroundColor: '#ffffff33'}}><VideocamIcon style={{color: 'white'}} /></div>}
                            <div className={classes.icon} onClick={handleClose} style={{display: showFooter ? 'flex' : 'none', backgroundColor: 'red'}}><CloseIcon style={{color: 'white'}} /></div>
                            {isAudioMute ? <div className={classes.icon} onClick={toggleAudioEnabled} style={{display: showFooter ? 'flex' : 'none', backgroundColor: '#ffffff33'}}><MicOffIcon style={{color: 'white'}} /></div> : <div className={classes.icon} onClick={toggleAudioEnabled} style={{display: showFooter ? 'flex' : 'none', backgroundColor: '#ffffff33'}}><MicIcon style={{color: 'white'}} /></div>}
                        </div>
                    }
                {/* </div> */}
            </DialogContent>
            { callVideo ?
                <DialogActions>
                        <div>
                            <Button autoFocus onClick={handleCall} color="primary">
                                CALL
                            </Button>
                            <Button onClick={handleClose} color="primary" autoFocus>
                                OFF
                            </Button>
                        </div>
                </DialogActions>
            : ""
            }
        </Dialog>
    );
}