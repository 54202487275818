import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { Label } from '@material-ui/icons';

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: "500px",
        minWidth: "80%",
    },
    text: {
        width: "100%",
    },

}));
// **** useStyles ****
/*
 * **** component ****
 */
export const WatchDialog = ({ isOpen, setIsOpenWatchview, userRef, serverPost }) => {
    const classes = useStyles();
    const [siteUrl, setMemo] = useState(userRef.current?.siteUrl);
    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        let unmounted = false;
        // ユーザー情報を取得
        (async () => {
            try {
                const res = await serverPost(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_FIND}`, { siteUrl: userRef.current.siteUrl });
                //アンマウントされていなければ
                if (!unmounted) {
                    setMemo(res?.data?.siteUrl)
                    // alert("データを取得成功 Info : " + siteUrl);
                };
            } catch (e) {
                alert("データを取得できません。 Error : " + e);
            }
        })();

        return () => {
            unmounted = true;
        };
    }, [])

    const setText = (e) => {
        setMemo(e.target.value);
    }

    // post to server
    const handleSave = async () => {
        await serverPost(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_UPDATE}`, { id: userRef.current.id, options: { siteUrl: siteUrl } });
        setIsOpenWatchview(false);
        // alert("データを保存 Info : " + courseDetail);

        document.location.reload();
    };

    const handleClose = () => {
        setIsOpenWatchview(false);
    };


    return (
        <Dialog
            classes={{ paper: classes.paper }}
            fullScreen={fullScreen}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"動画視聴"}</DialogTitle>
            <DialogContent>
                <DialogContentText></DialogContentText>
                <TextField
                    classes={{ root: classes.text }}
                    variant="outlined"
                    type="text"
                    value={siteUrl}
                    onChange={setText}
                    multiline
                    rows={16}
                />
            </DialogContent>
            <DialogActions>
                {/* <TableCell align="center"> */}
                {/* <a href={siteUrl} target="" style={{
                        textDecoration: "none",
                    }}> */}
                <Button autoFocus onClick={handleSave} color="primary"

                >
                    <Link to={siteUrl} target='_blank'>Click</Link>
                    {/* 保存 */}
                </Button>
                {/* </a> */}
                {/* </TableCell> */}
                <Button autoFocus onClick={handleClose} color="primary">
                    閉じる
                </Button>
            </DialogActions >
        </Dialog >
    );
}