import { useApp } from "../context";

import React, { useEffect, useState, useRef, useCallback } from "react";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import Button from "@material-ui/core/Button";

import { Bar } from "./bar/Bar";
import { Loader } from "../component/Loader";
import { Menu2 } from "../component/Menu2";
import { MemoDialog } from "../component/MemoDialog";
import { CourseDialog } from "../component/CourseDialog";
import { WatchDialog } from "../component/WatchDialog";

import { CheckRemoveDialog } from "../component/CheckRemoveDialog";
import { VideoDialog } from "../component/VideoDialog";

// import { Notification } from '../component/Notification';

import { serverGet, serverPost } from "../modules/Ajax";

import io from "socket.io-client";

const EVENTS = {
  connect: "connect",
  ADMIN_LOGIN: "ADMIN_LOGIN",
  ONLINE_USERS: "ONLINE_USERS",
  RECEIVE_OFFER: "RECEIVE_OFFER",
  CANDIDATE: "CANDIDATE",
  ANSWER: "ANSWER",
};
const configuration = {
  iceServers: [
    {
      urls: "stun:stun.l.google.com:19302",
    },
    {
      urls: "turn:18.136.198.54:3478",
      credential: "123456",
      username: "username",
    },
  ],
};

let constraints = (window.constraints = {
  audio: true,
  video: true,
});

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles((theme) => ({
  root: theme.mixins.root,
  body: theme.mixins.body,
  videoContainer: {
    position: "absolute",
    backgroundColor: theme.palette.secondary["900"],
    width: "80%",
    top: "50%",
    left: "50%",
    height: "80%",
    minHeight: "600px",
    transform: "translate(-50%, -50%)",
  },
  video: {
    "-webkit-transform": "scaleX(-1)",
    // marginTop: '50px',
    height: "40%",
    width: "100%",
  },
  self: {
    // width: '100%',
    // height: '100%',
  },
  footer: {
    height: "120px",
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  icon: {
    fontSize: "3em",
    height: "60px",
    width: "60px",
    borderRadius: "50%",
    "-moz-border-radius": "50%",
    "-webkit-border-radius": "50%",
  },
}));
// **** useStyles ****

/*
 * **** component ****
 */
export const Menu2WithSocket = () => {
  const { stateAuth, dispatchAuth, state, dispatch } = useApp();
  const classes = useStyles();
  // const history = useHistory();
  const navigate = useNavigate();

  /*
   * **** table ****
   */
  const [loadTable, setLoadTable] = useState(false);
  // メモ
  const [isOpenMemo, setIsOpenMemo] = useState(false); // dialog open
  const userForMemoRef = useRef();
  // 2021/11/25 h.suzuki add 概要説明用モーダル追加
  const [isOpenOverview, setIsOpenOverview] = useState(false); // dialog open
  const userForOverviewRef = useRef();
  // 2021/12/01 h.suzuki add 動画視聴用モーダル追加
  const [isOpenWatchview, setIsOpenWatchview] = useState(false); // dialog open
  const userForWatchviewRef = useRef();
  // 削除
  const [isCheckRemove, setIsCheckRemove] = useState(false); // dialog open
  const removeUserRef = useRef();

  const [isVideoChatWindowOpen, setIsVideoChatWindowOpen] = useState(false);
  /*
   * **** table ****
   */

  // 一度だけ呼ばれるようにするために定義
  const [socketOnOnlineUsers, setSocketOnOnlineUsers] = useState(false);
  /*
   * **** socket ****
   */
  const [users, setUsers] = useState([]); // {id, name, socketID} tableData
  const [userTokens, setUserTokens] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ name: "" });
  const [isShowVideo, setIsShowVideo] = useState(false);
  const [isCallVideo, setIsCallVideo] = useState(false);
  const [isCallingUserBackground, setIsCallingUserBackground] = useState(false);

  const userVideo = useRef();
  const streamVideo = useRef();
  const videoPlay = useRef();

  const socketRef = useRef();

  let yourConnection = useRef();
  let remoteStream = useRef();
  // let localStream = useRef();
  /*
   * **** socket ****
   */

  /*
   * useEffect
   */
  /*
   * login確認
   */
  useEffect(() => {
    let unmounted = false;

    // if (stateAuth.currentUser == null) history.replace('/login');
    if (stateAuth.currentUser == null) navigate("/");
    else {
      /*
       * 一度だけ呼ばれるようにする
       */
      if (stateAuth.id && socketOnOnlineUsers === false) {
        console.log("socket connect before");
        socketRef.current = io.connect(process.env.REACT_APP_SOCKETIO_ENDPOINT);

        setInterval(() => {
          socketRef.current.emit("GET_USERS", {
            name: stateAuth.currentUser.name,
          });
        }, 60000);

        // 接続時に呼ばれる
        socketRef.current.on(EVENTS.connect, () => {
          console.log("socket connect");
          console.log("stateAuth.id : ", stateAuth.id); // @todo: change name of admin later
          // socketRef.current.emit(EVENTS.ADMIN_LOGIN, { name: 'admin: react' });
          socketRef.current.emit(EVENTS.ADMIN_LOGIN, {
            name: stateAuth.currentUser.name,
            id: stateAuth.id,
          });
          /* ********************************
           * まずオンラインユーザーを確認する
           */
        });

        socketRef.current.on(EVENTS.RECEIVE_OFFER, (data) => {
          _catchEvent(EVENTS.RECEIVE_OFFER, data);
        });

        socketRef.current.on(EVENTS.ANSWER, (data) => {
          console.log("on recevive ANSWER");
          console.log(data);
          yourConnection.current.setRemoteDescription(
            new RTCSessionDescription(data)
          );
        });

        socketRef.current.on(EVENTS.CANDIDATE, (data) => {
          _catchEvent(EVENTS.CANDIDATE, data);
        });

        socketRef.current.on("USERS_TOKEN", (data) => {
          console.log("USERS_TOKEN", data);
          let dt = [];
          for (let t of data) {
            dt[t["id"]] = t;
          }
          setUserTokens(dt);
          // console.log(self.userTokens);
        });

        (async () => {
          try {
            socketRef.current.on("READY_FOR_CALL", async (data) => {
              console.log("READY_FOR_CALL", data);
              for (let u of users) {
                if ((u.socketID = data["from"])) {
                  setSelectedUser(u);
                  await doCallVideo();
                  break;
                }
              }
              setIsCallingUserBackground(false);
            });

            //アンマウントされていなければ
            if (!unmounted) {
            }
          } catch (e) {
            alert("データを取得できません。 Error : " + e);
          }
        })();

        socketRef.current.on("ONLINE_USERS", async (data) => {
          console.log("********************************");
          console.log("online_users : ", data);
          const tData = await fetchData(
            `${process.env.REACT_APP_API_BASE_URL}/api/admin/users`
          );
          setLoadTable(true);
          // usersﾆデータが入るまで待つ
          // let _users = [...users]; // 参照ではなくコピー
          console.log("ONLINE_USERS tData : ", tData);
          let _users = tData;
          // console.log('_users : ', _users);
          let _dataIdKey = {};
          for (let k in data) {
            _dataIdKey[data[k]["id"]] = { name: data[k]["name"], socketID: k };
          }
          if (_users.length > 0) {
            for (let i = 0; i < _users.length; i++) {
              let _id = _users[i]["id"];
              if (_dataIdKey[_id]) {
                _users[i]["socketID"] = _dataIdKey[_id]["socketID"];
              }
            }
            console.log("_users : ", _users);
            console.log("********************************");
            setUsers(_users);
          }
        });

        // dispatch({type: 'SET_SOCKET_REF', payload: socketRef});

        setSocketOnOnlineUsers(true);
      }
    }

    return () => {
      unmounted = true;
      if (socketRef.current) {
        socketRef.current?.close();
        // dispatch({type: 'SET_SOCKET', payload: null});
      }
    };
    // }, [stateAuth.currentUser, stateAuth.id, history]);
  }, [stateAuth.currentUser, stateAuth.id, navigate]);

  useEffect(() => {
    let unmounted = false;

    (async () => {
      try {
        //アンマウントされていなければ
        const tData = await fetchData(
          `${process.env.REACT_APP_API_BASE_URL}/api/admin/users`
        );
        if (!unmounted) {
          // if (!loadTable && tData && tData.length > 0) {
          setUsers(tData);
          setLoadTable(true);
          // }
        }
      } catch (e) {
        alert("データを取得できません。 Error : " + e);
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [stateAuth.id]);

  useEffect(() => {
    // dispatch({type: 'SET_USER_VIDEO_REF', payload: userVideo});
    // dispatch({type: 'SET_STREAM_VIDEO_REF', payload: streamVideo});
    // dispatch({type: 'SET_SOCKET_REF', payload: socketRef});
    // dispatch({type: 'SET_SELECTED_USER', payload: selectedUser});

    // videochat終了時に呼ばれる
    window.addEventListener("message", function (e) {
      console.log("addEventListener message");
      console.log("e: ", e);
      switch (e.data.action) {
        case "windowClose":
          setIsVideoChatWindowOpen(false);
          console.log("windowClose");
          break;
      }
    });
  }, []);
  /*
   * useEffect
   */

  /*
   * method
   */
  const fetchData = async (url) => {
    // 時間の０字埋め
    let tData = [];
    try {
      if (
        stateAuth?.id &&
        (stateAuth.id === process.env.REACT_APP_ADMIN ||
          stateAuth.id === process.env.REACT_APP_ADMIN2)
      ) {
        let res = await serverGet(url);
        if (res.data && res.data.length > 0) tData = res.data;
      } else if (stateAuth.id) {
        let res = await serverPost(url, { id: stateAuth.id });
        console.log("fetch res : ", res);
        if (res.data && res.data.length > 0) tData = res.data;
      }
    } catch (e) {
      console.log("fetchData error : ", e);
    }

    return tData;
  };

  // **** socket method ****
  const closeVideo = () => {
    if (yourConnection.current) {
      console.log("videoPlay : ", videoPlay.current.srcObject);
      videoPlay.current.srcObject = null;
      yourConnection.current.close();
      yourConnection.current.onicecandidate = null;
      yourConnection.current.onaddstream = null;
    }
    console.log("closeVideo : ", selectedUser);
    socketRef.current.emit("CLOSE_VIDEO", {
      to: selectedUser.socketID,
      uid: selectedUser.id,
    });
    setIsShowVideo(false);
  };

  const callVideo = () => {
    closeVideo();
    // setIsVideoChatWindowOpen(true);
    // // roomﾍ遷移
    // const videochatWindow = window.open(`/chat`, "_blank", 'top=100,left=100,width=1280,height=960');
    // if (videochatWindow) {
    //     console.log('開きました');
    //     videochatWindow.postMessage({
    //         action: 'init',
    //         message: 'hello'
    //     }, '*', );
    // } else {
    //     console.log('開けませんでした');
    //     videochatWindow.close();
    //     setIsVideoChatWindowOpen(false);
    // }

    setTimeout(() => {
      doCallVideo();
    }, 1000);
    setIsCallingUserBackground(false);
  };

  const doCallVideo = async () => {
    let data = {
      from: socketRef.current.id,
      to: selectedUser.socketID,
      uid: selectedUser.id,
    };
    socketRef.current.emit("CALL_VIDEO", data);
    // ダイアログで表示させる
    setIsCallVideo(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      _handleSuccess(stream);
    } catch (e) {
      console.log(e);
    }
    setIsCallingUserBackground(false);
  };

  const closeCallVideo = () => {
    setIsCallVideo(false);
    if (yourConnection.current) {
      try {
        if (userVideo.current.srcObject) {
          for (
            let i = 0;
            i < userVideo.current.srcObject.getTracks().length;
            i++
          ) {
            userVideo.current.srcObject.getTracks()[i].stop();
          }
        }
      } catch (e) {
        console.log(e);
      }
      streamVideo.current.srcObject = null;
      userVideo.current.srcObject = null;
      yourConnection.current.close();
      yourConnection.current.onicecandidate = null;
      yourConnection.current.onaddstream = null;
    }
    socketRef.current.emit("CLOSE_CALL_VIDEO", {
      to: selectedUser.socketID,
      uid: selectedUser.id,
    });
    setIsCallingUserBackground(false);
    if (isVideoChatWindowOpen) setIsVideoChatWindowOpen(false);
  };

  const openVideo = useCallback(async (user) => {
    console.log("openVideo");
    console.log("user : ", user);
    setSelectedUser(user);
    setIsShowVideo(true);
    let data = { to: user.socketID, from: socketRef.current.id, uid: user.id };
    console.log("SHOW_VIDEO", data);
    socketRef.current.emit("SHOW_VIDEO", data);
    try {
      await _updateUserCheckDatetime(user);
    } catch (e) {
      console.log("error : ", e);
    }
    setIsCallingUserBackground(false);
  });

  const _updateUserCheckDatetime = async (user) => {
    // let self = this;
    let now = new Date();
    let month = (now.getMonth() + 1).toString();
    if (month.length < 2) {
      month = "0" + month;
    }
    let day = now.getDate().toString();
    if (day.length < 2) {
      day = "0" + day;
    }
    let hours = now.getHours().toString();
    if (hours.length < 2) hours = "0" + hours;
    let minutes = now.getMinutes().toString();
    if (minutes.length < 2) minutes = "0" + minutes;
    let datetimeStr =
      now.getFullYear() + "." + month + "." + day + " " + hours + ":" + minutes;

    try {
      const _users = [...users];
      console.log("selectedUser : ", selectedUser);
      console.log("_users : ", _users);
      console.log("user : ", user);
      const res = await serverPost(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/users-update-check-datetime`,
        { id: user.id, checkDatetime: now }
      );
      for (let i = 0; i < users.length; i++) {
        if (users[i].id === user.id) {
          _users[i].checkDatetime = datetimeStr;
          setUsers(_users);
          break;
        }
      }
    } catch (e) {
      console.log("error : ", e);
    }
  };

  const changeStatus = async (user, value) => {
    try {
      const res = await serverPost(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/users-change-status`,
        { id: user.id, status: value }
      );
    } catch (e) {
      console.log("error : ", e);
    }
  };

  const openVideoBackground = (user) => {
    let token = userTokens[user.id];
    token.adminSocketID = socketRef.current.id;
    console.log("token", token);
    socketRef.current.emit("RING_USER", token);
    setIsCallingUserBackground(true);
  };

  const _handleSuccess = (stream) => {
    console.log("stream : ", stream);
    userVideo.current.srcObject = stream;
    // localStream.current = stream;

    // peer
    yourConnection.current = new RTCPeerConnection(configuration);
    // yourConnection.current.addStream(localStream.current);
    yourConnection.current.addStream(stream);
    yourConnection.current.onaddstream = (e) => {
      console.log("onaddstream call");
      remoteStream.current = e.stream;
      streamVideo.current.srcObject = remoteStream.current;
    };
    yourConnection.current.onicecandidate = (event) => {
      console.log("onicecandidate", event);
      if (event.candidate) {
        socketRef.current.emit("CANDIDATE", {
          from: socketRef.current.id,
          to: selectedUser.socketID,
          candidate: event.candidate,
        });
      }
    };
    yourConnection.current.createOffer(
      (offer) => {
        console.log("CALL_OFFER", offer);
        socketRef.current.emit("CALL_OFFER", {
          to: selectedUser.socketID,
          from: socketRef.current.id,
          offer: offer,
        });
        yourConnection.current.setLocalDescription(offer);
      },
      (error) => {
        alert("An error has occurred.");
      }
    );
  };

  const _catchEvent = (name, data) => {
    switch (name) {
      case EVENTS.RECEIVE_OFFER:
        console.log("RECEIVE_OFFER", data);
        try {
          if (yourConnection.current) {
            videoPlay.current.srcObject = null;
            yourConnection.current.close();
            yourConnection.current.onicecandidate = null;
            yourConnection.current.onaddstream = null;
          }
        } catch (ex) {
          alert(JSON.stringify(ex));
        }
        if (true) {
          yourConnection.current = new RTCPeerConnection(configuration);
          yourConnection.current.onaddstream = (e) => {
            remoteStream.current = e.stream;
            videoPlay.current.srcObject = remoteStream.current;
          };
          yourConnection.current.setRemoteDescription(
            new RTCSessionDescription(data.offer)
          );
          yourConnection.current.onicecandidate = (event) => {
            console.log("onicecandidate", event);
            if (event.candidate) {
              socketRef.current.emit(EVENTS.CANDIDATE, {
                from: socketRef.current.id,
                to: data.from,
                candidate: event.candidate,
              });
            }
          };
          console.log("createAnswer");
          yourConnection.current.createAnswer(
            (answer) => {
              yourConnection.current.setLocalDescription(answer);
              socketRef.current.emit(EVENTS.ANSWER, {
                to: data.from,
                answer: answer,
              });
            },
            (error) => {
              alert("An error has occurred");
            }
          );
        }
        break;

      case EVENTS.CANDIDATE:
        console.log("CANDIDATE", data);
        yourConnection.current.addIceCandidate(
          new RTCIceCandidate(data.candidate)
        );
        break;

      default:
        console.log("no event: " + name);
    }
  };
  // **** socket method ****

  /*
   * render
   */
  return (
    <div className={classes.root}>
      <Bar
        // title={'講座一覧'}
        title={""}
      />

      <main className={classes.body}>
        {loadTable === false ? (
          <Loader />
        ) : (
          <div>
            {/* 2021/11/19 h.suzuki add 端末ステータス取得UI非表示 */}
            <div
              style={{ margin: "24px", textAlign: "left", fontSize: "24px" }}
            >
              {/* ＜ 講座一覧 ＞ */}
              {/* 2021/11/19　追加修正　h.suzuki　端末ステータス取得UI調整 */}
              {/* <div style={{ margin: '32px', textAlign: 'right' }}>
                                <Button
                                    variant="contained" color="primary"
                                    onClick={() => {
                                        socketRef.current.emit("GET_USERS", { name: stateAuth.currentUser.name });
                                    }}
                                >端末ステータス取得</Button>
                            </div> */}
            </div>
            <Menu2
              tableData={users}
              openVideo={openVideo}
              changeStatus={changeStatus}
              userForOverviewRef={userForOverviewRef}
              setIsOpenOverview={setIsOpenOverview}
              userForWatchviewRef={userForWatchviewRef}
              setIsOpenWatchview={setIsOpenWatchview}
              userForMemoRef={userForMemoRef}
              setIsOpenMemo={setIsOpenMemo}
              removeUserRef={removeUserRef}
              setIsCheckRemove={setIsCheckRemove}
            />
          </div>
        )}
      </main>

      {isShowVideo ? (
        <VideoDialog
          myVideo={userVideo}
          streamVideo={videoPlay}
          callVideo={callVideo}
          closeVideo={closeVideo}
        />
      ) : (
        ""
      )}

      {isCallVideo ? (
        <VideoDialog
          myVideo={userVideo}
          streamVideo={streamVideo}
          // callVideo={callVideo}
          closeVideo={closeCallVideo}
        />
      ) : (
        ""
      )}

      {isOpenOverview ? (
        <CourseDialog
          isOpen={isOpenOverview}
          setIsOpenOverview={setIsOpenOverview}
          userRef={userForOverviewRef}
          serverPost={serverPost}
          // socketRef={socketRef}
        />
      ) : (
        ""
      )}

      {isOpenWatchview ? (
        <WatchDialog
          isOpen={isOpenWatchview}
          setIsOpenWatchview={setIsOpenWatchview}
          userRef={userForWatchviewRef}
          serverPost={serverPost}
          // socketRef={socketRef}
        />
      ) : (
        ""
      )}

      {isOpenMemo ? (
        <MemoDialog
          isOpen={isOpenMemo}
          setIsOpenMemo={setIsOpenMemo}
          userRef={userForMemoRef}
          serverPost={serverPost}
          // socketRef={socketRef}
        />
      ) : (
        ""
      )}

      {isCheckRemove ? (
        <CheckRemoveDialog
          setIsCheckRemove={setIsCheckRemove}
          removeUserRef={removeUserRef}
          serverPost={serverPost}
        />
      ) : (
        ""
      )}
    </div>
  );
};
