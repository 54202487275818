import React from "react";
// import { Link, useHistory } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: theme.palette.common.drawerWidth,
      flexShrink: 1,
    },
  },
  listItem: {
    width: "100%",
    margin: "0 auto",
    justifyContent: "center",
  },
  item: {
    fontSize: "1.3em",
    color: theme.palette.primary.main,
  },
  toolbar: theme.mixins.toolbar, // テーマからバーの高さなどをロード
  title: {
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "1.4em",
    fontWeight: "bold",
  },
  drawerPaper: {
    width: theme.palette.common.drawerWidth,
  },
}));
// **** useStyles ****

/*
 * **** component ****
 */
export const ProfileSidebar = ({ mobileOpen, setMobileOpen }) => {
  const classes = useStyles();
  const theme = useTheme();

  // const history = useHistory();
  const navigate = useNavigate();

  /*
   * method
   */
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div
        className={`${classes.toolbar} ${classes.title}`}
        style={{ backgroundColor: "#fff" }}
      >
        {/* <img onClick={() => { history.replace('/'); }} src={`${process.env.PUBLIC_URL}/chocomiru_textlogo.png`} /> */}
        <img
          onClick={() => {
            navigate("/");
          }}
          src={`${process.env.PUBLIC_URL}/chocomiru_textlogo.png`}
        />
      </div>
      {/* <div className={classes.toolbar} style={{backgroundColor: theme.palette.primary.main}}/> */}
      <Divider />
      <List>
        {/* {['利用者リスト', 'みまもり履歴', '操作履歴', '設定'].map((text, index) => ( */}
        {/* {['講座管理 ', '講座一覧', '操作履歴'].map((text, index) => ( */}
        {["操作履歴"].map((text, index) => (
          <ListItem key={text} button classes={{ root: classes.listItem }}>
            {index === 0 ? (
              <Link to="/accesshistory" style={{ textDecoration: "none" }}>
                <ListItemText
                  classes={{ root: classes.item }}
                  disableTypography={true}
                  primary={text}
                />
              </Link>
            ) : (
              ""
            )}
            {/* { index === 1 ? <Link to="/accesshistory" style={{ textDecoration: 'none' }}><ListItemText classes={{ root: classes.item, }} disableTypography={true} primary={text} /></Link> : ''} */}
            {/* { index === 2 ? <Link to="/" style={{ textDecoration: 'none' }}><ListItemText classes={{ root: classes.item, }} disableTypography={true} primary={text} /></Link> : ''} */}
            {/* { index === 3 ? <Link to="/" style={{ textDecoration: 'none' }}><ListItemText classes={{ root: classes.item, }} disableTypography={true} primary={text} /></Link> : ''} */}
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["All mail", "Trash", "Spam"].map((text, index) => (
          <ListItem button key={text}>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                    <ListItemText primary={text} /> */}
          </ListItem>
        ))}
      </List>
    </div>
  );

  /*
   * render
   */
  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={document.body}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          // anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        {/* <Drawer
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer> */}
      </Hidden>
    </nav>
  );
};
// **** component ****
