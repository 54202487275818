// import React from 'react';
import MaterialTable from "material-table";
// import GenericTemplate from '../templates/GenericTemplate';

import { Link } from "react-router-dom";
import { ButtonBase, Hidden, makeStyles } from "@material-ui/core";
// import SortableTable from 'react-sortable-table';
import {
  ArrowUpward,
  ArrowDownward,
  GetAppOutlined,
  GetAppRounded,
  Palette,
} from "@material-ui/icons";
import * as colors from "@material-ui/core/colors";

// システム日時登録用
import { useApp } from "../context";
import React, { useEffect, useState, useRef, userRef } from "react";
// import React, { userRef } from 'react';
// import { useApp } from '../context'

import * as functions from "firebase/functions";
import { format } from "date-fns";
import firebase from "firebase/app";
import "firebase/auth";
// import "firebase/firestore";
// import { updateTimeStamp, getCurrentUserFromFirebase } from '../modules/FirebaseModule';
import { doc, setDoc } from "firebase/firestore";
// import { updateTimeStamp } from '../modules/FirebaseModule';
// import { findUserById } from '../../server/src/util/db';
// import { render } from 'react-dom';
// PDF出力
import jsPDF from "jspdf";
import "jspdf-autotable";

import {
  Button,
  Icon,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
} from "@material-ui/core";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  CardActions,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import CardActions from '@material-ui/core/Select';

// import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
// import ChatIcon from '@material-ui/icons/Chat';
// import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import ContactEmailIcon from '@material-ui/icons/ContactMail';
// import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
// import ContactMailTwoToneIcon from '@material-ui/icons/ContactMailTwoTone';
// import SmsIcon from '@material-ui/icons/Sms';
// import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
// import LiveTvSharpIcon from '@material-ui/icons/LiveTvSharp';
// import VideoLibrarySharpIcon from '@material-ui/icons/VideoLibrarySharp';
// 20240108 taniguchi add | tableタグ内にdivタグが存在しているエラーの解消
import { IconButton } from "@material-ui/core";
import OndemandVideoSharpIcon from "@material-ui/icons/OndemandVideoSharp";
import OndemandVideoTwoToneIcon from "@material-ui/icons/OndemandVideoTwoTone";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import SaveIcon from "@material-ui/icons/Save";

// import CastForEducationTwoToneIcon from '@material-ui/icons/CastForEducationTwoTone';
// import GetAppIcon from '@material-ui/icons/GetAppIcon';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
// import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import Typography from "@material-ui/core/Typography";
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { createTheme } from "@material-ui/core/styles";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import { Pdf } from "../component/Pdf";

// 2021/12/08 h.suzuki add 「material-table」の日本語Localization対応
import { localizationJapanese } from "../localization/localization";
// 2022/09/30 h.suzuki add メディアクエリによる、レスポンシブ対応
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { CenterFocusStrong } from "@material-ui/icons";

import { initializeApp } from "firebase/app";
import { app, auth, db } from "../modules/FirebaseModule"; // firebase.jsをインポート
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  Timestamp, // Timestampを直接インポート
  query,
  where,
} from "firebase/firestore";

// role が admin の場合に true を返す関数
const isAdminRole = (role) => {
  return role === "admin";
};

/* スタイルの記述をする */
const useStyles = makeStyles((theme) => ({
  muiSvgIconRoot: {
    color: colors.pink[300],
    // margin: "300px, 0px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "1.25em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("md")]: {
      // fontSize: "1.75em",
      fontSize: "2.0em",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.4em",
    },
    // [theme.breakpoints.up('xl')]: {
    //     fontSize: "2.0em",
    // },

    // [theme.breakpoints.up('sm')]
    // "@media (min-width:768px)": {
    //     width: `calc(100% - ${Number(process.env.REACT_APP_DRAWER_WIDTH)}px)`,
    //     marginLeft: Number(process.env.REACT_APP_DRAWER_WIDTH),
    //     // minWidth: 500,
  },
  root: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "0.75em",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.30em",
    },
    [theme.breakpoints.up("md")]: {
      // fontSize: "1.75em",
      fontSize: "1.35em",
    },

    width: "100%",
    margin: "0 auto",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  // 現在は使っていない（マテリアルテーブル設定前に使用していた）
  // header: {
  //     [theme.breakpoints.up('xs')]: {
  //         fontSize: "3.15em",
  //     },
  //     [theme.breakpoints.up('sm')]: {
  //         fontSize: "1.5em",
  //     },
  //     [theme.breakpoints.up('md')]: {
  //         // fontSize: "1.75em",
  //         fontSize: "1.25em",
  //     },
  // backgroundColor: theme.palette.common.black,
  // backgroundColor: theme.palette.primary.main,
  // backgroundColor: theme.palette.secondary.main,
  // color: theme.palette.common.white,
  // backgroundImage: "linear-gradient(-20deg, #f794a4 0%, #fdd6bd 50%)",
  // backgroundImage: "linear-gradient(to bottom, #fccb90 0%, #ff9a94 100%)",
  // 2021/11/15　追加修正　h.suzuki テーブルバー高さ変更
  // backgroundImage: "linear-gradient(to bottom, #ffc3a0 0%, #ff9a94 100%)",
  // backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
  // backgroundImage: "linear-gradient(to bottom, #fdd6bd 0%, #f794a4 100%)",

  // backgroundImage: "linear-gradient(to bottom, #fac8b9 0%, #ff9a94 100%)",
  // backgroundImage: "linear-gradient(to bottom, #f5b4b0 0%, #ff9a94 100%)",
  // backgroundColor: "#FDD6BC",
  // backgroundColor: "#f5b4b0",
  // backgroundColor: "#fac8b9",
  // color: '#000',
  // },
  title: {
    // [theme.breakpoints.up('xs')]: {
    //     fontSize: "1.15em",
    // },
    // [theme.breakpoints.up('sm')]: {
    //     fontSize: "1.5em",
    // },
    // [theme.breakpoints.up('md')]: {
    //     // fontSize: "1.75em",
    //     fontSize: "1.25em",
    // },
  },
  // 2022/01/13 h.suzuki add レスポンシブ対応｜テーブルサイズ調整
  // columnListSP: {
  //     [theme.breakpoints.up('xs')]: {
  //         fontSize: "0.15em",
  //     },
  //     [theme.breakpoints.up('sm')]: {
  //         fontSize: "1.5em",
  //     },
  //     [theme.breakpoints.up('md')]: {
  //         // fontSize: "1.75em",
  //         fontSize: "1.25em",
  //     },
  // [theme.breakpoints.up('lg')]: {
  //     fontSize: "2.0em",
  // },
  // [theme.breakpoints.up('xl')]: {
  //     fontSize: "2.0em",
  // },
  // },
  table: {
    // 2022/01/13 h.suzuki add レスポンシブ対応｜テーブルサイズ調整
    // minWidth: 'auto',
    // minHeight: 'auto',
    // [theme.breakpoints.up('xs')]: {
    //     fontSize: "2.75em",
    // },
    // [theme.breakpoints.up('sm')]: {
    //     fontSize: "1.5em",
    // },
    // [theme.breakpoints.up('md')]: {
    //     // fontSize: "1.75em",
    //     fontSize: "1.25em",
    // },
    // [theme.breakpoints.up('lg')]: {
    //     fontSize: "2.0em",
    // },
    // [theme.breakpoints.up('xl')]: {
    //     fontSize: "2.0em",
    // },columnListSP
  },
  tableCell: {
    padding: "0px 8px",
    // [theme.breakpoints.up('xs')]: {
    //     fontSize: "0.75em",
    // },
    // [theme.breakpoints.up('sm')]: {
    //     fontSize: "1.5em",
    // },
    // [theme.breakpoints.up('md')]: {
    //     // fontSize: "1.75em",
    //     fontSize: "1.25em",
    // },
  },
  userStatus: {
    borderRadius: 4,
    border: "1px solid #ced4da",
    padding: "4px 16px 4px 8px",
    width: "140px",
  },
  castFEIcon: {
    color: colors.pink[300],
    fontSize: "4.0em",
    margin: "30px",
  },
  urls: {
    color: "skyblue",
    margin: "20px",
  },
  urlButtons: {
    textDecoration: "none",
    color: "#ffffff",
  },
  ul: {
    listStyle: "none",
    paddingLeft: "0",
    display: "flex",
  },
  buttons: {
    width: "90%",
    margin: "10px",
    minWidth: "150px",
    backgroundColor: "",
    border: "1px solid #ff9a94",
    textDecoration: "none",
  },
  sp_buttons: {
    width: "90%",
    margin: "10px",
    minWidth: "150px",
    backgroundColor: "",
    border: "1px solid #ff9a94",
    textDecoration: "none",
  },
  alert: {
    animation: "$flash 1s linear infinite",
    color: "red",
  },

  "@keyframes flash": {
    "0%": {
      opacity: 1,
    },

    "50%": {
      opacity: 0,
    },

    "100%": {
      opacity: 1,
    },
  },
}));
/*
 * **** component ****
 */

// 2021/12/07（火） h.suzuki add Materialテーブルに変更設定する
export const MyTable = ({
  userId,
  tableData, // 旧取得方法　→　Materialテーブルに未対応...
  // openVideo, changeStatus, // 今回は使わない
  userForOverviewRef,
  setIsOpenOverview,
  userForWatchviewRef,
  setIsOpenWatchview,
  userForMemoRef,
  setIsOpenMemo,
  removeUserRef,
  setIsCheckRemove,
}) => {
  // 2022/07/03 h.suzuki add stateAuthユーザーでPDF出力させるため
  const { stateAuth, dispatchAuth, state, dispatch } = useApp();
  console.log("stateAuth : ", stateAuth);

  // 2022/09/30 h.suzuki add ブラウザ判定
  const theme = useTheme();
  // この書き方は多重になるのでNG?
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  console.log("matches : ", matches);

  const classes = useStyles();
  // 2021/11/27 h.suzuki add システム日時取得
  const myTimestamp = Timestamp.now();
  const myToDated = myTimestamp.toDate();

  const columnListSP = [
    {
      title: "研修カテゴリ",
      field: "category2",
      headerStyle: { whiteSpace: "nowrap" },
    },
    {
      title: "講座名",
      field: "name",
      sorting: false,
      headerStyle: { whiteSpace: "nowrap" },
    },
  ];
  const columnList = [
    {
      title: "ID",
      field: "id",
      headerStyle: { whiteSpace: "nowrap" },
      editable: "never",
      defaultSort: "asc",
    },
    // { title: "avatar", field: "avatar", sorting: false, headerStyle: { whiteSpace: 'nowrap', }, editable: "never", export: false },
    // { title: "タイプ", field: "type", headerStyle: { whiteSpace: 'nowrap',}, removable: true },
    {
      title: "研修メニュー",
      field: "category",
      headerStyle: { whiteSpace: "nowrap" },
      defaultSort: "asc",
    },
    {
      title: "研修カテゴリ",
      field: "category2",
      headerStyle: { whiteSpace: "nowrap" },
    },
    {
      title: "講座名",
      field: "name",
      sorting: false,
      headerStyle: { whiteSpace: "nowrap" },
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);
  // 2021/12/10 h.suzuki add　DBデータmap取得処理（courses）
  const [data, setData] = useState([]);
  // 2021/12/11 h.suzuki add ポップアップ対応（視聴）
  const [status, setStatus] = React.useState({ open: false, rowData: {} });
  // 2022/01/16 h.suzuki add ポップアップ対応２（テスト）
  const [test, setTest] = React.useState({ open: false, rowData: {} });
  const [movieUrl, setMovieUrl] = React.useState({ open: false, rowData: {} });
  // 2022/01/18 h.suzuki add 利用規約・制約対応
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // 2021/12/12 h.suzuki add Dense対応
  const [dense, setDense] = React.useState(false);
  // 2022/01/17 h.suzuki add スマホ対応で表示カラム数を変更させたい

  const emptyRows = data.length;
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  // 関数コンポーネント内で副作用(side-effect)を実行するためのHook
  // 第二引数を指定しない場合は、副作用は全レンダリング後に実行されます
  // 第二引数を指定した場合、配列に格納された値が変更された場合のみ実行されます
  // 第二引数に空配列が渡された場合、副作用は最初のレンダリングが行われた後だけ1回実行されます
  const [isLoading, setIsLoading] = useState(true); // ローディング状態を管理する状態変数

  useEffect(() => {
    const role = stateAuth.currentUser.role; // role を stateAuth から取得

    // 現在のURLを取得
    const currentUrl = window.location.href;

    // URLにroleパラメータを追加
    const newUrl = currentUrl.includes("?")
      ? `${currentUrl}&role=${role}`
      : `${currentUrl}?role=${role}`;
    window.history.pushState({}, "", newUrl);

    const fetchData = async () => {
      try {
        const db = getFirestore(app); // Firestore の初期化

        // Firestore の初期化が完了するのを待つ
        await app.isInitialized;

        let querySnapshot;
        const coursesRef = collection(db, "courses");

        if (role === "admin") {
          // permissionが"admin"の場合はすべてのデータを取得
          querySnapshot = await getDocs(coursesRef);
        } else {
          querySnapshot = await getDocs(
            query(coursesRef, where("role", "array-contains", role))
          );
        }

        console.log("querySnapshot: ", querySnapshot);

        if (!querySnapshot.empty) {
          const coursesData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id, // ドキュメントのIDもオブジェクトに含める
          }));
          console.log("coursesData: ", coursesData);

          setData(coursesData);
        }
        // データの取得が完了したことを示す状態を設定
        setIsLoading(false);
      } catch (error) {
        console.log(`データの取得に失敗しました (${error})`);
      }
    };

    fetchData();
    // stateAuth.currentUser.roleが変わるたびにuseEffectを再実行する
  }, []);

  /*
   * return処理
   */
  if (isLoading) {
    // データ取得前
    return <div>データ取得中...</div>;
  } else if (data) {
    return (
      <div>
        {matches ? (
          // PC表示
          <div>
            <TableContainer className={classes.root} component={Paper}>
              {stateAuth.currentUser ? (
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                  fixedheader="false"
                  style={{ width: "100%", tableLayout: "auto" }}
                >
                  {/* {stateAuth.currentUser ?  */}
                  {/* REACT_APP_ADMIN */}
                  {/* REACT_APP_PRO */}
                  {/* REACT_APP_STARTOR */}
                  {
                    // システム管理者 or 施設管理者｜PDFボタン表示
                    // admin, standard, special｜PDFボタン表示
                    stateAuth.currentUser &&
                    ["admin", "standard", "special"].includes(
                      stateAuth.currentUser.role
                    ) ? (
                      <MaterialTable
                        title={`講座一覧`}
                        className={classes.title}
                        // 入れると色つくけどソートが効かない（Firebaseの場合のみ）
                        icons={{
                          SortArrow: React.forwardRef((props, ref) => (
                            <ArrowDownward
                              // <ArrowUpward
                              {...props}
                              ref={ref}
                              style={{ color: colors.red[800] }}
                            />
                          )),
                        }}
                        columns={columnListSP}
                        className={classes.columnListSP}
                        data={data}
                        key={data.id}
                        // 保存＆編集
                        actions={[
                          // 動画視聴ボタン｜行クリック時のポップアップ
                          {
                            icon: () => (
                              <OndemandVideoSharpIcon
                                className={classes.muiSvgIconRoot}
                                style={{}}
                              />
                            ),
                            tooltip: "視聴する",
                            onClick: (evt, rowData) => {
                              evt.preventDefault();
                              setStatus({ open: true, rowData: rowData });
                            },
                          },
                          // テストボタン｜行クリック時のポップアップ
                          {
                            icon: () => (
                              <CastForEducationIcon
                                className={classes.muiSvgIconRoot}
                                style={{}}
                              />
                            ),
                            tooltip: "確認テスト",
                            onClick: (evt, rowData) => {
                              evt.preventDefault();
                              setTest({ open: true, rowData: rowData });
                            },
                          },
                          // PDF出力
                          {
                            icon: () => (
                              <Pdf
                                userId={stateAuth.id}
                                className={classes.muiSvgIconRoot}
                              />
                            ),
                            tooltip: "PDF出力",
                            onClick: (event) => {
                              console.log("PDFボタンが押されました: ", event);
                            },
                            // onClick: () => {stateAuth.id ? <Pdf userId={stateAuth.id} /> : ''},
                            isFreeAction: true,
                          },
                        ]}
                        options={{
                          showTitle: true, // * タイトル表示有無
                          addRowPosition: "first",
                          actionsColumnIndex: -1,
                          resizable: true, //　列のサイズ調整ができるはずだったが、できない・・
                          columnResizable: true,
                          tableLayout: "auto", // 可変幅
                          headerStyle: {
                            minWidth: 150,
                          },
                          size: dense ? "small" : "medium",
                          height: (dense ? 33 : 53) * 11,

                          sorting: true, // * 自動ソート
                          search: true, // * 検索機能
                          paging: true,
                          // TBLヘッダー固定時の最適値：　MacPro(13-inch, 2019)
                          maxBodyHeight: 500,
                          minBodyHeight: 350,
                          pageSizeOptions: [10, 20, 50, 100], // * ページング機能
                          draggable: false, // * 全ての列を選択移動可能
                          filtering: false, // ★ 全ての列にフィルターを設置
                          headerStyle: {
                            // ヘッダーを固定したままスクロールできる
                            position: "sticky",
                            top: 0,
                            backgroundImage:
                              "linear-gradient(to bottom, #fdd6bd 0%, #f794a4 100%)",
                            color: "#000",
                            // fontSize: 'middle',
                            // fontSize: 'medium',
                            fontSize: "large",
                            textAlign: "left",
                            // fontWeight: 'bold', //　しない方が綺麗
                          },
                        }}
                        localization={localizationJapanese}
                      />
                    ) : (
                      <MaterialTable
                        title={`講座一覧`}
                        className={classes.title}
                        // 入れると色つくけどソートが効かない（Firebaseの場合のみ）
                        icons={{
                          SortArrow: React.forwardRef((props, ref) => (
                            <ArrowDownward
                              // <ArrowUpward
                              {...props}
                              ref={ref}
                              style={{ color: colors.red[800] }}
                            />
                          )),
                        }}
                        columns={columnListSP}
                        className={classes.columnListSP}
                        data={data}
                        key={data.id}
                        // 保存＆編集
                        actions={[
                          // 動画視聴ボタン｜行クリック時のポップアップ
                          {
                            icon: () => (
                              <OndemandVideoSharpIcon
                                className={classes.muiSvgIconRoot}
                                style={{}}
                              />
                            ),
                            tooltip: "視聴する",
                            onClick: (evt, rowData) => {
                              evt.preventDefault();
                              setStatus({ open: true, rowData: rowData });
                            },
                          },
                          // テストボタン｜行クリック時のポップアップ
                          {
                            icon: () => (
                              <CastForEducationIcon
                                className={classes.muiSvgIconRoot}
                                style={{}}
                              />
                            ),
                            tooltip: "確認テスト",
                            onClick: (evt, rowData) => {
                              evt.preventDefault();
                              setTest({ open: true, rowData: rowData });
                            },
                          },
                        ]}
                        options={{
                          showTitle: true, // * タイトル表示有無
                          addRowPosition: "first",
                          actionsColumnIndex: -1,
                          resizable: true, //　列のサイズ調整ができるはずだったが、できない・・
                          columnResizable: true,
                          tableLayout: "auto", // 可変幅
                          headerStyle: {
                            minWidth: 150,
                          },
                          size: dense ? "small" : "medium",
                          height: (dense ? 33 : 53) * 11,

                          sorting: true, // * 自動ソート
                          search: true, // * 検索機能
                          paging: true,
                          // TBLヘッダー固定時の最適値：　MacPro(13-inch, 2019)
                          maxBodyHeight: 500,
                          minBodyHeight: 350,
                          pageSizeOptions: [10, 20, 50, 100], // * ページング機能
                          draggable: false, // * 全ての列を選択移動可能
                          filtering: false, // ★ 全ての列にフィルターを設置
                          headerStyle: {
                            // ヘッダーを固定したままスクロールできる
                            position: "sticky",
                            top: 0,
                            backgroundImage:
                              "linear-gradient(to bottom, #fdd6bd 0%, #f794a4 100%)",
                            color: "#000",
                            // fontSize: 'middle',
                            // fontSize: 'medium',
                            fontSize: "large",
                            textAlign: "left",
                            // fontWeight: 'bold', //　しない方が綺麗
                          },
                        }}
                        localization={localizationJapanese}
                      />

                      // <MaterialTable
                      //   title="講座一覧（一般ユーザ｜非表示）"
                      //   className={classes.title}
                      //   // size={dense ? 'small' : 'medium'}
                      //   // style={{ height: (dense ? 33 : 53) * 11 }}

                      //   // 入れると色つくけどソートが効かない（Firebaseの場合のみ）
                      //   icons={{
                      //     SortArrow: React.forwardRef((props, ref) => (
                      //       <ArrowDownward
                      //         // <ArrowUpward
                      //         {...props}
                      //         ref={ref}
                      //         style={{ color: colors.red[800] }}
                      //       />
                      //     )),
                      //   }}
                      //   // tableRef={tableRef}
                      //   // columns={columnListSP}
                      //   columns={columnListSP}
                      //   className={classes.columnListSP}
                      //   // {theme.breakpoints.up('xs') ? }}
                      //   data={data}
                      //   key={data.id}
                      //   // 保存＆編集
                      //   actions={[
                      //     // 動画視聴ボタン｜行クリック時のポップアップ
                      //     {
                      //       icon: () => (
                      //         <OndemandVideoSharpIcon
                      //           className={classes.muiSvgIconRoot}
                      //           style={{}}
                      //         />
                      //       ),
                      //       tooltip: "視聴する",
                      //       onClick: (evt, rowData) => {
                      //         evt.preventDefault();
                      //         setStatus({ open: true, rowData: rowData });
                      //       },
                      //     },
                      //     // テストボタン｜行クリック時のポップアップ
                      //     {
                      //       icon: () => (
                      //         <CastForEducationIcon
                      //           className={classes.muiSvgIconRoot}
                      //           style={{}}
                      //         />
                      //       ),
                      //       tooltip: "確認テスト",
                      //       onClick: (evt, rowData) => {
                      //         evt.preventDefault();
                      //         setTest({ open: true, rowData: rowData });
                      //       },
                      //       // open: {status.open},
                      //       // onClick: (evt, rowData) => alert("開始しますか？" + rowData.id + " : " + rowData.testUrl),
                      //     },
                      //     // 削除
                      //     // rowData => ({
                      //     //     icon: 'delete',
                      //     //     tooltip: 'Delete User',
                      //     //     onClick: (evt, rowData) => alert("削除しますか？ " + rowData.id),
                      //     //     disabled: rowData.id < 10
                      //     // })
                      //   ]}
                      //   // 親子グループを作成（これができれば、見やすくなるかも
                      //   // parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}

                      //   // 行タップで詳細開く
                      //   // onRowClick={(event, rowData, togglePanel) => togglePanel()}

                      //   // 行ホバー時に背景色を付ける
                      //   // onRowClick={
                      //   //     ((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))
                      //   // }
                      //   //

                      //   // 行ホバー時に背景色を付ける＆モーダルでダイアログ表示
                      //   // // onRowClick={(event, rowData) => {
                      //   // //     event.preventDefault();
                      //   // //     setStatus({ open: true, rowData: rowData });
                      //   // // }
                      //   // }

                      //   // onChangeRowsPerPage={(pageSize) => {
                      //   //     setStatus(pageSize);
                      //   // }}
                      //   // onSelectionChange={(selectedRows) => console.log(selectedRows)}
                      //   options={{
                      //     showTitle: true, // * タイトル表示有無
                      //     addRowPosition: "first",
                      //     actionsColumnIndex: -1,
                      //     resizable: true, //　列のサイズ調整ができるはずだったが、できない・・
                      //     columnResizable: true,
                      //     tableLayout: "auto", // 可変幅
                      //     // tableLayout: "fixed", // 固定幅
                      //     headerStyle: {
                      //       minWidth: 150,
                      //     },
                      //     size: dense ? "small" : "medium",
                      //     height: (dense ? 33 : 53) * 11,

                      //     sorting: true, // * 自動ソート
                      //     search: true, // * 検索機能
                      //     paging: true,
                      //     // TBLヘッダー固定時の最適値：　MacPro(13-inch, 2019)
                      //     maxBodyHeight: 500,
                      //     minBodyHeight: 350,
                      //     // padding: "default",
                      //     // padding: "dense",
                      //     // dense: "small",
                      //     // dense: "medium",
                      //     // detailPanelColumnAlignment: "left",　//詳細表示ボタン表示位置
                      //     // toolbar: false,　// ツールバーの表示有無
                      //     // toolbarButtonAlignment: "right",
                      //     pageSizeOptions: [10, 20, 50, 100], // * ページング機能
                      //     // paginationPosition: "both", // * ページネーション設定「上下表示」
                      //     // selection: true, // ★ 行選択可能
                      //     // showSelectAllCheckbox: true,
                      //     // showTextRowsSelected: false,
                      //     // 表示項目設定（表示：非表示）
                      //     // grouping: true,
                      //     // 2021/12/12 h.suzuki add ダウンロードボタン配置対応
                      //     // columnsButton: true, //項目表示・非表示ボタン
                      //     // 選択させない処理
                      //     // selectionProps: rowData => ({
                      //     //     disabled: true,
                      //     //     color: "primary"
                      //     // }),
                      //     draggable: false, // * 全ての列を選択移動可能
                      //     // actionsColumnIndex: -1,
                      //     filtering: false, // ★ 全ての列にフィルターを設置
                      //     // 選択時に行に色つけるけど、checkBoxと相性悪いため、却下
                      //     // rowStyle: rowData => ({
                      //     //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                      //     // }),

                      //     headerStyle: {
                      //       // ヘッダーを固定したままスクロールできる
                      //       position: "sticky",
                      //       top: 0,
                      //       backgroundImage:
                      //         "linear-gradient(to bottom, #fdd6bd 0%, #f794a4 100%)",
                      //       color: "#000",
                      //       // fontSize: 'middle',
                      //       // fontSize: 'medium',
                      //       fontSize: "large",
                      //       textAlign: "left",
                      //       // fontWeight: 'bold', //　しない方が綺麗
                      //     },
                      //   }}
                      //   localization={localizationJapanese}
                      // />
                    )
                  }
                  {/* MaterialTable　ここまで */}
                  {/* モーダルウインドウ設定 */}
                  <Dialog
                    open={status.open}
                    aria-labelledby="draggable-dialog-title"
                    fullWidth
                    maxWidth="lg"
                  >
                    <DialogTitle
                      id="draggable-dialog-title"
                      style={{
                        textAlign: "center",
                        // fontSize: 'medium',
                        // margin: '20px',
                      }}
                    >
                      ＜詳細情報＞
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                      // style={{
                      //     margin: '0px',
                      // }}
                      >
                        {/* avatar */}
                        {/* <img
                                                    src="../../h.suzuki.png"
                                                    style={{
                                                        // margin: '20px',
                                                        width: '100px',
                                                    }}
                                                    alt={status.rowData.avatar}
                                                /> */}
                        研修名：　{status.rowData.category}
                        <br />
                        講座名：　{status.rowData.name}
                        <br />
                        講師名：　{status.rowData.instructor}
                        <br />
                        説　明：　{status.rowData.detail}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        width: "95%",
                      }}
                    >
                      <ul className={classes.ul}>
                        <li>
                          <a
                            className={classes.urlButtons}
                            // variant="contained" color="primary"
                            href={status.rowData.movieUrl}
                            target="_blank"
                          >
                            <Button
                              // autoFocus
                              variant="contained"
                              color="primary"
                              className={classes.buttons}
                            >
                              視聴する
                            </Button>
                          </a>
                        </li>
                        <li>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttons}
                            onClick={() =>
                              setStatus({
                                open: false,
                                rowData: status.rowData,
                              })
                            }
                            color="secondary"
                          >
                            閉じる
                          </Button>
                        </li>
                      </ul>
                    </DialogActions>
                  </Dialog>

                  {/* テストボタン用 */}
                  <Dialog
                    open={test.open}
                    aria-labelledby="draggable-dialog-title"
                    fullWidth
                    maxWidth="lg"
                  >
                    {/* <CastForEducationIcon
                                            className={classes.castFEIcon}
                                        /> */}
                    <DialogTitle
                      id="draggable-dialog-title"
                      style={{
                        textAlign: "center",
                        // fontSize: 'medium',
                        // margin: '20px',
                      }}
                    >
                      ＜テスト情報＞
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                      // style={{

                      // }}
                      >
                        {/* avatar */}
                        {/* <img
                                                    src="../../h.suzuki.png"
                                                    style={{
                                                        // margin: '20px',
                                                        width: '100px',
                                                    }}
                                                    alt={test.rowData.avatar}
                                                /> */}
                        研修名：　{test.rowData.category}
                        <br />
                        講座名：　{test.rowData.name}
                        <br />
                        講師名：　{test.rowData.instructor}
                        <br />
                        説　明：　{test.rowData.detail}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      style={{
                        width: "95%",
                      }}
                    >
                      <ul className={classes.ul}>
                        <li>
                          <a
                            className={classes.urlButtons}
                            // variant="contained" color="primary"
                            href={test.rowData.testUrl}
                            target="_blank"
                          >
                            <Button
                              // autoFocus
                              variant="contained"
                              color="primary"
                              className={classes.buttons}
                            >
                              テスト開始
                            </Button>
                          </a>
                        </li>
                        <li>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttons}
                            onClick={() =>
                              setTest({ open: false, rowData: test.rowData })
                            }
                            color="secondary"
                          >
                            閉じる
                          </Button>
                        </li>
                      </ul>
                    </DialogActions>
                  </Dialog>
                </Table>
              ) : (
                ""
              )}
            </TableContainer>
          </div>
        ) : (
          // スマホ表示
          <div>
            <TableContainer className={classes.root} component={Paper}>
              {stateAuth.currentUser ? (
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  aria-label="enhanced table"
                  fixedheader="false"
                  style={{ width: "100%", tableLayout: "auto" }}
                >
                  <MaterialTable
                    // style={{ paddingLeft: "10px" }}
                    title="講座一覧"
                    className={classes.title}
                    style={{ fontSize: "10px" }}
                    spacer="none"
                    // size='small'
                    // style={{ height: (dense ? 33 : 53) * 11 }}

                    // 入れると色つくけどソートが効かない（Firebaseの場合のみ）
                    icons={{
                      SortArrow: React.forwardRef((props, ref) => (
                        <ArrowDownward
                          // <ArrowUpward
                          {...props}
                          ref={ref}
                          style={{ color: colors.red[800] }}
                        />
                      )),
                    }}
                    // tableRef={tableRef}
                    // columns={columnListSP}
                    columns={columnListSP}
                    className={classes.columnListSP}
                    // {theme.breakpoints.up('xs') ? }}
                    data={data}
                    key={data.id}
                    // 保存＆編集
                    actions={[
                      // 動画視聴ボタン｜行クリック時のポップアップ
                      {
                        icon: () => (
                          <OndemandVideoSharpIcon
                            className={classes.muiSvgIconRoot}
                            style={{}}
                          />
                        ),
                        tooltip: "視聴する",
                        onClick: (evt, rowData) => {
                          evt.preventDefault();
                          setStatus({ open: true, rowData: rowData });
                        },
                      },
                      // テストボタン｜行クリック時のポップアップ
                      {
                        icon: () => (
                          <CastForEducationIcon
                            className={classes.muiSvgIconRoot}
                            style={{}}
                          />
                        ),
                        tooltip: "確認テスト",
                        onClick: (evt, rowData) => {
                          evt.preventDefault();
                          setTest({ open: true, rowData: rowData });

                          // open: true, rowData: status.rowData
                        },
                        // open: {status.open},
                        // onClick: (evt, rowData) => alert("開始しますか？" + rowData.id + " : " + rowData.testUrl),
                      },
                      // PDF出力
                      // {
                      //     icon: () => <Pdf userId={stateAuth.id} className={classes.muiSvgIconRoot}
                      //     // style={{
                      //     //     colors: "gray"
                      //     // }}
                      //     />,
                      //     // />,
                      //     // tooltip: 'PDFを出力する',
                      //     // onClick: () => downloadPdf(),

                      //     // onClick: () => {stateAuth.id ? <Pdf userId={stateAuth.id} /> : ''},
                      //     // onClick: () => <GetAppRounded />,
                      //     isFreeAction: true,
                      // },
                    ]}
                    options={{
                      showTitle: true, // * タイトル表示有無
                      addRowPosition: "first",
                      actionsColumnIndex: -1,
                      resizable: false, //　列のサイズ調整ができるはずだったが、できない・・
                      columnResizable: false,
                      tableLayout: "auto", // 可変幅
                      // tableLayout: "fixed", // 固定幅
                      // size: (dense ? 'small' : 'medium'),
                      // height: (dense ? 33 : 53) * 11,

                      sorting: true, // * 自動ソート
                      search: false, // * 検索機能
                      searchFieldAlignment: "right", // * 検索機能右寄せ
                      searchAutoFocus: true,
                      paging: true,
                      // TBLヘッダー固定時の最適値：　MacPro(13-inch, 2019)
                      maxBodyHeight: 500,
                      minBodyHeight: 350,
                      // padding: "default",
                      // padding: "dense",
                      // dense: "small",
                      // dense: "medium",
                      // detailPanelColumnAlignment: "left",　//詳細表示ボタン表示位置
                      // toolbar: false,　// ツールバーの表示有無
                      // toolbarButtonAlignment: "right",
                      pageSizeOptions: [10, 20, 50, 100], // * ページング機能
                      // paginationPosition: "both", // * ページネーション設定「上下表示」
                      // selection: true, // ★ 行選択可能
                      // showSelectAllCheckbox: true,
                      // showTextRowsSelected: false,
                      // 表示項目設定（表示：非表示）
                      // grouping: true,
                      // 2021/12/12 h.suzuki add ダウンロードボタン配置対応
                      // columnsButton: true, //項目表示・非表示ボタン
                      // 選択させない処理
                      // selectionProps: rowData => ({
                      //     disabled: true,
                      //     color: "primary"
                      // }),
                      draggable: false, // * 全ての列を選択移動可能
                      // actionsColumnIndex: -1,
                      filtering: false, // ★ 全ての列にフィルターを設置
                      // 選択時に行に色つけるけど、checkBoxと相性悪いため、却下
                      // rowStyle: rowData => ({
                      //     backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                      // }),
                      headerStyle: {
                        // ヘッダーを固定したままスクロールできる
                        position: "sticky",
                        top: 0,
                        backgroundImage:
                          "linear-gradient(to bottom, #fdd6bd 0%, #f794a4 100%)",
                        color: "#000",
                        fontSize: "14px",
                        // fontSize: 'small',
                        // fontSize: 'middle',
                        // fontSize: 'medium',
                        // fontSize: 'large',
                        textAlign: "left",
                        // fontWeight: 'bold', //　しない方が綺麗
                      },
                    }}
                    localization={localizationJapanese}
                  />
                  {/* 視聴ボタン用 */}
                  <Dialog
                    open={status.open}
                    aria-labelledby="draggable-dialog-title"
                    fullWidth
                    maxWidth="lg"
                  >
                    <DialogTitle
                      id="draggable-dialog-title"
                      style={{
                        textAlign: "center",
                      }}
                    >
                      ＜詳細情報＞
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        style={
                          {
                            // fontSize: 'medium',
                            // margin: '20px',
                          }
                        }
                      >
                        {/* avatar */}
                        {/* <img
                                                    // src="../../h.suzuki.png"
                                                    // style={{
                                                    //     // marginTop: '0px',
                                                    //     marginLeft: '25%',
                                                    //     marginRight: '25%',
                                                    // position: "center",

                                                    //     width: '100px',
                                                    // }}
                                                    alt={status.rowData.avatar}
                                                /> */}
                        講師名：　
                        <br />
                        {status.rowData.instructor}
                        <br />
                        <br />
                        研修名：　
                        <br />
                        {status.rowData.category}
                        <br />
                        <br />
                        講座名：　
                        <br />
                        {status.rowData.name}
                        <br />
                        <br />
                        説　明：　
                        <br />
                        {status.rowData.detail}
                        <br />
                        <br />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <ul className={classes.ul}>
                        <li>
                          <a
                            className={classes.urlButtons}
                            // variant="contained" color="primary"
                            href={status.rowData.movieUrl}
                            target="_blank"
                          >
                            <Button
                              // autoFocus
                              variant="contained"
                              color="primary"
                              className={classes.sp_buttons}
                              // style={{ width: "100%", margin: "auto" }}
                            >
                              視聴する
                            </Button>
                          </a>
                        </li>
                        <li>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.sp_buttons}
                            // style={{ width: "100%", margin: "auto" }}
                            onClick={() =>
                              setStatus({
                                open: false,
                                rowData: status.rowData,
                              })
                            }
                            color="secondary"
                          >
                            閉じる
                          </Button>
                        </li>
                      </ul>
                    </DialogActions>
                  </Dialog>

                  {/* テストボタン用 */}
                  {/* <CastForEducationIcon
                                            className={classes.castFEIcon}
                                        /> */}
                  <Dialog
                    open={test.open}
                    aria-labelledby="draggable-dialog-title"
                    fullWidth
                    maxWidth="lg"
                  >
                    <DialogTitle
                      id="draggable-dialog-title"
                      style={{
                        textAlign: "center",
                        // fontSize: 'medium',
                        // margin: '20px',
                      }}
                    >
                      ＜テスト情報＞
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText
                        style={
                          {
                            // fontSize: 'medium',
                            // margin: '20px',
                          }
                        }
                      >
                        {/* avatar */}
                        {/* <img
                                                    src="../../h.suzuki.png"
                                                    style={{
                                                        //     // marginTop: '0px',
                                                        //     marginLeft: '25%',
                                                        //     marginRight: '25%',
                                                        position: "center",

                                                        width: '100px',
                                                    }}
                                                    alt={status.rowData.avatar}
                                                /> */}
                        研修名：　
                        <br />
                        {test.rowData.category}
                        <br />
                        <br />
                        講座名：　
                        <br />
                        {test.rowData.name}
                        <br />
                        <br />
                        講師名：　
                        <br />
                        {test.rowData.instructor}
                        <br />
                        <br />
                        説　明：　
                        <br />
                        {test.rowData.detail}
                        <br />
                        <br />
                        設問数：　
                        <br />
                        ５問（選択式）
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <ul className={classes.ul}>
                        <li>
                          <a
                            className={classes.urlButtons}
                            // variant="contained" color="primary"
                            href={test.rowData.testUrl}
                            target="_blank"
                          >
                            <Button
                              // autoFocus
                              variant="contained"
                              color="primary"
                              className={classes.sp_buttons}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              テスト開始
                            </Button>
                          </a>
                        </li>
                        <li>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.sp_buttons}
                            onClick={() =>
                              setTest({ open: false, rowData: test.rowData })
                            }
                            color="secondary"
                          >
                            閉じる
                          </Button>
                        </li>
                      </ul>
                    </DialogActions>
                  </Dialog>
                </Table>
              ) : (
                ""
              )}
            </TableContainer>
          </div>
        )}
      </div>
    );
  }
};

// **** component ****
