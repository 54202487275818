import { useState, useEffect } from "react";
import { initializeApp, getApps } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  serverTimestamp,
  // 20240304 taniguchi add | TypeError: collection is not a functionエラーの解消のため追加
  collection,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  //   sendPasswordResetEmail,
} from "firebase/auth";
import { sendPasswordResetEmail as sendPasswordResetEmailFirebase } from "firebase/auth";

import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, auth, db };

const apps = getApps();
console.log(apps); // 初期化されたアプリのリストが表示される
if (apps.length > 0) {
  console.log("Firebase has been initialized");
} else {
  console.log("Firebase has not been initialized");
}

export const initiateAuth = (cb) => {
  auth.onAuthStateChanged((user) => {
    // ローカルにデータがなく、
    // ログインしたらcurrentUserを基にデータベースから情報を取得
    cb(user);
  });
};

export const signUp = async (option) => {
  const { mail, password } = option;
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      mail,
      password
    );
    const user = userCredential.user;

    const newOption = {
      ...option,
      avator: "",
      backgroundImage: "",
      createdAt: serverTimestamp(),
    };

    await setDoc(doc(db, "owners", user.uid), newOption);

    return user;
  } catch (error) {
    alert(error.message);
  }
};

export const login = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return user;
  } catch (error) {
    alert("メールアドレス又はパスワードが違います");
  }
};

export const logout = () => {
  signOut(auth);
  localStorage.removeItem("currentUser");
};

export const sendPasswordResetEmail = async (mail) => {
  try {
    await sendPasswordResetEmailFirebase(auth, mail);
    return `${mail}宛に再設定用メールを送信しました`;
  } catch (e) {
    return "メールアドレスが見つかりません";
  }
};

export const getCurrentUser = () => {
  const localValue = localStorage.getItem("currentUser");
  if (localValue) return JSON.parse(localValue);
  else return auth.currentUser;
};

export const getCurrentUserFromFirebase = () => {
  const user = auth.currentUser;
  console.log("from firebase user : ", user);
  return user;
};

export const getUserFromDb = async (collection, uid, cb) => {
  try {
    console.log("uid: ", uid);

    const docRef = doc(db, collection, uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      cb(docSnap.data());
    } else {
      alert("ユーザーデータが見つかりません");
    }
  } catch (e) {
    alert(e);
  }
};

export const getPdfDataFromDb = async (collectionName, uid) => {
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    let pdfData = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.id === uid) pdfData.push(data);
    });
    return pdfData;
  } catch (e) {
    console.log("collection: ", collectionName);
    console.log("uid: ", uid);
    console.log(e);
    alert("データを取得できませんでした");
  }
};

export const addToDb = async (collection, item, uid) => {
  const docRef = doc(db, collection, uid);
  try {
    await setDoc(docRef, item, { merge: true });
  } catch (e) {
    alert(e);
  }
};

export const updateTimeStamp = async (collection, id, item) => {
  const option = { ...item, updatedAt: serverTimestamp() };
  const docRef = doc(db, collection, id);
  try {
    await updateDoc(docRef, option);
  } catch (e) {
    alert(e);
  }
};

export const updateDocument = async (collection, id, item) => {
  const option = { ...item, updatedAt: serverTimestamp() };
  const docRef = doc(db, collection, id);
  try {
    await updateDoc(docRef, option);
  } catch (e) {
    alert(e);
  }
};

export const setToDocument = async (collection, id, item) => {
  const option = { ...item, updatedAt: serverTimestamp() };
  const docRef = doc(db, collection, id);
  try {
    await setDoc(docRef, option, { merge: true });
  } catch (e) {
    alert(e);
  }
};

export const uploadImage = async (path, id, image, cb) => {
  const storageRef = ref(storage, `images/${path}/${id}/${image.name}`);
  try {
    await uploadString(storageRef, image, "data_url");
    const downloadURL = await getDownloadURL(storageRef);
    cb(downloadURL);
  } catch (error) {
    console.error(error);
  }
};

// import firebase from 'firebase/app'
// import "firebase/firestore";
// import 'firebase/auth';
// import "firebase/storage";
// import bcrypt from 'bcryptjs';
// import { getFromLocal, removeFromLocal } from './WebStorage'

// firebase.initializeApp({
//     apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID,
//     measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID
// });

// const db = firebase.firestore();

// export const initiateAuth = (cb) => {
//     firebase.auth().onAuthStateChanged(user => {
//         // ローカルにデータがなく、
//         // ログインしたらcurrentUserを基にデータベースから情報を取得
//         cb(user);
//     });
// }

// export const signUp = async (option) => {

//     // option = {
//     //     mail,
//     //     category,
//     //     name,
//     //     nameKana,
//     //     zip,
//     //     prefectures,
//     //     address1,
//     //     address2,
//     //     building,
//     //     tel,
//     //     fax,
//     //     staffName1,
//     //     staffNameKana1,
//     //     staffTel1,
//     //     staffName2,
//     //     staffNameKana2,
//     //     staffTel2,
//     //     activeFlg, // とりあえずは使わない
//     //     password,
//     //     createdAt,
//     // }

//     const saltRounds = 10;
//     const salt = bcrypt.genSaltSync(saltRounds);
//     const hash = bcrypt.hashSync(option.password, salt);
//     console.log(bcrypt.compareSync(option.password, hash)) // true

//     try {
//         const res = await firebase.auth().createUserWithEmailAndPassword(option.mail, option.password);

//         const newOption = {
//             ...option,
//             // password: hash, /* */
//             password: option.password,
//             avator: '',
//             backgroundImage: '',
//         };
//         // 新しいoptionを持って
//         // cloud firestoreに登録
//         await addToDb('owners', newOption, res.user.uid);

//         return res.user;
//     } catch (error) {
//         // var errorCode = error.code;
//         // var errorMessage = error.message;
//         alert(error.message);
//     }

// }

// // ユーザーをログインさせる関数
// export const login = async (email, password) => {
//     try {
//         if (email === '' || email === null || email.isEmpty) {
//             throw new Error('メールを入力して下さい');
//         }

//         if (password === '' || password === null || password.isEmpty) {
//             throw new Error('パスワードを入力して下さい');
//         }

//         const user = await firebase.auth().signInWithEmailAndPassword(email, password);
//         return user;
//     } catch (error) {
//         // alert(error.message);
//         alert('メールアドレス又はパスワードが違います')
//     }
// };

// export const logout = () => {
//     firebase.auth().signOut();
//     removeFromLocal('currentUser');
// }

// export const sendPasswordResetEmail = async (mail) => {
//     try {
//         await firebase.auth().sendPasswordResetEmail(mail);
//         return `${mail}宛に再設定用メールを送信しました`;
//     } catch (e) {
//         // const errorCode = e.code;
//         // const errorMessage = e.message;
//         // console.log('error : ', e);
//         return 'メールアドレスが見つかりません';
//     }
// }

// export const getCurrentUser = () => {
//     const localValue = getFromLocal('currentUser');
//     if (localValue) return localValue;
//     else return firebase.auth().currentUser;
// }

// export const getCurrentUserFromFirebase = () => {
//     const user = firebase.auth().currentUser;
//     // 2021/11/24 h.suzuki add 一旦ログを出すためにコメント解除
//     console.log('from firebase user : ', user)
//     return user;
// }

// // uidを元にデータを取得しstateAuth.currentUserに入れる
// export const getUserFromDb = async (collection, uid, cb) => {
//     // console.log('uid: ', uid)
//     let ref = db.collection(collection).doc(uid);
//     try {
//         const doc = await ref.get();
//         if (doc) cb(doc.data())
//     } catch (e) {
//         alert(e);
//     }
// }

// // uidを元にデータを取得
// export const getPdfDataFromDb = async (collection, uid) => {
//     // console.log('uid: ', uid)
//     let ref = db.collection(collection);
//     try {
//         const docs = await ref.get();
//         let pdfData = [];
//         docs.forEach(doc => {
//             if (doc.data().id == uid) pdfData.push(doc.data());
//         })

//         return pdfData;
//     } catch (e) {
//         alert('データを取得できませんでした');
//     }
// }
// // ********************************

// export const addToDb = async (collection, item, uid) => { // (string, obj)
//     const doc = { ...item, createdAt: firebase.firestore.Timestamp.now() };
//     try {
//         // const res = await db.collection(collection).add(item);
//         const res = await db.collection(collection).doc(uid).set(doc);
//         return res;
//     } catch (e) {
//         alert(e);
//     }
// }

// // 2021/11/28 h.suzuki add 視聴再生日時を更新する
// // ドキュメントの追加ではなくfieldの更新
// export const updateTimeStamp = async (collection, id, item) => {
//     const option = { ...item, updatedAt: firebase.firestore.Timestamp.now() };
//     const ref = db.collection(collection).doc(id);
//     try {
//         await ref.set(option, { merge: true }); // mergeをつけないとドキュメントがoptionだけになってしまう
//     } catch (e) {
//         alert(e);
//     }
// }
// // ドキュメントの追加ではなくfieldの更新
// export const updateDocument = async (collection, id, item) => {
//     const option = { ...item, updatedAt: firebase.firestore.Timestamp.now() };
//     const ref = db.collection(collection).doc(id);
//     try {
//         await ref.update(option);
//     } catch (e) {
//         alert(e);
//     }
// }
// // ドキュメントへ追加
// export const setToDocument = async (collection, id, item) => {
//     const option = { ...item, updatedAt: firebase.firestore.Timestamp.now() };
//     const ref = db.collection(collection).doc(id);
//     try {
//         await ref.set(option, { merge: true }); // mergeをつけないとドキュメントがoptionだけになってしまう
//     } catch (e) {
//         alert(e);
//     }
// }

// // ************************************************
// // ************************************************
// // ************************************************
// // storage
// // ************************************************
// // ************************************************
// // ************************************************
// export const uploadImage = (path, id, image, cb) => {
//     const storageRef = firebase.storage().ref();
//     // アップロード処理
//     const uploadTask = storageRef.child(`images/${path}/${id}/${image.name}`).put(image);
//     uploadTask.on('state_changed', (snapshot) => {
//         // Observe state change events such as progress, pause, and resume
//         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//         const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         console.log('Upload is ' + progress + '% done');
//         switch (snapshot.state) {
//             case firebase.storage.TaskState.PAUSED: // or 'paused'
//                 console.log('Upload is paused');
//                 break;
//             case firebase.storage.TaskState.RUNNING: // or 'running'
//                 console.log('Upload is running');
//                 break;
//             default:
//                 console.log(`default`);
//         }
//     }, (error) => {
//         // Handle unsuccessful uploads
//         console.log(error);
//     }, () => {
//         // Handle successful uploads on complete
//         // For instance, get the download URL: https://firebasestorage.googleapis.com/...
//         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
//             console.log('File available at', downloadURL);
//             cb(downloadURL);
//         });
//     });
// }
