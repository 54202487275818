import { useApp } from "../../context";

import React, { useState } from "react";
// import { useHistory, Link } from 'react-router-dom';
import { useNavigate, Link } from "react-router-dom";
import { Box, Card, makeStyles } from "@material-ui/core";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { logout } from "../../modules/FirebaseModule";

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "#fff",
    color: "gray[200]",
    // 2021/11/15　追加修正　h.suzuki ヘッダーバー高さ変更
    height: "70px",
    // backgroundImage: "linear-gradient(to bottom, #ffc3a0 0%, #ff9a94 100%)",
    backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
    // backgroundImage: "linear-gradient(to bottom, #9795f0 0%, #fbc8d4 100%)",
    // [theme.breakpoints.up('sm')]: {
    //     width: `calc(100% - ${theme.palette.common.drawerWidth}px)`,
    //     marginLeft: theme.palette.common.drawerWidth,
    // },
  },
  toolbar: theme.mixins.toolbar, // テーマからバーの高さなどをロード
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //     display: 'none',
    // },
  },
  title: {
    flexGrow: 1,
  },
  name: {
    fontSize: "1.1em",
  },
}));
// **** useStyles ****

/*
 * **** component ****
 */
export const Header = ({ title, mobileOpen, setMobileOpen }) => {
  const { stateAuth, dispatchAuth } = useApp();
  const classes = useStyles();
  // const history = useHistory();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /*
   * method
   */
  const handleDrawerToggle = () => {
    console.log("drawerToggle");
    setMobileOpen(!mobileOpen);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToLogin = () => {
    // history.push("/login");
    navigate("/login");
  };

  const handleLogout = () => {
    console.log("handleLogout");
    logout();
    dispatchAuth({ type: "SET_CURRENT_USER", payload: null });
    dispatchAuth({ type: "HANDLE_LOGIN", payload: false });
  };

  /*
   * render
   */
  return (
    <AppBar position="static" color="primary" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          // onClick={handleDrawerToggle}
        >
          {/* <MenuIcon /> */}

          <img src={`${process.env.PUBLIC_URL}/kaigo_college_menu_bar.png`} />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>

        <Typography variant="h6" className={classes.name}>
          {stateAuth.currentUser?.name}
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            {stateAuth.currentUser ? (
              <div>
                {/* <Link to="/" style={{ textDecoration: 'none' }}><MenuItem >ホーム</MenuItem></Link> */}
                {/* 2021/11/14　追加修正　h.suzuki リンク配色変更*/}
                <Box>
                  <MenuItem
                    style={{
                      backgroundImage:
                        "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
                    }}
                  >
                    アカウント管理
                  </MenuItem>
                </Box>
                {/* <Link
                  to="/profile"
                  style={{
                    textDecoration: "none",
                    color: "#087cc9",
                    fontSize: "0.85em",
                    fontweight: "bold",
                    marginTop: "16px",
                  }}
                // ><MenuItem >{stateAuth.currentUser.name}</MenuItem>
                >
                  <MenuItem>{stateAuth.currentUser.name}</MenuItem>
                </Link> */}
                <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
              </div>
            ) : (
              <MenuItem onClick={handleToLogin}>ログイン</MenuItem>
            )}
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const Copyright = () => {
  return (
    <Typography variant="body2" color="secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" to="/">
        開発元：　RuDoLf Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

// **** component ****
