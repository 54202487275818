// import React from 'react';
import React, { useEffect, useState, useRef, userRef } from 'react';
// import MaterialTable from 'material-table';
// import GenericTemplate from '../templates/GenericTemplate';

import { Link } from "react-router-dom";
import { ButtonBase, Hidden, makeStyles } from '@material-ui/core';
// import SortableTable from 'react-sortable-table';
// import { ArrowUpward, ArrowDownward, GetAppOutlined, GetAppRounded, Palette } from '@material-ui/icons';
import * as colors from "@material-ui/core/colors";

// システム日時登録用
import { useApp } from '../context'

import * as functions from 'firebase/functions';
import { format } from 'date-fns';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
// import { updateTimeStamp, getCurrentUserFromFirebase } from '../modules/FirebaseModule';
import { doc, setDoc } from "firebase/firestore";
// import { updateTimeStamp } from '../modules/FirebaseModule';
// import { findUserById } from '../../server/src/util/db';
// import { render } from 'react-dom';
// PDF出力
// import jsPDF from 'jspdf';
import 'jspdf-autotable';

import { AppBar, Toolbar, Typography, Button, Icon, IconButton, FormControlLabel, Switch } from '@material-ui/core';
// import { Menu, MenuItem, MenuList } from '@material-ui/core';
import { CardActionArea, CardMedia, CardContent, CardActions } from '@material-ui/core';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid, Box } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Card } from '@material-ui/core';
// import Card from './Card';

// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ChatIcon from '@material-ui/icons/Chat';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// import ContactEmailIcon from '@material-ui/icons/ContactMail';
// import ContactMailOutlinedIcon from '@material-ui/icons/ContactMailOutlined';
// import ContactMailTwoToneIcon from '@material-ui/icons/ContactMailTwoTone';
// import SmsIcon from '@material-ui/icons/Sms';
// import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
// import LiveTvSharpIcon from '@material-ui/icons/LiveTvSharp';
// import VideoLibrarySharpIcon from '@material-ui/icons/VideoLibrarySharp';
// import OndemandVideoSharpIcon from '@material-ui/icons/OndemandVideoSharp';
// import OndemandVideoTwoToneIcon from '@material-ui/icons/OndemandVideoTwoTone';
// import CastForEducationIcon from '@material-ui/icons/CastForEducation';
// import SaveIcon from '@material-ui/icons/Save';

// import CastForEducationTwoToneIcon from '@material-ui/icons/CastForEducationTwoTone';
// import GetAppIcon from '@material-ui/icons/GetAppIcon';
// import AccountBoxIcon from '@material-ui/icons/AccountBox';
// import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
// import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
// import Typography from '@material-ui/core/Typography';
// import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
// import { createTheme } from '@material-ui/core/styles';
// import pdfMake from 'pdfmake/build/pdfmake';
// import pdfFonts from 'pdfmake/build/vfs_fonts';

// 2021/12/08 h.suzuki add 「material-table」の日本語Localization対応
import { Localization } from "material-table";
const localizationJapanese: Localization = {
    error: "エラー",
    body: {
        emptyDataSourceMessage: "該当するレコードがありません。",
        filterRow: {
            filterPlaceHolder: "",
            filterTooltip: "フィルター",
        },
        editRow: {
            saveTooltip: "保存",
            cancelTooltip: "キャンセル",
            deleteText: "この行を削除しますか？",
        },
        addTooltip: "追加",
        deleteTooltip: "削除",
        editTooltip: "編集",
    },
    header: {
        // actions: "視聴／テスト",
    },
    grouping: {
        groupedBy: "グループ化:",
        placeholder: "ヘッダーをドラッグ ...",
    },
    pagination: {
        firstTooltip: "最初のページ",
        firstAriaLabel: "最初のページ",
        previousTooltip: "前のページ",
        previousAriaLabel: "前のページ",
        nextTooltip: "次のページ",
        nextAriaLabel: "次のページ",
        labelDisplayedRows: "{from}-{to} 全{count}件",
        labelRowsPerPage: "ページあたりの行数:",
        lastTooltip: "最後のページ",
        lastAriaLabel: "最後のページ",
        labelRowsSelect: "行",
    },
    toolbar: {
        addRemoveColumns: "列の追加、削除",
        nRowsSelected: "{0} 行選択",
        showColumnsTitle: "列の表示",
        showColumnsAriaLabel: "列の表示",
        exportTitle: "出力",
        exportAriaLabel: "出力",
        exportCSVName: "CSV出力",
        exportPDFName: "PDF出力",
        searchTooltip: "検索",
        searchPlaceholder: "検索",
        searchAriaLabel: "検索",
        clearSearchAriaLabel: "クリア",
    },
};
/* スタイルの記述をする */
const useStyles = makeStyles(theme => ({
    muiSvgIconRoot: {
        color: colors.pink[300],
        // margin: "300px, 0px",
        [theme.breakpoints.up('xs')]: {
            fontSize: "1.25em",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.5em",
        },
        [theme.breakpoints.up('md')]: {
            // fontSize: "1.75em",
            fontSize: "2.0em",
        },
        // [theme.breakpoints.up('lg')]: {
        //     fontSize: "2.0em",
        // },
        // [theme.breakpoints.up('xl')]: {
        //     fontSize: "2.0em",
        // },

        // [theme.breakpoints.up('sm')]
        // "@media (min-width:768px)": {
        //     width: `calc(100% - ${Number(process.env.REACT_APP_DRAWER_WIDTH)}px)`,
        //     marginLeft: Number(process.env.REACT_APP_DRAWER_WIDTH),
        //     // minWidth: 500,
    },
    root: {
        width: '25%',
        margin: "36px auto",
        padding: "0px",
        alignItems: "center",
        justifyContent: "center",
        // display: "flex",
    },
    main: {
        display: "flex",
    },
    // 現在は使っていない（マテリアルテーブル設定前に使用していた）
    head: {
        // [theme.breakpoints.up('xs')]: {
        //     fontSize: "0.15em",
        // },
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: "1.5em",
        // },
        // [theme.breakpoints.up('md')]: {
        //     // fontSize: "1.75em",
        //     fontSize: "1.25em",
        // },

        // backgroundColor: theme.palette.common.black,
        // backgroundColor: theme.palette.primary.main,
        // backgroundColor: theme.palette.secondary.main,
        // color: theme.palette.common.white,
        // backgroundImage: "linear-gradient(-20deg, #f794a4 0%, #fdd6bd 50%)",
        // backgroundImage: "linear-gradient(to bottom, #fccb90 0%, #ff9a94 100%)",
        // 2021/11/15　追加修正　h.suzuki テーブルバー高さ変更
        // backgroundImage: "linear-gradient(to bottom, #ffc3a0 0%, #ff9a94 100%)",
        // backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
        // backgroundImage: "linear-gradient(to bottom, #fdd6bd 0%, #f794a4 100%)",

        // backgroundImage: "linear-gradient(to bottom, #fac8b9 0%, #ff9a94 100%)",
        // backgroundImage: "linear-gradient(to bottom, #f5b4b0 0%, #ff9a94 100%)",
        // backgroundColor: "#FDD6BC",
        // backgroundColor: "#f5b4b0",
        // backgroundColor: "#fac8b9",
        // color: 'white',
    },
    title: {
        [theme.breakpoints.up('xs')]: {
            fontSize: "1.15em",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.5em",
        },
        [theme.breakpoints.up('md')]: {
            // fontSize: "1.75em",
            fontSize: "1.25em",
        },
    },
    // 2022/01/13 h.suzuki add レスポンシブ対応｜テーブルサイズ調整
    // columnListSP: {
    //     [theme.breakpoints.up('xs')]: {
    //         fontSize: "0.15em",
    //     },
    //     [theme.breakpoints.up('sm')]: {
    //         fontSize: "1.5em",
    //     },
    //     [theme.breakpoints.up('md')]: {
    //         // fontSize: "1.75em",
    //         fontSize: "1.25em",
    //     },
    // [theme.breakpoints.up('lg')]: {
    //     fontSize: "2.0em",
    // },
    // [theme.breakpoints.up('xl')]: {
    //     fontSize: "2.0em",
    // },        
    // },
    table: {
        // 2022/01/13 h.suzuki add レスポンシブ対応｜テーブルサイズ調整
        // minWidth: 'auto',
        // minHeight: 'auto',
        [theme.breakpoints.up('xs')]: {
            fontSize: "0.75em",
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: "1.5em",
        },
        [theme.breakpoints.up('md')]: {
            // fontSize: "1.75em",
            fontSize: "1.25em",
        },
        // [theme.breakpoints.up('lg')]: {
        //     fontSize: "2.0em",
        // },
        // [theme.breakpoints.up('xl')]: {
        //     fontSize: "2.0em",
        // },columnListSP
    },
    tableCell: {
        padding: "0px 8px",
        // [theme.breakpoints.up('xs')]: {
        //     fontSize: "0.75em",
        // },
        // [theme.breakpoints.up('sm')]: {
        //     fontSize: "1.5em",
        // },
        // [theme.breakpoints.up('md')]: {
        //     // fontSize: "1.75em",
        //     fontSize: "1.25em",
        // },
    },
    cards: {
        width: '25%',
        margin: "36px auto",
        padding: "0px",
        alignItems: "center",
        justifyContent: "center",
        // display: "flex",
    },
    userStatus: {
        borderRadius: 4,
        border: '1px solid #ced4da',
        // border: '1px solid #ff9a94',
        padding: '4px 16px 4px 8px',
        width: '140px',
    },
    castFEIcon: {
        color: colors.pink[300],
        fontSize: '4.0em',
        margin: '30px',

    },
    urls: {
        // textDecoration: 'none',
        color: "skyblue",
        margin: "20px",
    },
    urlButtons: {
        textDecoration: 'none',
        color: '#ffffff',

        // minWidth: "150px",
        // minHeight: "auto",
    },
    ul: {
        listStyle: 'none',
        paddingLeft: '0',
        display: 'flex',

    },
    buttons: {
        // backgroundImage: "linear-gradient(to bottom, #fdd6bd 0%, #f794a4 100%)",
        // margin: "16px",
        minWidth: "150px",
        minHeight: "auto",
        backgroundColor: "",
        // borderRadius: 4,
        border: '1px solid #ff9a94',
        // color: "#087cc9",
        // fontSize: "1.0em",
        // fontweight: "bold",
        textDecoration: 'none',
        textAlign: 'center',
        position: 'center',
    },
    alert: {
        animation: '$flash 1s linear infinite',
        color: 'red',
    },

    '@keyframes flash': {
        '0%': {
            opacity: 1,
        },

        '50%': {
            opacity: 0,
        },

        '100%': {
            opacity: 1,
        },
    },
}));
/*
 * **** component ****
 */
// 2022/01/18（火） h.suzuki add Card表示対応

export const Menu2 = ({ tableData, openVideo, changeStatus, userForMemoRef, setIsOpenMemo, removeUserRef, setIsCheckRemove }) => {
    const classes = useStyles();
    /*
     * method
     */
    // 2021/11/27 h.suzuki add システム日時取得
    // const myTimestamp = firebase.firestore.Timestamp.now();
    // const myToDated = myTimestamp.toDate();
    // const myShapedTime = format(myToDated, 'yyyy.MM.dd HH:mm');
    // const fbTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    // 
    // const columnListSP = [
    //     // { title: "研修メニュー", field: "category", headerStyle: { whiteSpace: 'nowrap', }, defaultSort: 'asc', display: 'none' },
    //     { title: "カテゴリ", field: "category2", headerStyle: { whiteSpace: 'nowrap', } },
    //     { title: "講座名", field: "name", sorting: false, headerStyle: { whiteSpace: 'nowrap', } },
    // ];
    // const columnList = [
    //     { title: "ID", field: "id", headerStyle: { whiteSpace: 'nowrap', }, editable: "never", defaultSort: 'asc' },
    //     // { title: "avatar", field: "avatar", sorting: false, headerStyle: { whiteSpace: 'nowrap', }, editable: "never", export: false },
    //     // { title: "タイプ", field: "type", headerStyle: { whiteSpace: 'nowrap',}, removable: true },
    //     { title: "研修メニュー", field: "category", headerStyle: { whiteSpace: 'nowrap', }, defaultSort: 'asc' },
    //     { title: "カテゴリ", field: "category2", headerStyle: { whiteSpace: 'nowrap', } },
    //     { title: "講座名", field: "name", sorting: false, headerStyle: { whiteSpace: 'nowrap', } },
    // ];
    // { title: "講座詳細", field: "detail", sorting: false, headerStyle: { whiteSpace: 'nowrap', } },
    // { title: "講師", field: "instructor", sorting: false, headerStyle: { whiteSpace: 'nowrap', } },
    // { title: "動画視聴", field: "movieUrl", sorting: false, headerStyle: { whiteSpace: 'nowrap', }, editable: "never", export: false },
    // { title: "テスト", field: "testUrl", sorting: false, headerStyle: { whiteSpace: 'nowrap', }, editable: "never", export: false },
    // { title: "アクション", field: "action", editable: "never", export: false },
    // { title: "description", field: "description" },

    // const [selectedRow, setSelectedRow] = useState(null);
    // 2021/12/10 h.suzuki add　DBデータmap取得処理（courses）
    // const [data, setData] = useState([]);
    // const emptyRows = data.length;

    // 2021/12/11 h.suzuki add ポップアップ対応（視聴）
    // const [status, setStatus] = React.useState({ open: false, rowData: {} });
    // 2022/01/16 h.suzuki add ポップアップ対応２（テスト）
    // const [test, setTest] = React.useState({ open: false, rowData: {} });

    // 2022/01/18 h.suzuki add 利用規約・制約対応
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const createTableHeader = (labels) => {
        const header = labels.map((label, index) => <TableCell align="center" key={`${label}_${index}`}>{label}</TableCell>);
        return <TableRow>{header}</TableRow>;
    }

    const createCardBody = () => {
        console.log('tableData: ', tableData);
        let body = [];
        if (tableData.length > 0) {
            body = tableData.map((user, index) => {
                let status = '';
                let checkDatetime = '';
                if (user.status) status = user.status;
                if (user?.checkDatetime) {
                    const date = new Date(user.checkDatetime);
                    console.log('checkDatatime');
                    if (date == 'Invalid Date') { console.log(' invalid ') }
                    console.log('date : ', date);
                    let month = (date.getMonth() + 1).toString();
                    if (month.length < 2) { month = "0" + month; }
                    let day = (date.getDate()).toString();
                    if (day.length < 2) { day = "0" + day; }
                    let hours = date.getHours().toString();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = date.getMinutes().toString();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    checkDatetime = date.getFullYear() + "." + month + "." + day + " " + hours + ":" + minutes;
                }

                return (
                    <div>
                        {/* <TableRow key={`${user.name}_${index}`}>
                            <TableCell component="th" scope="row" align="center">
                            </TableCell>
                        </TableRow> */}
                        <div className={classes.main}>
                            <Card className={classes.root}
                                sx={{ maxWidth: 345 }}
                            >
                                <CardActionArea>
                                    <CardMedia
                                        className={classes.media}
                                        component="img"
                                        width="100px"
                                        height="auto"
                                        image="../../kaigo_college_menu_bar.png"
                                        alt="講座画像"
                                        title="Contemplative Reptile"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            タイトル
                            </Typography>
                                        <Typography variant="body2" color="secondary" component="p">
                                            説明
                            </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions>
                                    <Button size="small" color="primary"
                                        variant="contained" color="primary"
                                        className={classes.buttons}
                                    >
                                        Share
                            </Button>
                                    <Button size="small" color="primary"
                                        variant="contained" color="primary"
                                        className={classes.buttons}
                                    >
                                        Learn More
                            </Button>
                                </CardActions>
                            </Card>
                        </div>

                    </div>
                )
            }
            );
        } else {
            body = (
                // <TableRow></TableRow>
                <Card />
            )
        }

        return body;
    }

    /*
     * render
     */
    return (
        <div className={classes.root} component={Card}>
            {/* <TableContainer className={classes.root} component={Paper}> */}
            {/* <Table className={classes.table} aria-label="simple table"> */}
            {/* <TableHead className={classes.head}>
                        {createTableHeader(['利用者名', '確認日時', 'みまもり確認', 'メモ', '異常検知', '削除'])}
                    </TableHead> */}
            <TableBody>
                {createCardBody()}
            </TableBody>
            {/* /                </Table> */}
            {/* </TableContainer> */}
            {/* 2022/01/18 h.suzuki add Card形式での一覧画面作成　スマホ対応に向けてテスト作成
            <div className={classes.main}>
                <Card className={classes.root}
                    sx={{ maxWidth: 345 }}
                >
                    <CardActionArea>
                        <CardMedia
                            className={classes.media}
                            component="img"
                            width="100px"
                            height="auto"
                            image="../../kaigo_college_menu_bar.png"
                            alt="講座画像"
                            title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                タイトル
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                説明
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary"
                            variant="contained" color="primary"
                            className={classes.buttons}
                        >
                            Share
                            </Button>
                        <Button size="small" color="primary"
                            variant="contained" color="primary"
                            className={classes.buttons}
                        >
                            Learn More
                            </Button>
                    </CardActions>
                </Card>
            </div>

            <Button
                onClick={handleClickOpen('paper')}
                variant="contained" color="primary"
                className={classes.buttons}
            >利用規約</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">利用規約</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {[...new Array(10)]
                            .map(
                                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
                            )
                            .join('\n')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                        onClick={handleClickOpen('paper')}
                        variant="contained" color="primary"
                        className={classes.buttons}
                    >同意する</Button>
                    <Button
                        onClick={handleClose}
                        onClick={handleClickOpen('paper')}
                        variant="contained" color="primary"
                        className={classes.buttons}
                    >キャンセル</Button>
                </DialogActions>
            </Dialog>
            */}
        </div >
    );

}
// **** component ****
