import { useApp } from '../context'

import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import CloseIcon from '@material-ui/icons/Close';


const configuration = {
    "iceServers": [
        {
            "urls": 'stun:stun.l.google.com:19302'
        },
        {
            "urls": 'turn:18.136.198.54:3478',
            "credential": '123456',
            "username": 'username'
        }
    ]
};

let constraints = window.constraints = {
    audio: true,
    video: true
};

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: "500px",
        minWidth: "80%",
    },
    videoContainer: {
        // position: "absolute",
        backgroundColor: theme.palette.secondary["900"],
        width: "100%",
        height: "100%",
    },
    video: {
        '-webkit-transform': 'scaleX(-1)',
        // marginTop: '50px',
        width: '100%',
        height: '100%',
    },
    self: {
        position: "absolute",
        right: "30px",
        top: "50px",
        backgroundColor: theme.palette.secondary["100"],
        width: '150px',
        height: '100px',
        zIndex: '999',
    },
    footer: {
        height: '120px',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    icon: {
        fontSize: '3em',
        height: '60px',
        width: '60px',
        borderRadius: '50%',
        '-moz-border-radius': '50%',
        '-webkit-border-radius': '50%',
    },

}));
// **** useStyles ****
/*
 * **** component ****
 */
export const VideoWindow = () => {
    const { state, dispatch } = useApp();
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [showFooter, setShowFooter] = useState(false);
    const [isAudioMute, setIsAudioMute] = useState(false);
    const [isVideoOff, setIsVideoOff] = useState(false);

    const [closeVideo, setCloseVideo] = useState();

    useEffect(() => {
        window.addEventListener('message', (e) => {
            console.log('addEventListener message');
            console.log('e: ', e);
            switch (e.data.action) {
                case 'init':
                    console.log('init');
                    console.log(e.data.message);
                    break;
            }
        });

        doCallVideo();
    }, []);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    const doCallVideo = async () => {
        console.log('state.socketRef : ', state.socketRef);
        let data = {from: state.socketRef.current.id, to: state.selectedUser.socketID, uid: state.selectedUser.id};
        state.socketRef.current.emit('CALL_VIDEO', data);
        // ダイアログで表示させる
        // setIsCallVideo(true);
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            _handleSuccess(stream);
        } catch (e) {
            console.log(e);
        }
        // setIsCallingUserBackground(false);
    }

    const _handleSuccess = (stream) => {
        console.log('stream : ', stream);
        state.userVideo.current.srcObject = stream;
        // localStream.current = stream;

        // peer
        state.yourConnection.current = new RTCPeerConnection(configuration);
        // state.yourConnection.current.addStream(localStream.current);
        state.yourConnection.current.addStream(stream);
        state.yourConnection.current.onaddstream = (e) => {
            console.log("onaddstream call")
            // remoteStream.current = e.stream;
            // streamVideo.current.srcObject = remoteStream.current;
            state.streamVideo.current.srcObject = e.stream;
        };
        state.yourConnection.current.onicecandidate = (event) => {
            console.log('onicecandidate',event);
            if (event.candidate) {
                state.socketRef.current.emit('CANDIDATE',{
                    from: state.socketRef.current.id,
                    to: state.selectedUser.socketID,
                    candidate: event.candidate
                });
            }
        };
        state.yourConnection.current.createOffer((offer) => {
            console.log('CALL_OFFER',offer);
            state.socketRef.current.emit('CALL_OFFER',{to: state.selectedUser.socketID, from: state.socketRef.current.id, offer: offer});
            state.yourConnection.current.setLocalDescription(offer);

        }, (error) => {
            alert("An error has occurred.");
        });
    }

    const handleCall = () => {
        // setOpen(false);
    };

    const handleClose = () => {
        // closeVideo();
        window.opener.postMessage({
            action: 'windowClose',
            message: 'Hello'
        }, '*', );
        // setOpen(false);
    };

    /*
     * **** footer method ****
     */
    const handleShowFooter = (e) => {
        // e.preventDefault();
        setShowFooter(!showFooter);
    }

    const onHangupClicked = (e) => {
        // setPeers([]);
    }

    const toggleVideoEnabled = (e) => {
        e.stopPropagation();
        if (state.userVideo.current && state.userVideo.current.srcObject) {
            state.userVideo.current.srcObject.getVideoTracks()[0].enabled = isVideoOff;
            setIsVideoOff(!isVideoOff);
        }
    }
    const toggleAudioEnabled = (e) => {
        e.stopPropagation();
        if (state.userVideo.current && state.userVideo.current.srcObject) {
            state.userVideo.current.srcObject.getAudioTracks()[0].enabled = isAudioMute;
            // if (isAudioMute) myVideo.current.muted = false;
            // if (isAudioMute == false) myVideo.current.muted = true;
            setIsAudioMute(!isAudioMute);
        }
    }

    return (
        <Dialog
            classes={{paper: classes.paper}}
            fullScreen={fullScreen}
            open={open}
            // onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"見守り"}</DialogTitle>
            <DialogContent>
                <DialogContentText></DialogContentText>
                <div className={classes.videoContainer}
                    onClick={handleShowFooter}
                >
                    <video className={`${classes.video} ${classes.self}`} muted ref={state.userVideo} autoPlay playsInline />
                    <video className={`${classes.video}`} ref={state.streamVideo} autoPlay playsInline />
                    <div className={classes.footer}>
                        {isVideoOff ? <div className={classes.icon} style={{display: showFooter ? 'block' : 'none', backgroundColor: '#ffffff33'}}><VideocamOffIcon onClick={toggleVideoEnabled} style={{color: 'white', marginTop: '10px'}} /></div> : <div className={classes.icon} style={{display: showFooter ? 'block' : 'none', backgroundColor: '#ffffff33'}}><VideocamIcon onClick={toggleVideoEnabled} style={{color: 'white', marginTop: '10px'}} /></div>}
                        <div className={classes.icon} style={{display: showFooter ? 'block' : 'none', backgroundColor: 'red'}}><CloseIcon onClick={onHangupClicked} style={{color: 'white', marginTop: '10px'}} /></div>
                        {isAudioMute ? <div className={classes.icon} style={{display: showFooter ? 'block' : 'none', backgroundColor: '#ffffff33'}}><MicOffIcon onClick={toggleAudioEnabled} style={{color: 'white', marginTop: '10px'}} /></div> : <div className={classes.icon} style={{display: showFooter ? 'block' : 'none', backgroundColor: '#ffffff33'}}><MicIcon onClick={toggleAudioEnabled} style={{color: 'white', marginTop: '10px'}} /></div>}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                    OFF
                </Button>
            </DialogActions>
        </Dialog>
    );
}