import { useApp } from '../context'

import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form'

import { Bar } from './bar/Bar';
import { Loader } from '../component/Loader';
import { HistoryTable } from '../component/HistoryTable';
import { MemoDialog } from '../component/MemoDialog';
import { ToLogin } from '../component/ToLogin';

import { serverGet, serverPost } from '../modules/Ajax';

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
	root: theme.mixins.root,
	body: theme.mixins.body,
	header: {
		display: "block",
		padding: theme.spacing(0),
		alignItems: "center",
		justifyContent: "center",
		textAlign: "center",
		backgroundSize: "cover",
	},
	avator: {
		marginTop: "50px",
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "50px",
		width: "80px",
		height: "80px",
		borderRadius: "50%",
	},
	title: {
		// margin: "8px",
		color: "Gray",
		// color: "Pink",
		fontSize: "1.6em",
		fontWeight: "bold",
	},
	changeAvatorImage: {
		// textAlign: "center",
		// marginTop: "-20px",
	},
	changeBackgroundImage: {
		// textAlign: "center",
		// marginTop: "30px",

	},
}));
// **** useStyles ****

/*
 * **** component ****
 */
export const AccessHistory = () => {
	const { stateAuth, dispatchAuth } = useApp();
	const classes = useStyles();

	const [isLoader, setIsLoader] = useState(false);

	/*
	 * **** table ****
	 */
	const [isOpenMemo, setIsOpenMemo] = useState(false);
	const userForMemoRef = useRef();

	const [users, setUsers] = useState([]);


	// **** バリデーション ****
	const { register, handleSubmit, errors } = useForm({
		mode: "onSubmit",
		reValidateMode: "onBlur",
		criteriaMode: "all",
	});
	const onSubmit = (data) => {
		console.log(data);
	}

	useEffect(() => {
		let unmounted = false;

		(async () => {
			try {
				setIsLoader(true);
				//アンマウントされていなければ
				const tData = await fetchData(`${process.env.REACT_APP_API_BASE_URL}/api/admin/users-and-sort`);
				if (!unmounted) {
					// if (tData?.length > 0) {
					setUsers(tData);
					setIsLoader(false);
					// }
				};
			} catch (e) {
				alert("正しくデータを取得できませんでした。 Error : " + e);
			}
		})();

		return () => {
			unmounted = true;
		};
	}, [stateAuth.currentUser, stateAuth.id]);


	/*
	 * method
	 */
	const fetchData = async (url) => {
		// 時間の０字埋め
		let tData = [];
		try {
			if (stateAuth?.id && (stateAuth.id === process.env.REACT_APP_ADMIN || stateAuth.id === process.env.REACT_APP_ADMIN2)) {
				let res = await serverGet(url);
				if (res?.data?.length > 0) tData = res.data;
			} else if (stateAuth.id) {
				let res = await serverPost(url, { id: stateAuth.id });
				if (res?.data?.length > 0) tData = res.data;
			}
		} catch (e) {
			console.log('fetchData error : ', e);
		}

		return tData;
	}


	/*
	 * render
	 */
	return (
		<div className={classes.root}>
			{ isLoader === true ? <Loader /> : ''}
			{stateAuth.currentUser ?
				<Bar
					title={'履歴情報'}
				/>
				:
				''}
			<main className={classes.body}>
				{/* ログアウトした時表示 */}
				<ToLogin
					currentUser={stateAuth.currentUser}
				/>
				{stateAuth.currentUser ?
					isLoader === true ?
						<Loader />
						:
						<HistoryTable
							tableData={users}
							userForMemoRef={userForMemoRef}
							setIsOpenMemo={setIsOpenMemo}
							userId={stateAuth.id}
						/>
					: ""
				}

				{isOpenMemo ?
					<MemoDialog
						isOpen={isOpenMemo}
						setIsOpenMemo={setIsOpenMemo}
						userRef={userForMemoRef}
						serverPost={serverPost}
					/>
					: ""
				}
			</main>
		</div>
	);
}