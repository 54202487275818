export const initialStateAuth = {
    // route: false, // /login /signupに直でアクセスしたときはルートに飛ばす
    currentUser: null,
    id: null,

    username: '',
    mail: '',
    password: '',

    isLogin: false,
    // userLogin: {
    //     username: '',
    //     password: ''
    // },
    loginError: '',

};

/*
* action: {type: xxx, payload: yyy}
*/
export const reducerAuth = (state, action) => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
        console.log(action);
        console.log('stateAuth: ', state);
    }
    switch (action.type) {
        case "HANDLE_LOGIN": {
            return {
                ...state,
                isLogin: action.payload,
            };
        }
        // case "SET_ROUTE": {
        // return {
        //     ...state,
        //     route: true,
        // }
        // }
        // case "SIGNUP": {
        // return {
        //     ...state,
        //     cases: action.payload
        // }
        // }
        case "SET_CURRENT_USER": {
            return {
                ...state,
                currentUser: action.payload
            }
        }

        case "SET_ID": {
            return {
                ...state,
                id: action.payload
            }
        }

        default:
        return state;
    }
}