import React from "react";
// import { useHistory } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const imageUrl = `${process.env.PUBLIC_URL}/menu/TermOfService/`;

// スタイルの記述をする
const useStyles = makeStyles((theme) => ({
  outer: {
    // position: "absolute",
    width: "100%",
    height: "100%",
    // margin: "0 auto",
    // padding: theme.spacing(0),
    alignItems: "center",
    justifyContent: "center",
    // display: "block",
    backgroundColor: theme.palette.background.default,
    // backgroundColor: "#81BA32"
  },
  container: {
    position: "relative",
    width: "90%",
    height: "90%",
    margin: "0 auto",
    padding: theme.spacing(0),
    alignItems: "center",
    justifyContent: "center",
    display: "block",
  },
  img: {
    width: "100%",
  },
}));

//
export const LoginBeforeTermOfService = () => {
  const classes = useStyles();
  // const history = useHistory();
  const navigate = useNavigate();

  const loadImg = () => {
    const list = [];
    list.push(<img key="1" className={classes.img} src={`${imageUrl}1.jpg`} />);
    list.push(<img key="2" className={classes.img} src={`${imageUrl}2.jpg`} />);
    list.push(<img key="3" className={classes.img} src={`${imageUrl}3.jpg`} />);
    list.push(<img key="4" className={classes.img} src={`${imageUrl}4.jpg`} />);
    list.push(<img key="5" className={classes.img} src={`${imageUrl}5.jpg`} />);
    return list;
  };

  const onClick = () => {
    // history.goBack();
    navigate(-1);
  };

  return (
    <div className={classes.outer}>
      <div className={classes.container}>
        {loadImg()}
        {/* <Button
					variant="contained" color="primary"
					onClick={onClick}
				>戻る</Button> */}
      </div>
    </div>
  );
};
