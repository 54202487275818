import { useApp } from "../context";

import React, { useEffect, useState, useRef } from "react";
// import { useHistory, useParams } from "react-router-dom"
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles, TextField, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

import { Bar } from "./bar/Bar";
import { Loader } from "../component/Loader";
import { ToLogin } from "../component/ToLogin";
import { CheckDialog } from "../component/CheckDialog";

import { serverPost } from "../modules/Ajax";
import { uploadImage } from "../modules/FirebaseModule";

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles((theme) => ({
  root: theme.mixins.root,
  body: theme.mixins.body,
  titleContainer: {
    display: "block",
    margin: "10px",
  },
  title: {
    display: "initial",
    color: "Gray",
    fontSize: "1.6em",
    fontWeight: "bold",
    padding: "10px",
  },
  cardRoot: {
    // margin: "32px",
    width: "100%",
    overflow: "scroll",
    // display: "flex",
  },
  admin: {
    display: "flex",
  },
  avatorContainer: {
    float: "right",
    paddingRight: "5%",
  },
  avator: {
    float: "right",
    minWidth: "300px",
    minHeight: "300px",
    maxWidth: "300px",
    maxHeight: "300px",
  },
  inputContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px",
    },
    // minWidth: "350px",
    maxWidth: "500px",
    // textAlign: "right",
  },
  inputSet: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
    },
    width: "400px",
    height: "45px",
    display: "flex",
    position: "relative",
    // marginLeft: "auto",
    // textAlign: "right",
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      width: "120px",
    },
    width: "160px",
    textAlign: "right",
  },
  textField: {
    [theme.breakpoints.down("sm")]: {
      width: "160px",
    },
    width: "200px",
    height: "45px",
    // marginRight: "auto",
    // marginLeft: "auto",
    position: "absolute",
    right: 0,
    // textAlign: "right",
  },
  inputMemo: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    marginTop: "32px",
    marginBottom: "160px",
    width: "90%",
    height: "45px",
    display: "flex",
    position: "relative",
  },
  memo: {
    width: "80%",
  },
  cardBtn: {
    // margin: "0 0px 20px 0",
    // marginLeft: "64px",
    margin: "0 0 0 auto",
    marginTop: "20px",
    marginRight: "80px",
    marginBottom: "40px",
  },
}));
// **** useStyles ****

/*
 * **** component ****
 */
export const UserEdit = () => {
  const { stateAuth } = useApp();
  const classes = useStyles();
  // idからfirebaseのユーザーを取得
  const { id } = useParams();
  // const history = useHistory();
  const navigate = useNavigate();

  const [isLoader, setIsLoader] = useState(false);
  const [isOpenCheck, setIsOpenCheck] = useState(false);

  // ユーザー側
  // const userRef = useState(null);
  const [user, setUser] = useState(null);
  const userAvator = useRef();
  const [avatorImg, setAvatorImg] = useState(""); //
  /* ownerIdはadminで操作できるように */
  const [ownerId, setOwnerId] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [roomId, setRoomId] = useState(""); // 今回は未使用
  /* ownerIdはadminで操作できるように */
  const [name, setName] = useState("");
  const [nameKana, setNameKana] = useState("");
  const [mail, setMail] = useState("");
  const [tel, setTel] = useState("");
  const [emergencyTel, setEmergencyTel] = useState("");
  const [zip, setZip] = useState("");
  const [prefectures, setPrefectures] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [building, setBuilding] = useState("");
  const [gender, setGender] = useState(""); // num
  const [age, setAge] = useState(""); // num
  const [underlyingDisease, setUnderlyingDisease] = useState("");
  const [carelevel, setCarelevel] = useState(""); // num
  const [level, setLevel] = useState(""); // num
  const [meal, setMeal] = useState("");
  const [medicine, setMedicine] = useState("");
  const [pneumoniaFlg, setPneumoniaFlg] = useState(false);
  const [emergencyFlg, setEmergencyFlg] = useState(false);
  const [familyZip, setFamilyZip] = useState("");
  const [familyPrefectures, setFamilyPrefectures] = useState("");
  const [familyAddress1, setFamilyAddress1] = useState("");
  const [familyAddress2, setFamilyAddress2] = useState("");
  const [familyBuilding, setFamilyBuilding] = useState("");
  const [familyNameKana, setFamilyNameKana] = useState("");
  const [familyRelationship, setFamilyRelationship] = useState(""); // num
  const [familyGender, setFamilyGender] = useState(""); // num
  const [familyAge, setFamilyAge] = useState(""); // num

  const [memo, setMemo] = useState(""); // num

  const [activeFlg, setActiveFlg] = useState(true);

  // **** バリデーション ****
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    criteriaMode: "all",
  });
  const onSubmit = (data) => {
    console.log(data);
    // updateInfo();
    setIsOpenCheck(true);
  };

  /* ******************************** */
  useEffect(() => {
    let unmounted = false;

    (async () => {
      try {
        setIsLoader(true);
        //アンマウントされていなければ
        // ユーザーデータを取得
        let res = await serverPost(
          `${process.env.REACT_APP_API_BASE_URL}/api/user/profile`,
          { id: id }
        );
        if (res.data && !unmounted) {
          setUser(res.data);

          setAvatorImg(res.data?.avator ?? "");

          setOwnerId(res.data?.ownerId ?? "");
          setOwnerName(res.data?.ownerName ?? "");
          setRoomId(res.data?.roomId ?? "");
          setName(res.data?.name ?? "");
          setNameKana(res.data?.nameKana ?? "");
          setMail(res.data?.mail ?? "");
          setTel(res.data?.tel ?? "");
          setEmergencyTel(res.data?.emergencyTel ?? "");
          setZip(res.data?.zip ?? "");
          setPrefectures(res.data?.prefectures ?? "");
          setAddress1(res.data?.address1 ?? "");
          setAddress2(res.data?.address2 ?? "");
          setBuilding(res.data?.building ?? "");
          setGender(res.data?.gender ?? "");
          setAge(res.data?.age ?? "");
          setUnderlyingDisease(res.data?.underlyingDisease ?? "");
          setCarelevel(res.data?.carelevel ?? "");
          setLevel(res.data?.level ?? "");
          setMeal(res.data?.meal ?? "");
          setMedicine(res.data?.medicine ?? "");
          setPneumoniaFlg(res.data?.pneumoniaFlg ?? false);
          setEmergencyFlg(res.data?.emergencyFlg ?? false);
          setFamilyZip(res.data?.familyZip ?? "");
          setFamilyPrefectures(res.data?.familyPrefectures ?? "");
          setFamilyAddress1(res.data?.familyAddress1 ?? "");
          setFamilyAddress2(res.data?.familyAddress2 ?? "");
          setFamilyBuilding(res.data?.familyBuilding ?? "");
          setFamilyNameKana(res.data?.familyNameKana ?? "");
          setFamilyRelationship(res.data?.familyRelationship ?? "");
          setFamilyGender(res.data?.familyGender ?? "");
          setFamilyAge(res.data?.familyAge ?? "");

          setMemo(res.data?.memo ?? "");

          setActiveFlg(res.data?.activeFlg ?? true);

          setIsLoader(false);
        }
      } catch (e) {
        alert("データを取得できません。 Error : " + e);
        setIsLoader(false);
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [id]);

  /*
   * method
   */
  const setText = (e, setFunc) => {
    setFunc(e.target.value);
  };

  const updateInfo = async () => {
    try {
      const options = {
        avator: avatorImg,
        ownerId,
        ownerName,
        roomId,
        name,
        nameKana,
        mail,
        tel,
        emergencyTel,
        zip,
        prefectures,
        address1,
        address2,
        building,
        gender: Number(gender),
        age: Number(age),
        underlyingDisease,
        carelevel: Number(carelevel),
        level: Number(level),
        meal,
        medicine,
        pneumoniaFlg,
        emergencyFlg,
        familyZip,
        familyPrefectures,
        familyAddress1,
        familyAddress2,
        familyBuilding,
        familyNameKana,
        familyRelationship: Number(familyRelationship),
        familyGender: Number(familyGender),
        familyAge: Number(familyAge),
        memo,
        activeFlg,
      };
      console.log("options: ", options);
      // dbへupdate
      setIsLoader(true);
      await serverPost(
        `${process.env.REACT_APP_API_BASE_URL}/api/user/update`,
        { id: id, options: options }
      );
      setIsLoader(false);

      alert("更新が完了しました");
      // history.replace('/');
      navigate("/");
    } catch (e) {
      setIsLoader(false);

      alert(e);
    }
  };

  const setAvator = (e) => {
    console.log("set avator");
    console.log("user.id : ", user.id);
    const image = e.target.files[0];
    userAvator.current.title = image.name;
    const reader = new FileReader();
    reader.onload = (event) => {
      userAvator.current.setAttribute("src", event.target.result);
    };
    reader.readAsDataURL(image);
    // storageに保存
    uploadImage("avator", user.id, image, (url) => {
      console.log("firebaseUrl: ", url);
      setAvatorImg(url);
    });
  };

  /*
   * render
   */
  return (
    <div className={classes.root}>
      {isLoader === true ? <Loader /> : ""}
      {stateAuth.currentUser ? <Bar title={"ユーザー"} /> : ""}
      {/* ログアウトした時 */}
      <ToLogin currentUser={stateAuth.currentUser} />

      {stateAuth.currentUser ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <main className={classes.body}>
            <div className={classes.titleContainer}>
              <div className={classes.title}>利用者情報</div>
            </div>
            <Card className={classes.cardRoot}>
              <CardContent>
                {stateAuth.id === process.env.REACT_APP_ADMIN ||
                stateAuth.id === process.env.REACT_APP_ADMIN2 ? (
                  <div className={classes.admin}>
                    <span
                      style={{
                        display: "flex",
                        width: "280px",
                        height: "50px",
                      }}
                    >
                      <p className={classes.plate}>オーナーID</p>
                      <TextField
                        type="text"
                        value={ownerId}
                        onChange={(e) => setText(e, setOwnerId)}
                        name="ownerId"
                        error={!!errors.ownerId}
                        helperText={errors?.ownerId?.message}
                        inputRef={register({
                          // required: "*必須項目",
                          maxLength: 40,
                        })}
                      />
                    </span>
                    <span
                      style={{
                        display: "flex",
                        width: "280px",
                        height: "50px",
                      }}
                    >
                      {/* <p className={classes.plate}>オーナーName</p>
										<TextField
											type="text"
											value={ownerName}
											onChange={(e) => setText(e, setOwnerName)}
											name="ownerName"
											error={!!errors.ownerName}
											helperText={errors?.ownerName?.message}
											inputRef={register({
												// required: "*必須項目",
												maxLength: 80,
											})}
										/>
										</span>
										<span style={{display: "flex", width: "280px", height: "50px"}}> */}
                      <p className={classes.plate}>ルームID</p>
                      <TextField
                        type="text"
                        value={roomId}
                        onChange={(e) => setText(e, setRoomId)}
                        name="roomId"
                        error={!!errors.roomId}
                        helperText={errors?.roomId?.message}
                        inputRef={register({
                          // required: "*必須項目",
                          maxLength: 40,
                        })}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
                {/*
                 * ユーザー情報
                 */}
                <div className={classes.avatorContainer}>
                  <img
                    ref={userAvator}
                    src={
                      avatorImg !== ""
                        ? avatorImg
                        : `${process.env.PUBLIC_URL}/avator_image.png`
                    }
                    className={classes.avator}
                  />
                  <div className={classes.changeAvatorImage}>
                    <label htmlFor="avatorFilePicker">画像を変更する</label>
                    <input
                      id="avatorFilePicker"
                      type="file"
                      style={{ display: "none" }}
                      onChange={setAvator}
                    />
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      氏名 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={name}
                      onChange={(e) => setText(e, setName)}
                      name="name"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      inputRef={register({
                        required: "*必須項目",
                        maxLength: 40,
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      フリガナ :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={nameKana}
                      onChange={(e) => setText(e, setNameKana)}
                      name="nameKana"
                      error={!!errors.nameKana}
                      helperText={errors?.nameKana?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        // maxLength: 40,
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      性別 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={gender}
                      onChange={(e) => setText(e, setGender)}
                      name="gender"
                      error={!!errors.gender}
                      helperText={errors?.gender?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        // pattern: {
                        // 	value: /^[0-9]{3}-[0-9]{4}$/,
                        // 	message: "xxx-xxxxで入力して下さい。(xは半角数字)"
                        // },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      年齢 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={age}
                      onChange={(e) => setText(e, setAge)}
                      name="age"
                      error={!!errors.age}
                      helperText={errors?.age?.message}
                      inputRef={register({
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "半角数字で入力して下さい",
                        },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      介護度 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={carelevel}
                      onChange={(e) => setText(e, setCarelevel)}
                      name="carelevel"
                      error={!!errors.carelevel}
                      helperText={errors?.carelevel?.message}
                      // inputRef={register({
                      // 	pattern: {
                      // 		value: /^[0-9]+$/,
                      // 		message: "半角数字で入力して下さい"
                      // 	},
                      // })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      電話番号 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={tel}
                      onChange={(e) => setText(e, setTel)}
                      placeholder={`09012345678`}
                      name="tel"
                      error={!!errors.tel}
                      helperText={errors?.tel?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "半角数字のみで入力して下さい",
                        },
                        minLength: {
                          value: 10,
                          message: "電話番号を入力してください",
                        },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      緊急連絡先 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={emergencyTel}
                      onChange={(e) => setText(e, setEmergencyTel)}
                      placeholder={`09022345678`}
                      name="emergencyTel"
                      error={!!errors.emergencyTel}
                      helperText={errors?.emergencyTel?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "半角数字のみで入力して下さい",
                        },
                        minLength: {
                          value: 10,
                          message: "電話番号を入力してください",
                        },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      郵便番号 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={zip}
                      onChange={(e) => setText(e, setZip)}
                      name="zip"
                      error={!!errors.zip}
                      helperText={errors?.zip?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        pattern: {
                          value: /^[0-9]{3}-[0-9]{4}$/,
                          message: "xxx-xxxxで入力して下さい。(xは半角数字)",
                        },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      都道府県 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={prefectures}
                      onChange={(e) => setText(e, setPrefectures)}
                      name="prefectures"
                      error={!!errors.prefectures}
                      helperText={errors?.prefectures?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        // pattern: {
                        // 	value: /^[0-9]{3}-[0-9]{4}$/,
                        // 	message: "xxx-xxxxで入力して下さい。(xは半角数字)"
                        // },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      住所１ :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={address1}
                      onChange={(e) => setText(e, setAddress1)}
                      name="address1"
                      error={!!errors.address1}
                      helperText={errors?.address1?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        // pattern: {
                        // 	value: /^[0-9]{3}-[0-9]{4}$/,
                        // 	message: "xxx-xxxxで入力して下さい。(xは半角数字)"
                        // },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      住所２ :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={address2}
                      onChange={(e) => setText(e, setAddress2)}
                      name="address2"
                      error={!!errors.address2}
                      helperText={errors?.address2?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        // pattern: {
                        // 	value: /^[0-9]{3}-[0-9]{4}$/,
                        // 	message: "xxx-xxxxで入力して下さい。(xは半角数字)"
                        // },
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      建物名等 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={building}
                      onChange={(e) => setText(e, setBuilding)}
                      name="building"
                      error={!!errors.building}
                      helperText={errors?.building?.message}
                      inputRef={register({
                        // required: "*必須項目",
                        // pattern: {
                        // 	value: /^[0-9]{3}-[0-9]{4}$/,
                        // 	message: "xxx-xxxxで入力して下さい。(xは半角数字)"
                        // },
                      })}
                    />
                  </span>
                  {/* <span className={classes.inputSet}><p className={classes.plate}>原疾患</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={underlyingDisease}
											onChange={(e) => setText(e, setUnderlyingDisease)}
											name="underlyingDisease"
											error={!!errors.underlyingDisease}
											helperText={errors?.underlyingDisease?.message}
											inputRef={register({
												// required: "*必須項目",
												// pattern: {
												// 	value: /^[0-9]{3}-[0-9]{4}$/,
												// 	message: "xxx-xxxxで入力して下さい。(xは半角数字)"
												// },
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>自立度（寝たきり度）</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={level}
											onChange={(e) => setText(e, setLevel)}
											name="level"
											error={!!errors.level}
											helperText={errors?.level?.message}
											inputRef={register({
												pattern: {
													value: /^[0-9]+$/,
													message: "半角数字で入力して下さい"
												},
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>食形態</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={meal}
											onChange={(e) => setText(e, setMeal)}
											name="meal"
											error={!!errors.meal}
											helperText={errors?.meal?.message}
											inputRef={register({
												// required: "*必須項目",
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>既往歴</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={medicine}
											onChange={(e) => setText(e, setMedicine)}
											name="medicine"
											error={!!errors.medicine}
											helperText={errors?.medicine?.message}
											inputRef={register({
												// required: "*必須項目",
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>肺炎有無</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={pneumoniaFlg}
											onChange={(e) => setText(e, setPneumoniaFlg)}
											name="pneumoniaFlg"
											error={!!errors.pneumoniaFlg}
											helperText={errors?.pneumoniaFlg?.message}
											inputRef={register({
												// required: "*必須項目",
											})}
										/>
									</span>
									<p className={classes.plate}>緊急フラグ</p>
									<TextField
										className={classes.textField}
										type="text"
										value={emergencyFlg}
										onChange={(e) => setText(e, setEmergencyFlg)}
										name="emergencyFlg"
										error={!!errors.emergencyFlg}
										helperText={errors?.emergencyFlg?.message}
										inputRef={register({
											// required: "*必須項目",
										})}
									/>
									<span className={classes.inputSet}><p className={classes.plate}>家族・郵便番号</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyZip}
											onChange={(e) => setText(e, setFamilyZip)}
											name="familyZip"
											error={!!errors.familyZip}
											helperText={errors?.familyZip?.message}
											inputRef={register({
												pattern: {
													value: /^[0-9]{3}-[0-9]{4}$/,
													message: "xxx-xxxxで入力して下さい。(xは半角数字)"
												},
											})}
										/>

									</span><span className={classes.inputSet}><p className={classes.plate}>家族・都道府県</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyPrefectures}
											onChange={(e) => setText(e, setFamilyPrefectures)}
											name="familyPrefectures"
											error={!!errors.familyPrefectures}
											helperText={errors?.familyPrefectures?.message}
											inputRef={register({
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>家族・住所1</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyAddress1}
											onChange={(e) => setText(e, setFamilyAddress1)}
											name="familyAddress1"
											error={!!errors.familyAddress1}
											helperText={errors?.familyAddress1?.message}
											inputRef={register({
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>家族・住所2</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyAddress2}
											onChange={(e) => setText(e, setFamilyAddress2)}
											name="familyAddress2"
											error={!!errors.familyAddress2}
											helperText={errors?.familyAddress2?.message}
											inputRef={register({
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>家族・住所3</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyBuilding}
											onChange={(e) => setText(e, setFamilyBuilding)}
											name="familyBuilding"
											error={!!errors.familyBuilding}
											helperText={errors?.familyBuilding?.message}
											inputRef={register({
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>家族氏名カナ</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyNameKana}
											onChange={(e) => setText(e, setFamilyNameKana)}
											name="familyNameKana"
											error={!!errors.familyNameKana}
											helperText={errors?.familyNameKana?.message}
											inputRef={register({
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>家族・続柄</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyRelationship}
											onChange={(e) => setText(e, setFamilyRelationship)}
											name="familyRelationship"
											error={!!errors.familyRelationship}
											helperText={errors?.familyRelationship?.message}
											inputRef={register({
											})}
										/>
									</span><span className={classes.inputSet}><p className={classes.plate}>家族・性別</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyGender}
											onChange={(e) => setText(e, setFamilyGender)}
											name="familyGender"
											error={!!errors.familyGender}
											helperText={errors?.familyGender?.message}
											inputRef={register({
											})}
										/>
									</span>
									<span className={classes.inputSet}>
										<p className={classes.plate}>家族・年齢</p>
										<TextField
											classes={{root: classes.textField}}
											type="text"
											value={familyAge}
											onChange={(e) => setText(e, setFamilyAge)}
											name="familyAge"
											error={!!errors.familyAge}
											helperText={errors?.familyAge?.message}
											inputRef={register({
												pattern: {
													value: /^[0-9]+$/,
													message: "半角数字で入力して下さい"
												},
											})}
										/>
									</span> */}
                </div>
                <span className={classes.inputMemo}>
                  <Typography classes={{ body1: classes.typo }} variant="body1">
                    メモ :
                  </Typography>
                  <TextField
                    classes={{ root: classes.textField }}
                    className={classes.memo}
                    variant="outlined"
                    type="text"
                    value={memo}
                    onChange={(e) => setText(e, setMemo)}
                    multiline
                    rows={8}
                    name="memo"
                    error={!!errors.memo}
                    helperText={errors?.memo?.message}
                    inputRef={register({
                      // required: "*必須項目",
                      // pattern: {
                      // 	value: /^[0-9]{3}-[0-9]{4}$/,
                      // 	message: "xxx-xxxxで入力して下さい。(xは半角数字)"
                      // },
                    })}
                  />
                </span>
              </CardContent>
              {/* **************************************************************** */}
              {/* **************************************************************** */}
              {/* **************************************************************** */}
              <CardActions>
                <Button
                  className={classes.cardBtn}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  更新する
                </Button>
              </CardActions>
            </Card>
          </main>{" "}
          {/* body */}
          <CheckDialog
            isOpen={isOpenCheck}
            setIsOpen={setIsOpenCheck}
            title={"ユーザー情報を更新しますか？"}
            func={updateInfo}
          />
        </form>
      ) : (
        ""
      )}
    </div>
  );
};
