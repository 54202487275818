import React, { useEffect, createContext, useContext, useReducer } from "react";

import { initialState, reducer } from './reducer'
import { initialStateAuth, reducerAuth } from './reducerAuth'

import { initiateAuth, getUserFromDb } from '../modules/FirebaseModule';
import { addToLocal, getFromLocal } from "../modules/WebStorage";

export const AppContext = createContext();

export const Provider = ({children}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [stateAuth, dispatchAuth] = useReducer(reducerAuth, initialStateAuth);

	useEffect(() => {
		// ローカルにcurrentUserあったら、そちらからロード
		const localCurrentUser = getFromLocal('currentUser');
		if (localCurrentUser) {
			dispatchAuth({type: 'SET_CURRENT_USER', payload: localCurrentUser});
			dispatchAuth({type: "HANDLE_LOGIN", payload: true});
		}
		// ログイン判定
		initiateAuth((user) => {
			if (user) dispatchAuth({type: 'SET_ID', payload: user.uid});
			if (!getFromLocal('currentUser') || getFromLocal('currentUser') === undefined) {
				if (user) getUserFromDb('owners', user.uid, (data) => {
					dispatchAuth({type: 'SET_CURRENT_USER', payload: data});
					// ローカルストレージにセット
					addToLocal('currentUser', data);
				});
			}
		});
		return () => {};
	}, []);

	return (
		<AppContext.Provider value={{
			state, dispatch,stateAuth,
			dispatchAuth,
		}}>
			{children}
		</AppContext.Provider>
	);
}
//
export const useApp = () => useContext(AppContext);