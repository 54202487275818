import React, { useEffect } from "react";
import { Link, Button } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import { Login } from "../container/auth/Login";

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    margin: "0 auto",
    width: "100%",
    height: "100%",
    zIndex: "1999",
    fontSize: "1.3em",
    background: "#fff",
  },
}));
// **** useStyles ****

/*
 * **** component ****
 */
export const ToLogin = ({ currentUser }) => {
  const classes = useStyles();
  // const history = useHistory();
  const navigate = useNavigate();

  useEffect(() => {
    // currentUserは非同期で入るから使えない↓
    // if (!currentUser) history.replace("/login");
    if (!currentUser) {
      navigate("/login");
    }
  }, [currentUser]);

  /*
   * render
   */
  return (
    <div>
      {currentUser ? (
        ""
      ) : (
        // <Link to='/login' className={classes.link}>ログイン画面へ</Link>
        <Login />
      )}
    </div>
  );
};
// **** component ****
