import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: "100px",
        minWidth: "50%",
    },
    text: {
        width: "100%",
    },

}));
// **** useStyles ****
/*
 * **** component ****
 */
export const CheckDialog = ({ isOpen, setIsOpen, title, func }) => {
    const classes = useStyles();

    useEffect(() => {
        let unmounted = false;
        // ユーザー情報を取得
        (async () => {
            try {
                //アンマウントされていなければ
				if (!unmounted) {};
            } catch (e) {
                alert("データを取得できません。 Error : " + e);
            }
        })();

        return () => {
			unmounted = true;
		};
    }, [])

    /*
     * method
     */
    const handleSave = async () => {
        func();
        setIsOpen(false);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <Dialog
            classes={{paper: classes.paper}}
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"確認"}</DialogTitle>
            <DialogContent>
                <DialogContentText>{title}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSave} color="primary">
                    はい
                </Button>
                <Button autoFocus onClick={handleClose} color="primary">
                    いいえ
                </Button>
            </DialogActions>
        </Dialog>
    );
}