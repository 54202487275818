import { createTheme, styled } from "@material-ui/core/styles";
// import * as createPalette from "@material-ui/core/styles/createPalette";
import { PaletteColorOptions } from "@material-ui/core/styles/createPalette";

// interface CustomPalette {
//     red: PaletteColorOptions; // light, main, dark, 50, 100, ..., 900, A100, ..., A700
//     pink: PaletteColorOptions;
//     green: PaletteColorOptions;
// }

// Material UIのテーマ上書き
export const theme = createTheme({
  // 20231225 taniguchi add
  direction: "ltr", // または 'rtl' に設定
  // カラーパレット追加
  palette: {
    type: "light",
    primary: {
      // main: '#ff7d75',
      main: "#ff9a94",
      50: "#ffbdb9",
      100: "#ffada8",
      200: "#ff9d97",
      300: "#ff8d86",
      400: "#ff7d75",
      500: "#ff6d64",
      600: "#ff5d53",
      700: "#ff4d42",
      800: "#ff3d31",
      900: "#ff2d20",
      A100: "#750700",
      A200: "#640600",
      A400: "#530500",
      A700: "#420400",
      contrastDefaultColor: "light",
      // textDecoration: 'none',
    },
    secondary: {
      main: "#ffffff",
      // main: 'linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)',
      50: "#ffbdb9",
      100: "#ffada8",
      200: "#ff9d97",
      300: "#ff8d86",
      400: "#ff7d75",
      500: "#ff6d64",
      600: "#ff5d53",
      700: "#ff4d42",
      800: "#ff3d31",
      900: "#ff2d20",
      A100: "#750700",
      A200: "#640600",
      A400: "#530500",
      A700: "#ffbdb9",
      A700: "#e33371",
      A700: "#ff2d20",
      // main: '#dedede',
      // '50': '#ffffff',
      // '100': '#f7f7f7',
      // '200': '#efefef',
      // '300': '#e6e6e6',
      // '400': '#dedede',
      // '500': '#d6d6d6',
      // '600': '#cdcdcd',
      // '700': '#c5c5c5',
      // '800': '#bcbcbc',
      // '900': '#b4b4b4',
      // 'A100': '#1b1b1b',
      // 'A200': '#121212',
      // 'A300': '#0a0a0a',
      // 'A400': '#010101',
      // 'A700': '#000',
      contrastDefaultColor: "light",
    },
    common: {
      drawerWidth: Number(process.env.REACT_APP_DRAWER_WIDTH),
    },
  },
  mixins: {
    root: {
      position: "absolute",
      width: "100%",
      margin: "0px auto",
      // "@media (max-width:375px)": {
      //     width: "100px",
      //     fontsize: "20px",
      // },
    },
    body: {
      // padding: theme.spacing(3), 8*3
      // padding: "24px",
      // 2021/11/14　追加修正　h.suzuki　端末ステータス取得UI調整
      // padding: "0px 36px 32px 36px",
      // 枠が必要な場合
      padding: "0px 18px 32px 18px",
      // [theme.breakpoints.up('sm')]
      // "@media (min-width:768px)": {
      //     width: `calc(100% - ${Number(process.env.REACT_APP_DRAWER_WIDTH)}px)`,
      //     marginLeft: Number(process.env.REACT_APP_DRAWER_WIDTH),
      // },
      alignItems: "center",
      justifyContent: "center",
    },
  },
  // レスポンシブのブレイクポイント
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0, // スマホ用
      // 'xs': 415,
      sm: 415, // スマホ2用
      md: 768, // タブレット用
      lg: 992, // PC用
      xl: 1024,
      // 'tablet': 640,
      // 'laptop': 1024,
      // 'desktop': 1280,
    },
  },
  typography: {
    useNextVariants: true, // Migration to typography v2
    // htmlFontSize: 10,
    // fontSize: 11,
    // fontSize: 10.5,
    fontFamily:
      '"ab-tori-a","fot-seurat-pron","Hiragino Maru Gothic ProN W4","ヒラギノ丸ゴ ProN W4","Hiragino Maru Gothic Pro W4","ヒラギノ丸ゴ Pro W4","Yu Gothic", "Yu Gothic Medium", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  // classのstyleを上書き
  overrides: {
    MuiButton: {
      root: {
        // ボタン内アルファベット文字を大文字変換しない
        // textTransform: 'none',
        // borderRadius: 30,
        // width: window.parent.screen.width - window.parent.screen.width / 10,
        // height: 50,
        // fontSize: '1.0em',
      },
      containedPrimary: {
        // ログインボタン制御
        color: "#ffffff",
        // fontWeight: 'bold',
      },
      containedSecondary: {
        // 新規登録ボタン制御
        color: "#ff7d75",
        // color: '#ffffff',
        // borderColor: '#ff9a94',
        // fontWeight: 'bold',
        // color: '#000099',
        // backgroundColor: '#000099',
      },
    },
    MuiInput: {
      root: {
        // width: window.parent.screen.width - window.parent.screen.width / 10,
      },
    },
    MuiSvgIcon: {
      root: {
        marginBottom: "-3px",
      },
    },
    MuiTypography: {
      body1: {
        // fontSize: '1em',
      },
      h6: {
        fontSize: "1.5em",
      },
    },
    MuiTable: {
      root: {
        // fontSize: '1em',
        // size: 'small',
        // '@media (min-width: 500px) .table': {
        // minWidth: '250px',
        // },
      },
    },
    MuiDialog: {
      paper: {
        // margin: '10px',
      },
    },
    MuiDialogContent: {
      root: {
        // '&:first-child': {
        //     paddingTop: "0px",
        // },
      },
    },
  },
});
export default theme;
