import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
    paper: {
        minHeight: "100px",
        minWidth: "50%",
    },
    text: {
        width: "100%",
    },

}));
// **** useStyles ****
/*
 * **** component ****
 */
export const CheckRemoveDialog = ({ setIsCheckRemove, removeUserRef, serverPost }) => {
    const classes = useStyles();

    useEffect(() => {
        let unmounted = false;
        // ユーザー情報を取得
        (async () => {
            try {
                //アンマウントされていなければ
				if (!unmounted) {};
            } catch (e) {
                alert("データを取得できません。 Error : " + e);
            }
        })();

        return () => {
			unmounted = true;
		};
    }, [])

    // post to server
    const handleSave = async () => {
        await serverPost(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_UPDATE}`, {id: removeUserRef.current.id, options: {ownerId: ''}});
        setIsCheckRemove(false);
    };

    const handleClose = () => {
        setIsCheckRemove(false);
    };

    return (
        <Dialog
            classes={{paper: classes.paper}}
            open={true}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">{"確認"}</DialogTitle>
            <DialogContent>
                <DialogContentText>本当に削除しますか？</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleSave} color="primary">
                    はい
                </Button>
                <Button autoFocus onClick={handleClose} color="primary">
                    いいえ
                </Button>
            </DialogActions>
        </Dialog>
    );
}