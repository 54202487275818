import React, { useState } from "react";

import CssBaseline from '@material-ui/core/CssBaseline';

import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { ProfileSidebar } from './ProfileSidebar';


export const Bar = ({ title }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    return (
		<div>
            <CssBaseline />
			<Header
				title={title}
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
			/>
            <Sidebar
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
            />
        </div>
    )
}

export const ProfileBar = ({ title }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    return (
		<div>
            <CssBaseline />
			<Header
				title={title}
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
			/>
            <ProfileSidebar
                mobileOpen={mobileOpen}
                setMobileOpen={setMobileOpen}
            />
        </div>
    )
}