import React, { useEffect, useState } from "react";
import {
  PDFDownloadLink,
  PDFViewer,
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import fontRegular from "../font/Nasu-Regular-20200227.ttf"; //ttfファイル参照
import fontBold from "../font/Nasu-Bold-20200227.ttf"; //ttfファイル参照
import fontMincho from "../font/nijimi-mincho.otf";
import {
  ArrowUpward,
  ArrowDownward,
  GetAppOutlined,
  GetAppRounded,
  Palette,
} from "@material-ui/icons";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { getPdfDataFromDb } from "../modules/FirebaseModule";

export const Pdf = ({ userId }) => {
  const [pData, setPData] = useState([]);

  useEffect(() => {
    let unmounted = false;
    /*
     * firebaseのresultTestコレクションの中のidを見て自分と同じものだけをデータで取り出す
     */
    (async () => {
      try {
        if (!unmounted) {
          const data = await getPdfDataFromDb("resultTest", userId);
          const zeroFill = (val) => `0${val}`.slice(-2);
          const dayOfWeekStrJP = ["日", "月", "火", "水", "木", "金", "土"];
          // timestampから年月日を分ける
          const newData = data.map((val, i, arr) => {
            val.reportYear = val.createdAt.toDate().getFullYear();
            val.reportMonth = zeroFill(val.createdAt.toDate().getMonth() + 1);
            val.reportDate = zeroFill(val.createdAt.toDate().getDate());
            val.reportDay = dayOfWeekStrJP[val.createdAt.toDate().getDay()];
            return val;
          });
          setPData(newData);
        }
      } catch (e) {
        alert("データを読み込めませんでした。再読み込みしてください");
      }
    })();

    // const testData = [
    //   {reportYear: 2021, reportMonth: '03', reportDate: '23', reportDay: '火', company: 'SBI', institution: '海老名教育施設', belongTo: '4', name: 'アイウエオ', category: '2', training: 'ストリクト', instructor: '河合憲剛', trainingContents: 'テストアイウエオ。かきくけこ。さしすせそ。たちつてと。justify-contentは、要素の位置を水平方向に移動できるプロパティです。justify-contentの値であるflex-endは、並列になった要素を右端の終了地点から配置する処理なので右寄せができるという内容です。', report: 'marginの値は左から順に、上、右、下、左の余白を指定するので、上下と右に0を入力して左だけ自動算出(auto)を入力すると右に寄せることが可能です。また、marginを指定するブロック要素は、CSSのwidthとheightで横幅と高さを必ず設定しましょう。',},
    //   {reportYear: 2022, reportMonth: '06', reportDate: '08', reportDay: '火', company: 'エルテス', institution: '海老名教育施設', belongTo: '4', name: '12345', category: '2', training: 'ストリクト', instructor: '佐藤あかり', trainingContents: 'contentは、要素の位置を水平方向に移動できるプロパティです。justify-contentの値であるflex-endは、並列になった要素を右端の終了地点から配置する処理なので右寄せができるという内容です。', report: 'marginの値は左から順に、上、右、下、左の余白を指定するので、上下と右に0を入力して左だけ自動算出(auto)を入力すると右に寄せることが可能です。また、marginを指定するブロック要素は、CSSのwidthとheightで横幅と高さを必ず設定しましょう。',},
    // ]
    // setPData(testData);

    return () => {
      unmounted = true;
    };
  }, []);

  // ttfファイルのフォント定義
  // フォント「ナス レギュラー」
  Font.register({
    family: "Nasu-Regular",
    src: fontRegular,
  });

  // フォント「ナス 太字」
  Font.register({
    family: "Nasu-Bold",
    src: fontBold,
  });

  Font.register({
    family: "Nijimi-Mincho",
    src: fontMincho,
  });

  // CSSスタイル定義
  const myStyles = StyleSheet.create({
    body: {
      paddingTop: 40,
      paddingBottom: 40,
      paddingHorizontal: 20,
      fontFamily: "Nasu-Regular",
    },

    tableRow: {
      flexDirection: "row",
    },
    table: {
      width: "auto",
      margin: "16px",
      marginTop: "8px",
    },

    signatureStampTable: {
      marginTop: "0px",
      marginRight: "16px",
      marginLeft: "auto",
    },
    signatureStampHeaderLeft: {
      width: "54px",
      // height: "16px",
      height: "30px",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1, // ★ヘッダーの上の線を表示する
    },
    signatureStampHeader: {
      width: "54px",
      // height: "16px",
      height: "30px",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1, // ★ヘッダーの上の線を表示する
    },
    signatureStampCellLeft: {
      width: "54px",
      height: "40px",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 0, // ★ヘッダーの上の線を表示する
    },
    signatureStampCell: {
      width: "54px",
      height: "40px",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0, // ★ヘッダーの上の線を表示する
    },

    // main table
    // 罫線｜報告日〜施設名余白等
    tableHeaderTopLeft: {
      width: "25%",
      height: "32px",
      display: "table",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#000",
      borderLeftColor: "#000",
      borderRightColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1, // ★ヘッダーの上の線を表示する
      borderBottomWidth: 0,
    },
    // 罫線｜全体レイアウト左右入れ替え
    tableHeaderLeft: {
      width: "25%",
      height: "32px",
      display: "table",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#ccc",
      borderLeftColor: "#000",
      borderRightColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1, // ★ヘッダーの上の線を表示する
      borderBottomWidth: 0,
    },
    // 罫線｜全体行間およびヘッダー
    tableHeaderParagraphLeft: {
      width: "25%",
      height: "120px",
      display: "table",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#ccc",
      borderLeftColor: "#000",
      borderRightColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1, // ★ヘッダーの上の線を表示する
      borderBottomWidth: 0,
    },
    // 罫線｜全体行間およびヘッダー
    tableHeaderBottomLeft: {
      width: "25%",
      height: "120px",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#ccc",
      borderLeftColor: "#000",
      borderRightColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 1,
      borderTopWidth: 1, // ★ヘッダーの上の線を表示する
    },

    // 罫線｜報告日｜ボーダーボトムを0
    tableDataTop: {
      width: "75%",
      height: "32px",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#000",
      borderRightColor: "#000",
      borderLeftColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      borderBottomWidth: 0,
    },
    // 罫線｜施設名〜受講日時
    tableData: {
      width: "75%",
      height: "32px",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#ccc",
      borderRightColor: "#000",
      borderLeftColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      borderBottomWidth: 0,
    },
    // 罫線｜研修内容
    tableParagraphData: {
      width: "75%",
      height: "120px",
      wordBreak: "break-all",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#ccc",
      borderRightColor: "#000",
      borderLeftColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      borderBottomWidth: 0,
    },
    // 罫線｜報告と感想
    tableDataBottom: {
      width: "75%",
      height: "120px",
      wordBreak: "break-all",
      borderStyle: "solid",
      borderColor: "rgb(0,0,0)",
      borderTopColor: "#ccc",
      borderRightColor: "#000",
      borderLeftColor: "#ccc",
      borderBottomColor: "#000",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 1,
      borderBottomWidth: 1,
    },

    title: {
      marginBottom: "64px",
      textAlign: "center",
      fontSize: "20pt",
      fontFamily: "Nijimi-Mincho",
      fontWeight: "bold",
    },

    nameCol: {
      width: "30%",
      borderStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: 0.5, // ★ヘッダーの上の線を表示する
    },
    nameColEnd: {
      width: "5%",
      borderStyle: "solid",
      borderBottomColor: "#000",
      borderBottomWidth: 0.5, // ★ヘッダーの上の線を表示する
    },
    inputName: {
      textAlign: "right",
      marginRight: "8px",
      fontSize: "14pt",
      fontFamily: "Nijimi-Mincho",
    },
    name: { fontSize: "14pt", fontFamily: "Nijimi-Mincho" },

    text: { marginLeft: "24px", fontSize: "10pt", fontFamily: "Nijimi-Mincho" },
    headerText: {
      marginTop: "8px",
      width: "100%",
      height: "100%",
      textAlign: "center",
      fontSize: "12pt",
      fontFamily: "Nijimi-Mincho",
    },
    headerParagraphText: {
      marginTop: "48px",
      width: "100%",
      height: "100%",
      textAlign: "center",
      fontSize: "12pt",
      fontFamily: "Nijimi-Mincho",
    },
    signatureStampText: {
      marginLeft: "-2px",
      padding: "8px",
      fontSize: "10pt",
      fontFamily: "Nijimi-Mincho",
    },
    signatureStamp: {
      marginTop: "6px",
      width: "8px",
      marginLeft: "12px",
      padding: "8px",
      fontSize: "10pt",
      fontFamily: "Nijimi-Mincho",
    },
    dataText: { padding: "9px", fontSize: "10pt", fontFamily: "Nijimi-Mincho" },
    dataParagraphText: {
      width: "100%",
      padding: "8px",
      lineHeight: 1.5,
      fontSize: "10pt",
      fontFamily: "Nijimi-Mincho",
    },
    pageNumber: { fontSize: "8px", marginRight: "16px", marginLeft: "auto" },
  });

  const MyDoc = () => {
    return (
      <Document>
        {/* {exportData.current && exportData.current?.map((data) => { */}
        {pData.length > 0 &&
          pData?.map((data, i) => {
            return (
              <Page
                size="A4"
                orientation="portrait"
                style={myStyles.body}
                key={i}
                wrap
              >
                <Text style={myStyles.title} fixed>
                  研修受講報告書
                </Text>
                <View style={{ marginLeft: "16px" }}>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.nameCol}>
                      <Text style={myStyles.inputName}>
                        {data.company ? data.company : ""}
                      </Text>
                    </View>
                    <View style={myStyles.nameColEnd}>
                      <Text style={myStyles.name}>殿</Text>
                    </View>
                  </View>
                </View>
                {/* ★印鑑部分 */}
                <View style={myStyles.signatureStampTable}>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.signatureStampHeaderLeft}>
                      <Text style={myStyles.signatureStampText}>
                        {"受講者印"}
                      </Text>
                    </View>
                    <View style={myStyles.signatureStampHeader}>
                      <Text style={myStyles.signatureStampText}>
                        {"確認者印"}
                      </Text>
                    </View>
                    <View style={myStyles.signatureStampHeader}>
                      <Text style={myStyles.signatureStampText}>
                        {"管理者印"}
                      </Text>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.signatureStampCellLeft}>
                      <Text style={myStyles.signatureStamp}>{"印"}</Text>
                    </View>
                    <View style={myStyles.signatureStampCell}>
                      <Text style={myStyles.signatureStamp}>{"印"}</Text>
                    </View>
                    <View style={myStyles.signatureStampCell}>
                      {/* <View style={{ , }}> */}
                      <Text style={myStyles.signatureStamp}>{"印"}</Text>
                      {/* </View> */}
                    </View>
                  </View>
                </View>
                {/* ★印鑑部分 */}

                <Text style={myStyles.text} fixed>
                  以下のとおり、報告いたします。
                </Text>

                {/* ★メイン部分 */}
                <View style={myStyles.table}>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderTopLeft}>
                      <Text style={myStyles.headerText}>報告日</Text>
                    </View>
                    <View style={myStyles.tableDataTop}>
                      <View style={{}}>
                        <View style={myStyles.tableRow} fixed>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportYear ? data.reportYear : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>年</Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "8px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportMonth ? data.reportMonth : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>月</Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "8px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportDate ? data.reportDate : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>日</Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "8px" }}>
                            <Text style={myStyles.dataText}>(</Text>
                          </View>
                          <View style={{ width: "8px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportDay ? data.reportDay : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginLeft: "16px" }}>
                            <Text style={myStyles.dataText}>)</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderLeft}>
                      <Text style={myStyles.headerText}>施設名</Text>
                    </View>
                    <View style={myStyles.tableData}>
                      <Text style={myStyles.dataText}>
                        {data.institution ? data.institution : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderLeft}>
                      <Text style={myStyles.headerText}>所属</Text>
                    </View>
                    <View style={myStyles.tableData}>
                      <Text style={myStyles.dataText}>
                        {data.belongTo ? data.belongTo : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderLeft}>
                      <Text style={myStyles.headerText}>氏名</Text>
                    </View>
                    <View style={myStyles.tableData}>
                      <Text style={myStyles.dataText}>
                        {data.name ? data.name : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderLeft}>
                      <Text style={myStyles.headerText}>カテゴリ名</Text>
                    </View>
                    <View style={myStyles.tableData}>
                      <Text style={myStyles.dataText}>
                        {data.category ? data.category : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderLeft}>
                      <Text style={myStyles.headerText}>研修名</Text>
                    </View>
                    <View style={myStyles.tableData}>
                      <Text style={myStyles.dataText}>
                        {data.training ? data.training : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderLeft}>
                      <Text style={myStyles.headerText}>講師名</Text>
                    </View>
                    <View style={myStyles.tableData}>
                      <Text style={myStyles.dataText}>
                        {data.instructor ? data.instructor : ""}
                      </Text>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderLeft}>
                      <Text style={myStyles.headerText}>受講日時</Text>
                    </View>
                    <View style={myStyles.tableData}>
                      <View style={{}}>
                        <View style={myStyles.tableRow} fixed>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportYear ? data.reportYear : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>年</Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "8px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportMonth ? data.reportMonth : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>月</Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "8px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportDate ? data.reportDate : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "16px" }}>
                            <Text style={myStyles.dataText}>日</Text>
                          </View>
                          <View style={{ width: "8px", marginRight: "8px" }}>
                            <Text style={myStyles.dataText}>(</Text>
                          </View>
                          <View style={{ width: "8px" }}>
                            <Text style={myStyles.dataText}>
                              {data.reportDay ? data.reportDay : ""}
                            </Text>
                          </View>
                          <View style={{ width: "8px", marginLeft: "16px" }}>
                            <Text style={myStyles.dataText}>)</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderParagraphLeft}>
                      <Text style={myStyles.headerParagraphText}>研修内容</Text>
                    </View>
                    <View style={myStyles.tableParagraphData}>
                      <Text style={myStyles.dataParagraphText}>
                        {data.trainingContents ? data.trainingContents : ""}
                      </Text>
                    </View>
                  </View>
                  {/* main bottom */}
                  <View style={myStyles.tableRow} fixed>
                    <View style={myStyles.tableHeaderBottomLeft}>
                      <Text style={myStyles.headerParagraphText}>
                        報告と感想
                      </Text>
                    </View>
                    <View style={myStyles.tableDataBottom}>
                      <Text style={myStyles.dataParagraphText}>
                        {data.report ? data.report : ""}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* ★メイン部分 */}

                {/* ★ページ数を表示 */}
                <Text
                  style={myStyles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </Page>
            );
          })}
      </Document>
    );
  };
  return (
    <div>
      {pData.length > 0 ? (
        <PDFDownloadLink document={<MyDoc />} fileName="研修受講報告書.pdf">
          {({ loading }) =>
            loading ? (
              "..."
            ) : (
              <GetAppRounded
                variant="outlined"
                size="small"
                style={{
                  color: "#f06292",
                }}
                sx={{ marginBottom: 1 }}
              >
                PDF出力
              </GetAppRounded>
            )
          }
        </PDFDownloadLink>
      ) : (
        ""
      )}
    </div>
  );

  // 20240304 taniguchi コメントアウト | "Functions are not valid as a React child"エラーの解消のため→PDFDownloadLinkコンポーネント内にGetAppRoundedコンポーネントは置くことができない
  // return (
  //   <div>
  //     {/* クリックするとPDFをダウンロード */}
  //     {pData.length > 0 ? (
  //       // <PDFDownloadLink document={<MyDoc />} >
  //       <PDFDownloadLink document={<MyDoc />} fileName="研修受講報告書.pdf">
  //         {({ loading }) => (loading ? "Loading document..." : "PDF出力")}

  //         <GetAppRounded
  //           // <PictureAsPdfIcon document={<MyDoc />}
  //           variant="outlined"
  //           size="small"
  //           style={{
  //             color: "#f06292",
  //           }}
  //           // startIcon={<PictureAsPdfIcon />}
  //           sx={{ marginBottom: 1 }}
  //         >
  //           PDF出力
  //           {/* </PictureAsPdfIcon> */}
  //         </GetAppRounded>
  //       </PDFDownloadLink>
  //     ) : (
  //       ""
  //     )}

  //     {/* PDFをiframe表示 */}
  //     {/* <PDFViewer>
  //       <MyDoc />
  //     </PDFViewer> */}
  //   </div>
  // );
};
