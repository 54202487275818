// "react-hook-form": "^6.14.2",
import React, { useState, useEffect } from "react";
// import { Link, useHistory } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  makeStyles,
  Typography,
  Avatar,
  InputAdornment,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import { Loader } from "../../component/Loader";

import { useForm } from "react-hook-form";

import { login } from "../../modules/FirebaseModule";

import { useApp } from "../../context";
import { Height } from "@material-ui/icons";
import AccountCircle from "@material-ui/icons/AccountCircle";

// スタイルの記述をする
const useStyles = makeStyles((theme) => ({
  outer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    width: 400,
    margin: `${theme.spacing(0)} auto`,
    // 2021/11/19 h.suzuki add ログイン画面ロゴ挿入対応
    marginBottom: "-40px",
    border: "box-shadow .15s ease-in-out",
  },
  card: {
    // minWidth: "540px",
    // minHeight: "540px",
    minWidth: "95%",
    minHeight: "auto",
    marginTop: theme.spacing(10),
    // 2022/01/13 h.suzuki add ロゴ配置変更対応
    // marginTop: theme.spacing(7),
  },
  header: {
    // 2021/11/19 h.suzuki add ログインバー配色対応
    textAlign: "center",
    // backgroundColor: theme.palette.background.default,
    // backgroundColor: "#ff9a94",
    backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
    // backgroundImage: "linear-gradient(to bottom, #ffc3a0 0%, #ff9a94 100%)",
    // backgroundImage: "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
    color: "#333",
    // color: "#ffffff",
  },

  // textField: {
  //     border: "1px solid #ced4da",
  //     borderRadius: ".25rem",
  //     transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  // },
  signupbtn: {
    // 反映されていない可能性大
    // backgroundImage: "linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%)",
    // backgroundImage: "linear-gradient(120deg, #fccb90 0%, #e88bc8 100%)",
    // 2021/11/12　追加修正　h.suzuki
    // color: "#00000099",
    // color: "#000099",
  },
  signupTbtn: {
    // 反映されていない可能性大
    // backgroundImage: "linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)",
    // color: "#00000099",
    // color: "#000099",
  },
}));
// **** useStyles ****

// **** component ****
export const Login = () => {
  const { stateAuth, dispatchAuth } = useApp();
  const classes = useStyles();
  // let history = useHistory();
  const navigate = useNavigate();
  // const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  // パスワード表示制御ようのstate
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  // バリデーション
  const { register, handleSubmit, errors, formState } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur", // エラーになった後、空文字が入るのを防ぐ
  });
  const onSubmit = (data) => {
    // console.log(data);
    console.log("login submit");
    // console.log(watch('email'));
  };

  const inputMail = (e) => {
    setMail(e.target.value);
  };

  const inputPassword = (e) => {
    setPassword(e.target.value);
  };

  const pushLoginBtn = async () => {
    setIsLoader(true);
    const user = await login(mail, password);
    // console.log('user: ', user);
    if (user !== null && dispatchAuth) {
      if (user !== undefined) {
        // history.replace('/');
        dispatchAuth({ type: "HANDLE_LOGIN", payload: true });
      }
    }
    setIsLoader(false);
  };

  useEffect(() => {
    // ログインした状態でここにアクセスしたらホームへ飛ぶ
    // if (stateAuth.currentUser) history.replace("/");
    if (stateAuth.currentUser) navigate("/");
    return () => {};
  }, [stateAuth.currentUser, navigate]);

  return (
    <div className={classes.outer}>
      {isLoader === true ? <Loader /> : ""}

      <div
        style={{
          textAlign: "center",
          marginTop: "15px",
          marginBottom: "-65px",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/kaigo_college110.png`}
          style={{
            width: "65%",
            height: "auto",
          }}
        />
      </div>

      <Card className={classes.card}>
        <CardHeader className={classes.header} title="介護カレッジ">
          avatar={<Avatar>P</Avatar>}, title="Profile",
          {/* subheader="プロフィール", */}
          alignItems: "center", justifyContent: "center",
          {/* < img src={`${process.env.PUBLIC_URL}/kaigo_college110.png`
                    }
                        style={{
                            width: "65%",
                            height: "auto",
                        }} /> */}
        </CardHeader>
        {/* <img src={`${process.env.PUBLIC_URL}/kaigo_college_menu_bar.png`} style{{ ''}} /> */}

        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <p>メールアドレス</p> */}
            {/* <TextField
                            id="input-with-icon-textfield"
                            label="メールアドレス"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                        /> */}
            <TextField
              required
              error
              id="outlined-error"
              label="Error"
              id="outlined-required-mail"
              label="メールアドレス"
              fullWidth
              margin="normal"
              type="text"
              value={mail}
              onChange={inputMail}
              name="mail"
              error={!!errors.mail}
              helperText={errors?.mail?.message}
              inputRef={register({
                required: "*必須項目",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "メールアドレスが正しくありません",
                },
              })}
            />
            {/* {errors.mail && <p>{errors.mail.message}</p>} */}
            {/* <p>パスワード</p> */}
            <TextField
              required
              error
              id="outlined-error"
              label="Error"
              id="outlined-required-password"
              label="パスワード"
              fullWidth
              margin="normal"
              type={isRevealPassword ? "text" : "password"}
              value={password}
              onChange={inputPassword}
              name="password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              inputRef={register({
                required: "*必須項目",
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上入力して下さい",
                },
              })}
            />
            <div
              onClick={(e) => setIsRevealPassword(!isRevealPassword)}
              style={{
                color: "#087cc9",
                fontSize: "0.85em",
                fontweight: "bold",
              }}
            >
              <p>パスワードを表示する</p>
            </div>
            {/* <TextField
                            fullWidth
                            margin="normal"
                            type="password"
                            value={password}
                            onChange={inputPassword}
                            name="password"
                            error={!!errors.password}
                            helperText={errors?.password?.message}
                            inputRef={register({
                                required: "*必須 パスワードを入力してください",
                                minLength: {
                                    // 半角英小文字大文字数字をそれぞれ1種類以上含む8文字以上20文字以下の正規表現
                                    value: /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,20}$/,
                                    message: "半角英小文字大文字数字をそれぞれ1種類以上含む8文字以上20文字以下で入力して下さい"
                                }
                            })}
                        /> */}
            {/* {errors.password && <p>{errors.password.message}</p>} */}
            {/* 2021/11/12　h.suzuki 🅿︎タグ追加によりコメントアウト */}
            {/* <br /><br /> */}
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={pushLoginBtn}
                type="submit"
                disabled={!formState.isValid}
                // disabled={!formState.dirty || formState.isSubmitting}
              >
                ログインする
              </Button>
            </div>
            <div
              // onClick={(e) => history.push("/reset_password")}
              onClick={(e) => navigate("/reset_password")}
              // style={{color: "blue", fontSize: "0.9em", marginTop: "32px",}}
              style={{
                color: "#087cc9",
                fontSize: "0.85em",
                fontweight: "bold",
                marginTop: "16px",
              }}
            >
              パスワードを忘れた方はこちら
            </div>

            {/* <div>
              <p>はじめて登録する方はこちら</p>
            </div>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                component={Link}
                to="/signup"
                style={
                  {
                    // backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
                    // border: "solid 0.5px #ff9a94",
                  }
                }
              >
                新規登録する
              </Button>
            </div> */}
          </form>
        </CardContent>
      </Card>
      <Typography
        variant="body2"
        color="secondary"
        align="center"
        style={{ marginTop: "12px" }}
        // 20240304 pタグの入れ子を避けるためにdivを指定
        component="div"
      >
        <p
          style={{
            color: "#000",
          }}
        >
          お問い合わせはこちら&#8200;&#8200;
          <a
            target="_blank"
            style={{
              color: "#087cc9",
              fontSize: "1em",
              fontweight: "bold",
              marginTop: "16px",
            }}
            color="inherit"
            href="https://www.rehab-connect.com/"
          >
            株式会社アール・シー
          </a>
        </p>
      </Typography>
      {/* <Typography variant="body2" color="text.secondary" align="center" style={{ marginTop: "-6px" }}>
                <p>{'Copyright © '}
                    <a target="_blank" style={{
                        color: "#087cc9", fontSize: "1em", fontweight: "bold",
                    }} color="inherit" href="https://rudolf.co.jp/">
                        RuDoLf Inc.
                </a>{' All Rights Reserved.'}</p>
            </Typography> */}
    </div>
  );
};
// **** component ****
