import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import HttpsRedirect from "./component/HttpsRedirect";

import { Provider } from "./context";

// 20231225 taniguchi add
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./component/styles/Theme";

ReactDOM.render(
  // 20231225 taniguchi add
  <ThemeProvider theme={theme}>
    <HttpsRedirect>
      <Provider>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </Provider>
    </HttpsRedirect>
  </ThemeProvider>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('../firebase-messaging-sw.js')
//         .then((registration) => {
//             console.log('Registration successful, scope is:', registration.scope);
//             //通知の許可をユーザに確認
//             Notification.requestPermission()
//                 .then(permission => {
//                     if (permission === 'granted') {
//                         //通知が許可されている場合
//                         console.log('notif permission granted')
//                         navigator.serviceWorker.ready.then(p => {
//                             p.pushManager.getSubscription().then(subscription => {
//                                 if (subscription === null) {
//                                     //通知の購読が存在しない場合は登録する。
//                                     let re = p.pushManager.subscribe({
//                                         userVisibleOnly: true
//                                     })
//                                 }
//                             })
//                         });
//                     } else {
//                         //通知が許可されなかった場合
//                         console.log(permission);
//                     }
//                 });
//         }).catch((err) => {
//             console.log('Service worker registration failed, error:', err);
//         });
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
