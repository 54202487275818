import React from "react";
import "./App.css";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
//20231214 taniguchi add React Router v6:
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./component/styles/Theme";
// import { theme } from './component/styles/Theme3'

import { HomeWithSocket } from "./container/HomeWithSocket";
import { SignUp } from "./container/auth/SignUp";
import { Login } from "./container/auth/Login";
import { ResetPassword } from "./container/auth/ResetPassword";
import { Profile } from "./container/Profile";
import { UserEdit } from "./container/UserEdit";
import { AccessHistory } from "./container/AccessHistory";
import { VideoWindow } from "./container/VideoWindow";
import { Notification } from "./component/Notification";
import { Menu2WithSocket } from "./container/Menu2WithSocket";

// import { HomeWithSocket } from './container/HomeWithList'

function App() {
  return (
    <Router>
      {/* <ThemeProvider theme={theme}> */}
      {/* <Switch> */}
      <Routes>
        {/* <Route exact path="/" component={HomeWithSocket} />
          <Route exact path="/login" component={Login} />
          <Route path="/login" element={<Login />} />

          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/reset_password" component={ResetPassword} />

          <Route exact path="/profile" component={Profile} />
          <Route exact path="/user/:id" component={UserEdit} />
          {/* 2021/11/24 h.suzuki add 講座管理用を作成予定　HomeWithSocket　→　HomeWithList */}
        {/* <Route exact path="/" component={HomeWithList} /> */}
        <Route exact path="/accesshistory" component={AccessHistory} />
        <Route exact path="/chat" component={VideoWindow} />
        <Route exact path="/menu2" component={Menu2WithSocket} />
        {/* <Route path="/grouproom/:roomId/:uid" component={RoomWithSkyWay} /> */}
        {/* URLヒットしないときはNot Found画面を表示する */}
        <Route component={NotFound} /> */}
        {/* 20231214 taniguchi add React Router v6: */}
        <Route path="/" element={<HomeWithSocket />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/user/:id" element={<UserEdit />} />
        <Route path="/accesshistory" element={<AccessHistory />} />
        <Route path="/chat" element={<VideoWindow />} />
        <Route path="/menu2" element={<Menu2WithSocket />} />
        <Route path="*" element={<NotFound />} />
        {/* </Switch> */}
      </Routes>
      {/* </ThemeProvider> */}
      {/* Notification componentを埋め込む */}
      {/* <Notification /> */}
    </Router>
  );
}

export default App;

// 不明なRouteは全てNotFound
const NotFound = () => {
  return <h2>ページが見つかりません</h2>;
};
