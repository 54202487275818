
export const addToLocal = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
}

export const getFromLocal = (key) => {
    return JSON.parse(window.localStorage.getItem(key));
}

export const removeFromLocal = (key) => {
    window.localStorage.removeItem(key);
}
// 全データ削除
export const removeAllFromLocal = () => {
    window.localStorage.clear();
}

export const addToSesseion = (key, value) => {
    window.sesseionStorage.setItem(key, JSON.stringify(value));
}

export const getFromSesseion = (key) => {
    return JSON.parse(window.sesseionStorage.getItem(key));
}

export const removeFromSesseion = (key) => {
    window.sesseionStorage.removeItem(key);
}
// 全データ削除
export const removeAllFromSesseion = () => {
    window.sesseionStorage.clear();
}