import { useApp } from "../context";

import React, { useEffect, useState, useRef } from "react";
// import { useHistory } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { makeStyles, TextField, Button, Select } from "@material-ui/core";
import { useForm } from "react-hook-form";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";

import { ProfileBar } from "./bar/Bar";
import { Loader } from "../component/Loader";
import { ToLogin } from "../component/ToLogin";
import { CheckDialog } from "../component/CheckDialog";

import { addToLocal } from "../modules/WebStorage";
import { updateDocument, uploadImage } from "../modules/FirebaseModule";

/*
 * スタイルの記述をする
 */
const useStyles = makeStyles((theme) => ({
  root: theme.mixins.root,
  body: theme.mixins.body,
  titleContainer: {
    display: "block",
    margin: "10px",
  },
  title: {
    display: "initial",
    color: "Gray",
    fontSize: "1.6em",
    fontWeight: "bold",
    padding: "10px",
  },
  cardRoot: {
    // margin: "32px",
    width: "100%",
    overflow: "scroll",
    // display: "flex",
    position: "center",
  },
  admin: {
    display: "flex",
  },
  avatorContainer: {
    float: "right",
    paddingRight: "5%",
  },
  avator: {
    float: "right",
    minWidth: "300px",
    minHeight: "300px",
    maxWidth: "300px",
    maxHeight: "300px",
  },
  inputContainer: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px",
    },
    // minWidth: "350px",
    maxWidth: "500px",
    // textAlign: "right",
  },
  inputSet: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "300px",
    },
    width: "400px",
    height: "45px",
    display: "flex",
    position: "relative",
    // marginLeft: "auto",
    // textAlign: "right",
  },
  typo: {
    [theme.breakpoints.down("sm")]: {
      width: "120px",
    },
    width: "160px",
    textAlign: "right",
  },
  textField: {
    [theme.breakpoints.down("sm")]: {
      width: "160px",
    },
    width: "200px",
    height: "45px",
    // marginRight: "auto",
    // marginLeft: "auto",
    position: "absolute",
    right: 0,
    // textAlign: "right",
  },
  cardBtn: {
    width: "150px",
    height: "auto",
    // margin: "16 16 40 auto",

    // marginLeft: "64px",
    // marginTop: "20px",
    marginBottom: "36px",
    // position: "center",
    // right: "-80%",
  },
}));
// **** useStyles ****

/*
 * **** component ****
 */
export const Profile = () => {
  const { stateAuth, dispatchAuth } = useApp();
  const classes = useStyles();

  // const history = useHistory();
  const navigate = useNavigate(); // useNavigate フックを使ってナビゲーションを行う

  const [isLoader, setIsLoader] = useState(false);
  const [isOpenCheck, setIsOpenCheck] = useState(false);

  const userAvator = useRef();
  const [avatorImg, setAvatorImg] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.avator : ""
  ); //
  const [backgroundImg, setBackgroundImg] = useState(
    stateAuth.currentUser && stateAuth.currentUser.backgroundImage
      ? stateAuth.currentUser.backgroundImage
      : `${process.env.PUBLIC_URL}/back_image.png`
  ); //
  // 2022/01/17 h.suzuki add Userプロフィール情報｜DBデータ取得表示対応
  const [name, setName] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.name : ""
  );
  const [nameKana, setNameKana] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.nameKana : ""
  );
  const [mail, setMail] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.name : ""
  );
  const [zip, setZip] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.name : ""
  );
  // 20240305 taniguchi コメントアウト | PREF_OPTIONS以下に修正したコードを記述
  // const [prefectures, setPrefectures] = useState(
  //   stateAuth.currentUser ? stateAuth.currentUser.prefectures : ""
  // );
  const [address1, setAddress1] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.address1 : ""
  );
  const [address2, setAddress2] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.address2 : ""
  );
  const [building, setBuilding] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.building : ""
  );
  const [tel, setTel] = useState(
    stateAuth.currentUser ? stateAuth.currentUser.tel : ""
  );

  const PREF_OPTIONS = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];

  // 20240305 taniguchi add | "out-of-range"エラー対応のためprefecturesで未選択の場合にはPREF_OPTIONSの最初の値を設定する
  const [prefectures, setPrefectures] = useState(
    stateAuth.currentUser &&
      PREF_OPTIONS.includes(stateAuth.currentUser.prefectures)
      ? stateAuth.currentUser.prefectures
      : PREF_OPTIONS[0] // デフォルト値を設定
  );

  // **** バリデーション ****
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    criteriaMode: "all",
  });
  const onSubmit = (data) => {
    console.log(data);
    // updateInfo();
    setIsOpenCheck(true);
  };

  useEffect(() => {
    // let unmounted = false;

    if (stateAuth.currentUser !== null) {
      setAvatorImg(stateAuth.currentUser.avator);
      setBackgroundImg(stateAuth.currentUser.backgroundImage);
      setName(stateAuth.currentUser.name);
      setNameKana(stateAuth.currentUser.nameKana);
      setMail(stateAuth.currentUser.mail);
      setZip(stateAuth.currentUser.zip);
      setAddress1(stateAuth.currentUser.address1);
      setAddress2(stateAuth.currentUser.address2);
      setBuilding(stateAuth.currentUser.building);
      setTel(stateAuth.currentUser.tel);
    }
    // console.log('stateAuth.id : ', stateAuth.id);

    return () => {
      // unmounted = true;
    };
  }, [stateAuth.currentUser, stateAuth.id]);

  /*
   * method
   */
  const setText = (e, setFunc) => {
    setFunc(e.target.value);
  };

  const updateInfo = async () => {
    setIsLoader(true);
    try {
      const options = {
        avator: avatorImg,
        backgroundImage: backgroundImg,
        name,
        nameKana,
        mail,
        zip,
        address1,
        address2,
        building,
        tel,
      };
      console.log("options: ", options);
      // dbへ
      await updateDocument("owners", stateAuth.id, options);
      const updateUserInfo = {
        ...stateAuth.currentUser,
        avator: avatorImg,
        backgroundImage: backgroundImg,
        name,
        nameKana,
        mail,
        zip,
        address1,
        address2,
        building,
        tel,
      };
      dispatchAuth({ type: "SET_CURRENT_USER", payload: updateUserInfo });
      console.log("updateUserInfo: ", updateUserInfo);
      // ローカルストレージにセット
      addToLocal("currentUser", updateUserInfo);

      setIsLoader(false);

      alert("更新が完了しました");
      navigate("/"); // ナビゲーション先のパスを指定
      // history.replace('/');
      // history.goBack();
    } catch (e) {
      setIsLoader(false);

      alert(e);
    }
  };

  const setAvator = (e) => {
    console.log("set avator");
    const image = e.target.files[0];
    userAvator.current.title = image.name;
    const reader = new FileReader();
    reader.onload = (event) => {
      userAvator.current.setAttribute("src", event.target.result);
    };
    reader.readAsDataURL(image);
    // storageに保存
    uploadImage("avator", stateAuth.id, image, (url) => {
      console.log("firebaseUrl: ", url);
      setAvatorImg(url);
    });
  };

  /*
   * render
   */
  return (
    <div className={classes.root}>
      {isLoader === true ? <Loader /> : ""}
      {stateAuth.currentUser ? <ProfileBar title={""} /> : ""}
      {/* ログアウトした時 */}
      <ToLogin currentUser={stateAuth.currentUser} />

      {stateAuth.currentUser ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <main className={classes.body}>
            <div className={classes.titleContainer}>
              <div className={classes.title}>プロフィール編集</div>
            </div>
            <Card className={classes.cardRoot}>
              <CardContent>
                <div className={classes.avatorContainer}>
                  <img
                    ref={userAvator}
                    src={
                      avatorImg !== ""
                        ? avatorImg
                        : `${process.env.PUBLIC_URL}/avator_image.png`
                    }
                    className={classes.avator}
                  />
                  <div className={classes.changeAvatorImage}>
                    <label
                      style={{ textAlign: "right" }}
                      htmlFor="avatorFilePicker"
                    >
                      画像を変更する
                    </label>
                    <input
                      id="avatorFilePicker"
                      type="file"
                      style={{ display: "none" }}
                      onChange={setAvator}
                    />
                  </div>
                </div>
                <div className={classes.inputContainer}>
                  {/*
                   * アカウント名
                   */}
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      アカウント :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={name}
                      onChange={(e) => setText(e, setName)}
                      name="name"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      inputRef={register({
                        required: "*必須項目",
                        maxLength: 40,
                      })}
                    />
                  </span>
                  {/* {errors.staffName1 && <p>{errors.staffName1.message}</p>} */}
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      フリガナ :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={nameKana}
                      onChange={(e) => setText(e, setNameKana)}
                      name="nameKana"
                      error={!!errors.nameKana}
                      helperText={errors?.nameKana?.message}
                      inputRef={register({
                        required: "*必須項目",
                        maxLength: 40,
                      })}
                    />
                  </span>
                  {/* {errors.staffNameKana1 && <p>{errors.staffNameKana1.message}</p>} */}
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      メール :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={mail}
                      onChange={(e) => setText(e, setMail)}
                      placeholder={`09012345678`}
                      name="mail"
                      error={!!errors.setMail}
                      helperText={errors?.mail?.message}
                      inputRef={register({
                        required: "*必須項目",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "メールアドレスが正しくありません",
                        },
                      })}
                    />
                  </span>
                  {/* {errors.staffTel1 && <p>{errors.staffTel1.message}</p>} */}
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      郵便番号 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={zip}
                      onChange={(e) => setText(e, setZip)}
                      name="zip"
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      都道府県 :
                    </Typography>
                    <Select
                      style={{
                        marginTop: "-10px",
                        marginLeft: "40px",
                      }}
                      native
                      type="select"
                      value={prefectures}
                      onChange={(e) => setText(e, setPrefectures)}
                      placeholder={`東京都`}
                      name="prefectures"
                      inputRef={register({
                        required: "*必須項目",
                        maxLength: 5,
                      })}
                    >
                      {PREF_OPTIONS.map((option) => {
                        return <option value={option}>{option}</option>;
                      })}
                    </Select>
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      市区町村 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={address1}
                      onChange={(e) => setText(e, setAddress1)}
                      placeholder={`台東区`}
                      name="address1"
                      inputRef={register({
                        required: "*必須項目",
                        maxLength: 40,
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      番地 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={address2}
                      onChange={(e) => setText(e, setAddress2)}
                      placeholder={`〇-〇-〇`}
                      name="address2"
                      inputRef={register({
                        required: "*必須項目",
                        maxLength: 40,
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      建物名 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={building}
                      onChange={(e) => setText(e, setBuilding)}
                      placeholder={`建物名・部屋番号`}
                      name="building"
                      inputRef={register({
                        maxLength: 40,
                      })}
                    />
                  </span>
                  <span className={classes.inputSet}>
                    <Typography
                      classes={{ body1: classes.typo }}
                      variant="body1"
                    >
                      電話番号 :
                    </Typography>
                    <TextField
                      classes={{ root: classes.textField }}
                      type="text"
                      value={tel}
                      onChange={(e) => setText(e, setTel)}
                      placeholder={`09012345678`}
                      name="tel"
                      inputRef={register({
                        required: "*必須項目",
                        pattern: {
                          value: /^[0-9]+$/,
                          message: "半角数字のみで入力して下さい",
                        },
                        minLength: {
                          value: 10,
                          message: "電話番号を入力してください",
                        },
                      })}
                    />
                  </span>
                </div>
                {/* inputContainer */}
              </CardContent>
              <CardActions>
                <Button
                  className={classes.cardBtn}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  更新する
                </Button>
              </CardActions>
            </Card>
          </main>{" "}
          {/* body */}
          <CheckDialog
            isOpen={isOpenCheck}
            setIsOpen={setIsOpenCheck}
            title={"事業所情報を更新しますか？"}
            func={updateInfo}
          />
        </form>
      ) : (
        ""
      )}
    </div>
  );
};
