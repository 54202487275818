import React from 'react';
import { Link } from "react-router-dom"
import { makeStyles } from '@material-ui/core';

import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ChatIcon from '@material-ui/icons/Chat';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { getUserFromDb } from '../modules/FirebaseModule';


/*
 * スタイルの記述をする
 */
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: "0 auto",
        padding: "0px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",

        marginTop: "70px",
        marginBottom: "-70px",
    },
    head: {
        // backgroundColor: theme.palette.common.black,
        // backgroundColor: theme.palette.secondary.main,
        // 2021/11/23 h.suzuki add 操作履歴情報　配色対応
        backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
        color: theme.palette.common.white,
    },
    table: {
        minWidth: 500,
    },
    userStatus: {
        borderRadius: 4,
        border: '1px solid #ced4da',
        padding: '4px 16px 4px 8px',
        width: '140px',
    },
}));
// **** useStyles ****


/*
 * **** component ****
 */
export const HistoryTable = ({ tableData, userForMemoRef, setIsOpenMemo, userId }) => {
    console.log('userId : ', userId);
    const classes = useStyles();
    /*
     * method
     */
    const createTableHeader = (labels) => {
        const header = labels.map((label, index) => <TableCell align="center" key={`${label}_${index}`}>{label}</TableCell>);
        return <TableRow>{header}</TableRow>;
    }

    const createTableBody = () => {
        console.log('tableData: ', tableData);
        let body = [];
        if (tableData.length > 0) {
            body = tableData.map((user, index) => {
                let status = '';
                let checkDatetime = '';
                let ownerName = 'オーナー無し';
                if (user.status) status = user.status;
                if (user?.checkDatetime) {
                    const date = new Date(user.checkDatetime);
                    let month = (date.getMonth() + 1).toString();
                    if (month.length < 2) { month = "0" + month; }
                    let day = (date.getDate()).toString();
                    if (day.length < 2) { day = "0" + day; }
                    let hours = date.getHours().toString();
                    if (hours.length < 2) hours = "0" + hours;
                    let minutes = date.getMinutes().toString();
                    if (minutes.length < 2) minutes = "0" + minutes;
                    checkDatetime = date.getFullYear() + "." + month + "." + day + " " + hours + ":" + minutes;
                }

                if (userId === process.env.REACT_APP_ADMIN || userId == process.env.REACT_APP_ADMIN2) {
                    // ownerIdを確認
                    if (user.ownerName) ownerName = user.ownerName;
                }

                return (
                    <TableRow key={`${user.name}_${index}`}>
                        <TableCell component="th" scope="row" align="center">
                            <Link to={`user/${user.id}`} style={{ textDecoration: 'none' }}>
                                {user.name}
                            </Link>
                        </TableCell>
                        <TableCell align="center">{checkDatetime}</TableCell>
                        <TableCell align="center"><Button variant="contained" color="primary">ダウンロード</Button></TableCell>
                        <TableCell align="center">
                            {userId === process.env.REACT_APP_ADMIN || userId == process.env.REACT_APP_ADMIN2 ?
                                ownerName
                                :
                                user.memo ?
                                    <ChatIcon color="primary" onClick={async () => {
                                        // メモモーダルを開く
                                        userForMemoRef.current = user;
                                        setIsOpenMemo(true);
                                    }}
                                    />
                                    :
                                    <ChatBubbleOutlineIcon onClick={async () => {
                                        // メモモーダルを開く
                                        userForMemoRef.current = user;
                                        setIsOpenMemo(true);
                                    }}
                                    />
                            }
                            {/* { user.memo ?
                                <ChatIcon color="primary" onClick={async () => {
                                        // メモモーダルを開く
                                        userForMemoRef.current = user;
                                        setIsOpenMemo(true);
                                    }}
                                />
                            :
                                <ChatBubbleOutlineIcon onClick={async () => {
                                        // メモモーダルを開く
                                        userForMemoRef.current = user;
                                        setIsOpenMemo(true);
                                    }}
                                />
                            } */}
                        </TableCell>
                    </TableRow>
                )
            }
            );
        } else {
            body = (
                <TableRow></TableRow>
            )
        }

        return body;
    }

    /*
     * render
     */
    return (
        <div>
            <TableContainer className={classes.root} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead className={classes.head}>
                        {/* .env 環境名に登録されたADMINの場合のみユーザの操作履歴を参照可能 */}
                        {userId === process.env.REACT_APP_ADMIN || userId == process.env.REACT_APP_ADMIN2 ?
                            createTableHeader(['利用者名', '操作日時', '操作', 'ユーザー名'])
                            :
                            createTableHeader(['利用者名', '操作日時', '操作', 'メモ'])
                        }
                    </TableHead>
                    <TableBody>
                        {createTableBody()}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );

}
// **** component ****