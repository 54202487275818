export const initialState = {
    users: [], // {id, name, socketId}

    userTokens: [],
    isCallingUserBackground: false,

    isShowVideo: false,
    isCallVideo: false,

    userVideo: null,
    streamVideo: null,

    socketRef: null,

    yourConnection: null,
    remoteStream: null,

    selectedUser: {name: ''},

};

/*
 * action: {type: xxx, payload: yyy}
 */
export const reducer = (state, action) => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
        console.log(action);
        console.log('state: ', state);
    }
    switch (action.type) {
        case "SET_SOCKET_REF": {
            return {
                ...state,
                socketRef: action.payload,
            };
        }
        case "SET_USER_VIDEO_REF": {
            return {
                ...state,
                userVideo: action.payload
            };
        }
        case "SET_STREAM_VIDEO_REF": {
            return {
                ...state,
                streamVideo: action.payload
            };
        }
        case "SET_CONNECTION": {
            return {
                ...state,
                yourConnection: action.payload
            };
        }
        case "SET_REMOTE_STREAM": {
            return {
                ...state,
                remoteStream: action.payload
            };
        }
        case "SET_SELECTED_USER": {
            return {
                ...state,
                selectedUser: action.payload,
            };
        }

        default:
            return state;
    }
}