import { useApp } from "../../context";
import React, { useState, useEffect } from "react";
// import { useHistory } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import {
  Modal,
  Paper,
  TextField,
  Button,
  Checkbox,
  Select,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  makeStyles,
  Avatar,
  MenuItem,
  Box,
  OutlinedInput,
  InputAdornment,
  InputLabel,
  Input,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";

import { Loader } from "../../component/Loader";

import { signUp } from "../../modules/FirebaseModule";

import { LoginBeforeTermOfService } from "../../component/LoginBeforeTermOfService";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { Visibility, VisibilityOff } from "@material-ui/icons";
// import { Icon, FormControlLabel, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

// スタイルの記述をする
const useStyles = makeStyles((theme) => ({
  // column: {
  //     margin: theme.spacing(8),
  //     padding: theme.spacing(0),
  //     alignItems: "center",
  //     justifyContent: "center",
  //     display: "flex",
  //   backgroundColor: theme.palette.background.default
  // },
  outer: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    width: 400,
    margin: `${theme.spacing(0)} auto`,
    // 2021/11/19 h.suzuki add ログイン画面ロゴ挿入対応
    marginBottom: "-40px",
    border: "box-shadow .15s ease-in-out",
  },
  card: {
    // minWidth: "540px",
    // minHeight: "540px",
    textAlign: "center",
    minWidth: "95%",
    minHeight: "auto",
    marginTop: theme.spacing(10),
    // 2022/01/13 h.suzuki add ロゴ配置変更対応
    // marginTop: theme.spacing(7),
  },
  header: {
    // 2021/11/19 h.suzuki add ログインバー配色対応
    textAlign: "center",
    // backgroundColor: theme.palette.background.default,
    // backgroundColor: "#ff9a94",
    backgroundImage: "linear-gradient(to bottom, #f794a4 0%, #fdd6bd 100%)",
    // backgroundImage: "linear-gradient(to bottom, #ffc3a0 0%, #ff9a94 100%)",
    // backgroundImage: "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)",
    color: "#333",
    // color: "#ffffff",
  },
  agree: {
    marginTop: "16px",
    textAlign: "center",
    minWidth: "95%",
    minHeight: "auto",
  },

  buttons: {
    marginTop: "16px",
    textAlign: "center",
    minWidth: "95%",
    minHeight: "auto",
  },
  row: {
    marginTop: "8px",
    textAlign: "center",
    minWidth: "75%",
    minHeight: "auto",
  },
  plate: {
    // textAlign: "left",
    // marginLeft: "20px",
  },
  footer: {
    // backgroundColor: "#8888881a",
  },
  closeButton: {
    // 画面下に固定
    position: "fixed",
    bottom: "1px",
    left: "50%",
    transform: "translate(0%, -50%)",
    backgroundColor: theme.palette.background.default,
  },
}));

export const SignUp = () => {
  const { stateAuth } = useApp();
  //   let history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();

  const [isLoader, setIsLoader] = useState(false);

  const [mail, setMail] = useState("");
  const categoryArray = [
    "デイサービス",
    "グループホーム",
    "訪問看護ステーション",
    "小規模多機能施設（小多機）",
    "特別養護老人ホーム（特養）",
    "介護老人保健施設（老健）",
    "看護多機能施設（看多機）",
    "ケアプランセンター",
    "病院・クリニック",
    "歯科クリニック",
    "訪問介護事業所",
    "訪問歯科",
    "調剤薬局",
    "家族",
    "その他",
  ];
  const prefArray = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];
  const [category, setCategory] = useState(categoryArray[0]);
  const [name, setName] = useState(""); // 事業所名
  const [nameKana, setNameKana] = useState(""); // 事業所カナ
  const [zip, setZip] = useState(""); // 郵便番号　（例：348-0071）
  const [prefectures, setPrefectures] = useState("");
  const [address1, setAddress1] = useState(""); // 台東区
  const [address2, setAddress2] = useState(""); // 根岸2-8-7
  const [building, setBuilding] = useState(""); // フェルクルール上野根岸６０１
  const [tel, setTel] = useState(""); // 電話番号
  const [fax, setFax] = useState(""); // FAX番号
  // const [staffName1, setStaffName1] = useState(''); // スタッフ氏名１
  // const [staffNameKana1, setStaffNameKana1] = useState(''); // スタッフ氏名カナ１
  // const [staffTel1, setStaffTel1] = useState(''); // スタッフ電話番号１
  // const [staffName2, setStaffName2] = useState(''); // スタッフ氏名２
  // const [staffNameKana2, setStaffNameKana2] = useState(''); // スタッフ氏名カナ２
  // const [staffTel2, setStaffTel2] = useState(''); // スタッフ電話番号２

  const [activeFlg, setActiveFlg] = useState(true); // とりあえず必要ない

  const [password, setPassword] = useState({
    password: "",
    // weight: '',
    // weightRange: '',
    showPassword: false,
  }); // パスワード
  const [passwordConfirm, setPasswordConfirm] = useState(""); // 確認用パスワード

  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [termsOfService, setTermsOfService] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  /*
   * 2022/01/18 h.suzuki add 利用規約・制約対応
   */
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  /*
   * バリデーション
   */
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    formState,
    control,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onBlur",
    criteriaMode: "all",
    // shouldFocusError: true,
    // shouldUnregister: true
    // defaultValues: {},
  });
  const onSubmit = (data) => {
    console.log(data);
    pushSignUpBtn();
  };
  /*
   * 2022/01/15 h.suzuki add TOPへ戻るボタン対応
   */
  const pushGoBackBtn = (data) => {
    console.log(data);
    // history.goBack();
    navigate(-1);
  };

  /*
   * ログインチェック
   */
  useEffect(() => {
    // ログインした状態でここにアクセスしたらホームへ飛ぶ
    // if (stateAuth.currentUser) history.replace("/");
    if (stateAuth.currentUser) navigate("/");
    return () => {};
    //   }, [stateAuth.currentUser, history]);
  }, [stateAuth.currentUser, navigate]);

  /*
   * method
   */
  const inputMail = (e) => {
    setMail(e.target.value);
  };

  const inputName = (e) => {
    setName(e.target.value);
  };

  const inputNameKana = (e) => {
    setNameKana(e.target.value);
  };

  const selectCategory = (e) => {
    setCategory(e.target.value);
  };

  const inputZip = (e) => {
    setZip(e.target.value);
  };

  const selectPrefectures = (e) => {
    setPrefectures(e.target.value);
  };

  const inputAddress1 = (e) => {
    setAddress1(e.target.value);
  };

  const inputAddress2 = (e) => {
    setAddress2(e.target.value);
  };

  const inputBuilding = (e) => {
    setBuilding(e.target.value);
  };

  const inputTel = (e) => {
    setTel(e.target.value);
  };

  const inputFax = (e) => {
    setFax(e.target.value);
  };

  // const inputStaffName1 = (e) => {
  //     setStaffName1(e.target.value);
  // }

  // const inputStaffNameKana1 = (e) => {
  //     setStaffNameKana1(e.target.value);
  // }

  // const inputStaffTel1 = (e) => {
  //     setStaffTel1(e.target.value);
  // }

  // const inputStaffName2 = (e) => {
  //     setStaffName2(e.target.value);
  // }

  // const inputStaffNameKana2 = (e) => {
  //     setStaffNameKana2(e.target.value);
  // }

  // const inputStaffTel2 = (e) => {
  //     setStaffTel2(e.target.value);
  // }

  const changeActiveFlg = (e) => {
    setActiveFlg(e.target.value);
  };

  const inputPassword = (e) => {
    setPassword(e.target.value);
  };

  const inputPasswordConfirm = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const changeCheckTermsOfService = (event) => {
    setTermsOfService(event.target.checked);
  };

  const pushSignUpBtn = async () => {
    setIsLoader(true);
    const option = {
      mail: mail,
      category: category,
      name: name,
      nameKana: nameKana,
      zip: zip,
      prefectures: prefectures,
      address1: address1,
      address2: address2,
      building: building,
      tel: tel,
      fax: fax,
      // staffName1: staffName1,
      // staffNameKana1: staffNameKana1,
      // staffTel1: staffTel1,
      // staffName2: staffName2,
      // staffNameKana2: staffNameKana2,
      // staffTel2: staffTel2,
      activeFlg: activeFlg,
      password: password,
    };
    if (termsOfService) {
      const user = await signUp(option);
      setIsLoader(false);
      if (user !== null) {
        // if (user !== undefined) history.replace("/");
        if (user !== undefined) navigate("/");
        else if (user === undefined)
          alert("正しく登録できませんでした。通信を確認しやり直してください。");
      }
    } else {
      setIsLoader(false);
      alert("利用規約をチェックして下さい");
    }
  };

  useEffect(() => {
    if (zip) {
      fetch(`https://api.zipaddress.net/?zipcode=${zip}`, {
        mode: "cors",
      })
        .then((result) => {
          return result.json();
        })
        .then((result) => {
          setPrefectures(result.data?.pref || "");
          setAddress1(result.data?.address || "");
        });
    }
  }, [zip]);

  /*
   * rendering
   */
  return (
    // <div className={classes.column}>
    //     { isLoader === true ? <Loader /> : ''}
    //     <br /><br /><br />
    <div className={classes.outer}>
      {isLoader === true ? <Loader /> : ""}

      <div
        style={{
          textAlign: "center",
          marginTop: "15px",
          marginBottom: "-65px",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/kaigo_college110.png`}
          style={{
            width: "65%",
            height: "auto",
          }}
        />
      </div>

      <Card className={classes.card}>
        <CardHeader className={classes.header} title="新規会員登録">
          avatar={<Avatar>P</Avatar>}, title="Profile",
          {/* subheader="プロフィール", */}
          alignItems: "center", justifyContent: "center",
          {/* < img src={`${process.env.PUBLIC_URL}/kaigo_college110.png`
                    }
                        style={{
                            width: "65%",
                            height: "auto",
                        }} /> */}
        </CardHeader>
        {/* <img src={`${process.env.PUBLIC_URL}/kaigo_college_menu_bar.png`} style{{ ''}} /> */}

        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <h1>会員登録</h1> */}
            <p>必要な情報を入力してください</p>
            {/*
             * 事業所
             */}
            {/* <p className={classes.plate}>事業所名</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="事業所名"
              type="text"
              value={name}
              onChange={inputName}
              name="name"
              error={!!errors.name}
              helperText={errors?.name?.message}
              inputRef={register({
                required: "*必須項目",
                maxLength: 40,
              })}
            />
            {/* {errors.name && <p>{errors.name.message}</p>} */}
            {/* <p className={classes.plate}>フリガナ</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="ふりがな"
              type="text"
              value={nameKana}
              onChange={inputNameKana}
              name="nameKana"
              error={!!errors.nameKana}
              helperText={errors?.nameKana?.message}
              inputRef={register({
                required: "*必須項目",
                maxLength: 40,
              })}
            />
            {/* {errors.nameKana && <p>{errors.nameKana.message}</p>} */}
            {/* <p className={classes.plate}>メールアドレス</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="メール"
              type="text"
              value={mail}
              onChange={inputMail}
              placeholder={`info@rudolf.co.jp`}
              name="mail"
              error={!!errors.mail}
              helperText={errors?.mail?.message}
              inputRef={register({
                required: "*必須項目",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "メールアドレスが正しくありません",
                },
              })}
            />
            {/* {errors.mail && <p>{errors.mail.message}</p>} */}
            <Select
              className={classes.row}
              native
              id="outlined-required"
              label="カテゴリ"
              value={category}
              onChange={selectCategory}
              placeholder={`選択してください`}
              name="category"
              error={!!errors.category}
              helperText={errors?.category?.message}
              inputRef={register({
                required: "*必須項目",
                maxLength: 20,
              })}
            >
              {categoryArray.map((option) => {
                return <option value={option}>{option}</option>;
              })}
              {/* {PREF_OPTIONS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))} */}
            </Select>
            {/* <p className={classes.plate}>事業所カテゴリ</p>
                        <FormControl error={!!errors.category}>
                            <FormHelperText>{errors?.category?.message}</FormHelperText>
                            <Controller
                                name="radiogroup"
                                value={category}
                                defaultValue={categoryArray[0]}
                                onChange={selectCategory}
                                as={
                                    <RadioGroup
                                        aria-label="category"
                                        name="category"
                                    >
                                        {categoryArray.map((item, index) => {
                                            return (
                                                <FormControlLabel
                                                    key={`${item} _${index}`}
                                                    value={item}
                                                    control={<Radio />}
                                                    label={item}
                                                />
                                            );
                                        })}
                                    </RadioGroup>
                                }
                                control={control}
                            />
                        </FormControl> */}
            {/* <p className={classes.plate}>郵便番号</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="郵便番号"
              type="text"
              value={zip}
              onChange={inputZip}
              onKeyUp="AjaxZip3.zip2addr(this, '', 'prefectures', 'address1','address2');"
              placeholder={`100-0001`}
              name="zip"
              error={!!errors.zip}
              helperText={errors?.zip?.message}
              inputRef={register({
                required: "*必須項目",
                pattern: {
                  value: /^[0-9]{3}-{1}[0-9]{4}$/,
                  message: "xxx-xxxxで入力して下さい。(xは半角数字)",
                },
              })}
            />{" "}
            {/* <Button type="button" onclick="AjaxZip3.zip2addr('postal1', 'postal2', 'prefecture', 'city');">住所を自動入力</Button> */}
            {/* {errors.zip && <p>{errors.zip.message}</p>} */}
            {/* <p className={classes.plate}>都道府県</p> */}
            {/* <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        > */}
            <Select
              className={classes.row}
              native
              id="outlined-required"
              label="都道府県"
              value={prefectures}
              onChange={selectPrefectures}
              placeholder={`選択してください`}
              name="prefectures"
              error={!!errors.prefectures}
              helperText={errors?.prefectures?.message}
              inputRef={register({
                required: "*必須項目",
                maxLength: 5,
              })}
            >
              {prefArray.map((option) => {
                return <option value={option}>{option}</option>;
              })}
              {/* {PREF_OPTIONS.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))} */}
            </Select>
            {/* 2022/01/15 h.suzuki add 都道府県SelectBox対応 */}
            {/* <Select native
                            label="都道府県"
                            value={prefectures}
                            onChange={selectPrefectures}
                            placeholder={`東京都`}
                            name="prefectures"
                            error={!!errors.prefectures}
                            helperText={errors?.prefectures?.message}
                            inputRef={register({
                                required: "*必須項目",
                                maxLength: 8,
                            })}
                        >
                            {PREF_OPTIONS.map(option => {
                                return (<option value={option}>{option}</option>)
                            })}
                        </Select> */}
            {/* </Box> */}
            {/* {errors.prefectures && <p>{errors.prefectures.message}</p>} */}
            {/* <p className={classes.plate}>市区町村</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="市区町村"
              type="text"
              value={address1}
              onChange={inputAddress1}
              placeholder={`市区町村`}
              name="address1"
              error={!!errors.address1}
              helperText={errors?.address1?.message}
              inputRef={register({
                required: "*必須項目",
                maxLength: 40,
              })}
            />
            {/* {errors.address1 && <p>{errors.address1.message}</p>} */}
            {/* <p className={classes.plate}>番地</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="番地"
              type="text"
              value={address2}
              onChange={inputAddress2}
              placeholder={`〇-〇-〇`}
              name="address2"
              error={!!errors.address2}
              helperText={errors?.address2?.message}
              inputRef={register({
                required: "*必須項目",
                maxLength: 40,
              })}
            />
            {/* {errors.address2 && <p>{errors.address2.message}</p>} */}
            {/* <p className={classes.plate}>建物名</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="建物名"
              type="text"
              value={building}
              onChange={inputBuilding}
              placeholder={`RuDoLf A-Room`}
              name="building"
              // error={!!errors.building}
              // helperText={errors?.building?.message}
              // inputRef={register({
              //     required: "*必須項目",
              //     maxLength: 40,
              // })}
            />
            {/* {errors.building && <p>{errors.building.message}</p>} */}
            {/* <p className={classes.plate}>電話番号</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              id="outlined-required"
              label="電話番号"
              type="text"
              value={tel}
              onChange={inputTel}
              placeholder={`09012345678`}
              name="tel"
              error={!!errors.tel}
              helperText={errors?.tel?.message}
              inputRef={register({
                required: "*必須項目",
                pattern: {
                  value: /^[0-9]+$/,
                  message: "半角数字のみで入力して下さい",
                },
                minLength: {
                  value: 10,
                  message: "電話番号を入力してください",
                },
              })}
            />
            {/* {errors.tel && <p>{errors.tel.message}</p>} */}
            {/* <p className={classes.plate}>ファックス</p> */}
            <TextField
              className={classes.row}
              // required
              // error
              // id="outlined-error"
              // label="Error"
              // id="outlined-required"
              label="FAX"
              type="text"
              value={fax}
              onChange={inputFax}
              placeholder={`09012345678`}
              name="fax"
              // error={!!errors.fax}
              // helperText={errors?.fax?.message}
              // inputRef={register({
              //     required: "*必須項目",
              //     pattern: {
              //         value: /^[0-9]+$/,
              //         message: "半角数字のみで入力して下さい"
              //     },
              //     minLength: {
              //         value: 10,
              //         message: "ファックス番号を入力してください"
              //     },
              // })}
            />
            {/* {errors.fax && <p>{errors.fax.message}</p>} */}
            {/*
             * スタッフ
             */}
            {/* 2022/01/15 h.suzuki add 介護カレッジには不要なため、コメントアウト */}
            {/* <p className={classes.plate}>スタッフ1</p>
                <TextField
                    type="text"
                    value={staffName1}
                    onChange={inputStaffName1}
                    placeholder={`スタッフ名`}
                    name="staffName1"
                    error={!!errors.staffName1}
                    helperText={errors?.staffName1?.message}
                    inputRef={register({
                        required: "*必須項目",
                        maxLength: 40,
                    })}
                /> */}
            {/* {errors.staffName1 && <p>{errors.staffName1.message}</p>} */}
            {/* <p className={classes.plate}>フリガナ</p>
                <TextField
                    type="text"
                    value={staffNameKana1}
                    onChange={inputStaffNameKana1}
                    placeholder={`フリガナ`}
                    name="staffNameKana1"
                    error={!!errors.staffNameKana1}
                    helperText={errors?.staffNameKana1?.message}
                    inputRef={register({
                        required: "*必須項目",
                        maxLength: 40,
                    })}
                /> */}
            {/* {errors.staffNameKana1 && <p>{errors.staffNameKana1.message}</p>} */}
            {/* <p className={classes.plate}>スタッフ1電話番号</p>
                <TextField
                    type="text"
                    value={staffTel1}
                    onChange={inputStaffTel1}
                    placeholder={`09012345678`}
                    name="staffTel1"
                    error={!!errors.staffTel1}
                    helperText={errors?.staffTel1?.message}
                    inputRef={register({
                        required: "*必須項目",
                        pattern: {
                            value: /^[0-9]+$/,
                            message: "半角数字のみで入力して下さい"
                        },
                        minLength: {
                            value: 10,
                            message: "電話番号を入力してください"
                        },
                    })}
                /> */}
            {/* {errors.staffTel1 && <p>{errors.staffTel1.message}</p>} */}
            {/* <p className={classes.plate}>スタッフ2</p>
                <TextField
                    type="text"
                    value={staffName2}
                    onChange={inputStaffName2}
                    placeholder={`スタッフ名`}
                    name="staffName2"
                // error={!!errors.staffName2}
                // helperText={errors?.staffName2?.message}
                // inputRef={register({
                //     required: "*必須項目",
                //     maxLength: 40,
                // })}
                /> */}
            {/* {errors.staffName2 && <p>{errors.staffName2.message}</p>} */}
            {/* <p className={classes.plate}>フリガナ</p>
                <TextField
                    type="text"
                    value={staffNameKana2}
                    onChange={inputStaffNameKana2}
                    placeholder={`フリガナ`}
                    name="staffNameKana2"
                // error={!!errors.staffNameKana2}
                // helperText={errors?.staffNameKana2?.message}
                // inputRef={register({
                //     required: "*必須項目",
                //     maxLength: 40,
                // })}
                /> */}
            {/* {errors.staffNameKana2 && <p>{errors.staffNameKana2.message}</p>} */}
            {/* <p className={classes.plate}>スタッフ2電話番号</p>
                <TextField
                    type="text"
                    value={staffTel2}
                    onChange={inputStaffTel2}
                    placeholder={`09012345678`}
                    name="staffTel2"
                // error={!!errors.staffTel2}
                // helperText={errors?.staffTel2?.message}
                // inputRef={register({
                //     required: "*必須項目",
                //     pattern: {
                //         value: /^[0-9]+$/,
                //         message: "半角数字のみで入力して下さい"
                //     },
                //     minLength: {
                //         value: 10,
                //         message: "電話番号を入力してください"
                //     },
                // })}
                /> */}
            {/* {errors.staffTel2 && <p>{errors.staffTel2.message}</p>} */}
            {/*
             * password
             */}
            {/* <p className={classes.plate}>パスワード</p> */}
            {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">パスワード</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleChange('password')}
                                helperText={errors?.password?.message}
                                inputRef={register({
                                    required: "パスワードを設定してください",
                                    minLength: {
                                        value: 8,
                                        message: "パスワードは8文字以上で入力して下さい"
                                    }
                                })}

                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl> */}
            <TextField
              className={classes.row}
              id="outlined-required"
              label="パスワード"
              type="password"
              value={password}
              onChange={inputPassword}
              name="password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              inputRef={register({
                required: "パスワードを設定してください",
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上で入力して下さい",
                },
              })}
            />
            {/* {errors.password && <p>{errors.password.message}</p>} */}
            {/* <p className={classes.plate}>パスワード（確認）</p> */}
            {/* <FormControl sx={{ m: 1, width: '20ch' }} variant="standard">
                            <InputLabel htmlFor="standard-adornment-password">確認用パスワード</InputLabel>
                            <Input
                                id="standard-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={passwordConfirm}
                                onChange={inputPasswordConfirm}
                                name="passwordConfirm"
                                error={!!errors.passwordConfirm}
                                helperText={errors?.passwordConfirm?.message}
                                inputRef={register({
                                    validate: value =>
                                        // value is from confirm and watch will return value from password
                                        value === getValues('password') || <span>パスワードが一致しません</span>,
                                    required: 'もう一度パスワードを入力してください',
                                    minLength: { value: 8, message: 'パスワードは8文字以上で入力して下さい' }
                                })}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl> */}
            <TextField
              className={classes.row}
              id="outlined-required"
              label="確認用パスワード"
              type="password"
              value={passwordConfirm}
              onChange={inputPasswordConfirm}
              name="passwordConfirm"
              error={!!errors.passwordConfirm}
              helperText={errors?.passwordConfirm?.message}
              inputRef={register({
                validate: (value) =>
                  // value is from confirm and watch will return value from password
                  value === getValues("password") || (
                    <span>パスワードが一致しません</span>
                  ),
                required: "もう一度パスワードを入力してください",
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上で入力して下さい",
                },
              })}
            />
            {/* {errors.passwordConfirm && <p>{errors.passwordConfirm.message}</p>} */}
            <div className={classes.footer}>
              <div className={classes.row} style={{ paddingTop: "20px" }}>
                <Checkbox
                  checked={termsOfService}
                  color="primary"
                  onChange={changeCheckTermsOfService}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                {/* <Link to="/loginbeforetermofservice">利用規約</Link><p>に同意する</p> */}
                {/* <span style={{ color: 'skyblue' }} onClick={() => { setModalOpen(true) }}>利用規約</span><span>に同意する</span> */}
                <span
                  style={{ color: "skyblue" }}
                  onClick={handleClickOpen("paper")}
                >
                  利用規約
                </span>
                <span>に同意する</span>
              </div>
              {/* <Button
                                onClick={handleClickOpen('paper')}
                                variant="contained" color="primary"
                                className={classes.buttons}
                            >利用規約</Button> */}
              <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
              >
                <DialogTitle id="scroll-dialog-title">利用規約</DialogTitle>
                <DialogContent dividers={scroll === "paper"}>
                  <DialogContentText
                    id="scroll-dialog-description"
                    ref={descriptionElementRef}
                    tabIndex={-1}
                  >
                    {/* {[...new Array(1)]
                                            .map(
                                                () => ` */}
                    <Typography gutterBottom>
                      本利用規約（以下「本規約」といいます。）は、当社が提供するサービス「介護カレッジ（別称：カイゴカレッジ）」（以下「本サービス」といいます。）をご利用される全ての方に適用されます。本サービスの利用に際しては、本規約の全文をお読みのうえ、本規約に同意いただいた上で本サービスをご利用ください。
                    </Typography>
                    <Typography gutterBottom>第1条（内容）</Typography>
                    <Typography gutterBottom>
                      1.
                      本規約は、当社ウェブサイト、本サービスの利用に関する当社と利用者との間の権利義務関係を定めることを目的とし、利用者と当社との間の本サービスの利用に関わる一切の関係に適用されます。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      利用者は本規約のほか、当社ウェブサイト、本サービス、当社から利用者へ送付する電子メール等に表示した注意事項等を遵守するものとします。
                    </Typography>
                    <Typography gutterBottom>第2条（定義）</Typography>
                    <Typography gutterBottom>
                      ⑴ 「当社」とは、株式会社アール・シーのことをいいます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑵
                      「サービス利用契約」とは、本規約及び当社と会員の間で締結する、本サービスの利用契約のことをいいます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑶
                      「当社ウェブサイト」とは、そのドメインが「kaigo-college.com」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）のことをいいます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑷
                      「本サービス」とは、当社が提供するサービス「介護カレッジ（別称：カイゴカレッジ）」のことをいいます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑸
                      「本サービス」とは、当社が運営する本サービスを提供するサービスケーション及び関連ソフトウェアのことをいいます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑹
                      「会員」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人のことをいいます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑺
                      「事業者等」とは本サービスに登録している事業者のことをいいます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑻
                      「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）のことをいいます。
                    </Typography>
                    <Typography gutterBottom>第3条（登録）</Typography>
                    <Typography gutterBottom>
                      1.
                      本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の登録事項を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録申請をすることができます。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      登録希望者が前項に従って登録申請を完了させた時点で会員登録が完了したものとします。
                    </Typography>
                    <Typography gutterBottom>
                      3.
                      前項に定める登録の完了時に、会員と当社との間でサービス利用契約が成立し、会員は本サービスを本規約に従い利用することが可能になります。
                    </Typography>
                    <Typography gutterBottom>
                      4.
                      当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      ⑴
                      当社に提供した情報の全部または一部につき虚偽、誤記または記載漏れがあった場合
                    </Typography>
                    <Typography gutterBottom>⑵ １８歳未満の場合</Typography>
                    <Typography gutterBottom>
                      ⑶
                      成年被後見人、被保佐人または被補助人のいずれかであり、後見人、保佐人または補助人の同意等を得ていなかった場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑷
                      反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者をいいます。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑸
                      登録希望者が過去当社との契約に違反した者またはその関係者であると当社が判断した場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑹ 第15条に定める措置を受けたことがある場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑺ その他、当社が登録を適当でないと判断した場合
                    </Typography>
                    <Typography gutterBottom>
                      第4条（登録事項の変更）
                    </Typography>
                    <Typography gutterBottom>
                      会員は、登録事項に変更があった場合、当社の定める方法により当該変更事項を速やかに当社に届け出るものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第5条（パスワード及びユーザーIDの管理）
                    </Typography>
                    <Typography gutterBottom>
                      1.
                      会員は、自己の責任において、本サービスに関するユーザーＩＤ及びパスワードを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      ユーザーＩＤまたはパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は会員が負うものとし、当社は一切の責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      第6条（Eラーニング研修の受講およびテスト受講）
                    </Typography>
                    <Typography gutterBottom>
                      1.会員は、本サービスを通じて、Eラーニング研修受講が可能となります。
                    </Typography>
                    <Typography gutterBottom>
                      2.会員は、複数の会員から時期を同じくして、同じ講師等に対するEラーニング研修受講の日時が重複する予約申込がなされた場合、本サービスのサーバの処理によって予約が成立する会員が決定することを承諾するものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第7条（Eラーニング研修受講の提供）
                    </Typography>
                    <Typography gutterBottom>
                      1.Eラーニング研修受講の提供は契約先サーバのシステム処理によって行われます。当社契約先サーバシステムの不具合や障害発生の影響による提供の中断や、停止については、当社はこれに一切関与するものではありません。
                    </Typography>
                    <Typography gutterBottom>
                      2.会員は、本サービスの利用時間に、閲覧・操作・動画視聴・テスト受講が含まれていることを予め承諾するものとします。
                    </Typography>
                    <Typography gutterBottom>
                      3.会員は、予め契約時に定められた、利用時間を超過した場合、その理由の如何にかかわらず、これに伴う超過課金請求等の措置に対し、予め承諾するものとします。
                    </Typography>
                    <Typography gutterBottom>
                      4.会員は、本サービスの提供に関する全てのトラブルについて、当社が一切責任を負うものではないことを予め承諾するものとします。
                    </Typography>
                    <Typography gutterBottom>第9条（支払方法）</Typography>
                    <Typography gutterBottom>
                      1.会員は、当社が指定する方法にて料金の決済を行うものとします。
                    </Typography>
                    <Typography gutterBottom>
                      2.会員が決済に利用しようとしたクレジットカードにつき、カード会社がその利用を拒否した場合、当社は、会員の同意を得ることなく、予約成立の不承諾、支払方法の変更、予約の取消、その他当社が必要と認める措置のいずれかを行うものとし、会員はこの措置につき一切異議を述べず、これにより生じた損害につき、当社にその責任を追及しないものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第10条（返金および取消）
                    </Typography>
                    <Typography gutterBottom>
                      1.契約完了後、本サービスの都合等により、本サービスの提供を中止する場合があり、会員はあらかじめこれを承諾するものとします。
                    </Typography>
                    <Typography gutterBottom>
                      2.前項の場合、会員又は当社は契約の取消を行うことができるものとし、会員は当社に対し、契約完了時に決済した金額の返金を請求することができるものとします。この場合、会員は当社所定の方法により、手続を行うものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第11条（本サービスの使用）
                    </Typography>
                    <Typography gutterBottom>
                      1.当社は、利用者に対し、当社の許容する範囲で、非独占的に利用者の本サービスに対応したパソコン、タブレット、スマートフォン等（以下、「情報端末等」と言います。）にて、本サービスを使用することを許諾するものとします。
                    </Typography>
                    <Typography gutterBottom>
                      2.当社は、本サービスが全ての情報端末等に対応することを保証するものではありません。
                    </Typography>
                    <Typography gutterBottom>
                      3.
                      本サービスは、利用者が個人で使用する目的でのみ、利用可能であり、その他の目的で使用はできないものとします。
                    </Typography>
                    <Typography gutterBottom>
                      4.
                      利用者は、本サービスを、当社から提供された状態でのみ利用しなければならず、本サービスの変更、修正、改変、翻案又は複製はいずれも禁止となります。
                    </Typography>
                    <Typography gutterBottom>
                      5.
                      利用者による、当社から許諾された本サービスに関する一切のプログラムの一部又は全部を変更、修正、改変、翻案又は複製、第三者への開示はいずれも禁止します。
                    </Typography>
                    <Typography gutterBottom>第12条（禁止事項）</Typography>
                    <Typography gutterBottom>
                      利用者は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
                    </Typography>
                    <Typography gutterBottom>
                      ⑴ 事業者師等に対する無断取消
                    </Typography>
                    <Typography gutterBottom>
                      ⑵
                      当社、本サービスの他の利用者また事業者師等その他の第三者（以下「第三者等」）に対する詐欺、脅迫、暴力行為
                    </Typography>
                    <Typography gutterBottom>⑶ 公序良俗に反する行為</Typography>
                    <Typography gutterBottom>
                      ⑷
                      当社及び第三者等の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
                    </Typography>
                    <Typography gutterBottom>
                      ⑸
                      以下に該当し、または該当すると当社が判断する情報を当社または第三者等に送信すること
                    </Typography>
                    <Typography gutterBottom>
                      ・過度に暴力的または残虐な表現を含む情報
                    </Typography>
                    <Typography gutterBottom>
                      ・コンピュータウィルスその他の有害なコンピュータプログラムを含む情報
                    </Typography>
                    <Typography gutterBottom>
                      ・当社及び第三者等の名誉または信用を毀損する表現を含む情報
                    </Typography>
                    <Typography gutterBottom>
                      ・反社会的な表現を含む情報
                    </Typography>
                    <Typography gutterBottom>
                      ⑹
                      本サービスのネットワークまたはシステム等に過度な負荷をかける行為
                    </Typography>
                    <Typography gutterBottom>
                      ⑺ 本サービスの運営を妨害するおそれのある行為
                    </Typography>
                    <Typography gutterBottom>
                      ⑻
                      当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを試みる行為
                    </Typography>
                    <Typography gutterBottom>
                      ⑼ 第三者に成りすます行為
                    </Typography>
                    <Typography gutterBottom>
                      ⑽ 本サービスの他の利用者のIDまたはパスワードを利用する行為
                    </Typography>
                    <Typography gutterBottom>
                      ⑾
                      当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                    </Typography>
                    <Typography gutterBottom>
                      ⑿ 本サービスの他の利用者及び治療院等の情報の収集
                    </Typography>
                    <Typography gutterBottom>
                      ⒀ 当社及び第三者等に不利益、損害、不快感を与える行為
                    </Typography>
                    <Typography gutterBottom>
                      ⒁ 当社の定める規約に違反する行為
                    </Typography>
                    <Typography gutterBottom>
                      ⒂ 反社会的勢力等への利益供与
                    </Typography>
                    <Typography gutterBottom>
                      ⒃ 本サービス利用時に虚偽の内容を申告する行為
                    </Typography>
                    <Typography gutterBottom>
                      ⒄ 当社が承認した方法以外に本サービスを利用する行為
                    </Typography>
                    <Typography gutterBottom>
                      ⒅
                      前各号の行為を直接または間接に惹起し、または容易にする行為
                    </Typography>
                    <Typography gutterBottom>
                      ⒆
                      法律および本規約に違反する行為または違反のおそれのある行為
                    </Typography>
                    <Typography gutterBottom>
                      ⒇ その他、当社が不適切と判断する行為
                    </Typography>
                    <Typography gutterBottom>
                      第13条（本サービスの停止等）
                    </Typography>
                    <Typography gutterBottom>
                      1.
                      当社は、以下のいずれかに該当する場合には、会員に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
                    </Typography>
                    <Typography gutterBottom>
                      ⑴
                      本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑵ コンピュータ、通信回線等が事故により停止した場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑶
                      地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑷ その他、当社が停止または中断を必要と判断した場合
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      当社は、本条に基づき当社が行った措置に基づき会員に生じた損害について一切の責任を負いません。
                    </Typography>
                    <Typography gutterBottom>第14条（権利帰属）</Typography>
                    <Typography gutterBottom>
                      本サービス、当社ウェブサイトに関する知的財産権は全て当社または当社に知的財産権の実施許諾をしている者に帰属します。本規約による本サービスの利用許諾は、この知的財産権の使用許諾にはあたりません。
                    </Typography>
                    <Typography gutterBottom>第15条（登録抹消等）</Typography>
                    <Typography gutterBottom>
                      1.
                      当社は、会員が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、当該会員について本サービスの利用を一時的に停止し、または会員としての登録を抹消、もしくはサービス利用契約を解除することができます。
                    </Typography>
                    <Typography gutterBottom>
                      ⑴ 本規約のいずれかの条項に違反した場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑵ 登録事項に虚偽の事実があることが判明した場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑶ 第3条第4項各号に該当する場合
                    </Typography>
                    <Typography gutterBottom>
                      ⑷
                      その他、当社が本サービスの利用、会員としての登録、またはサービス利用契約の継続を適当でないと判断した場合
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      前項各号のいずれかの事由に該当した場合、会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                    </Typography>
                    <Typography gutterBottom>
                      3.
                      当社は、本条に基づき当社が行った行為により会員に生じた損害について一切の責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      4．利用者が第1項に該当することにより、当社または第三者に損害が発生した場合には、当該利用者は、自己の責任と費用をもって、当該損害の一切を賠償する義務を負うものとします。
                    </Typography>
                    <Typography gutterBottom>第16条（退会、解約）</Typography>
                    <Typography gutterBottom>
                      1.
                      会員は、当社所定の方法により、本サービスから退会し、自己の会員としての登録を抹消し、本サービス利用契約を解約することができます。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      退会にあたり、当社に対して負っている債務が有る場合は、会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                    </Typography>
                    <Typography gutterBottom>
                      3.
                      退会後の利用者情報の取扱いについては、第20条の規定に従うものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第17条（本サービスの内容の変更、終了）
                    </Typography>
                    <Typography gutterBottom>
                      1.
                      当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      当社は、本条に基づき当社が行った措置に基づき会員に生じた損害について一切の責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      第18条（保証の否認及び免責）
                    </Typography>
                    <Typography gutterBottom>
                      1．当社は、本サービス及び本サービスを通じて受ける役務等が会員の特定の目的に適合すること、期待する機能、価値、正確性、有用性を有すること、会員による本サービスの利用が会員に適用のある法令または各種規則等に適合することについて何ら保証するものではありません。
                    </Typography>
                    <Typography gutterBottom>
                      2．当社は、会員が本サービスを通じて得た情報の正確性、合法性、道徳性、著作権の許諾等について一切責任を負いません（当社が提供した情報、会員が提供した情報、リンク先、広告、その他あらゆる情報を含みます）。
                    </Typography>
                    <Typography gutterBottom>
                      3．当社は、システム上の障害により本サービスが中断・遅延し、当社または会員が提供した情報、ウェブおよびモバイルページ、電子メール等が毀損・消滅し、または電子メールが誤配されたとしても、一切責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      4．当社は、本サービス利用に関し会員相互または会員と第三者との間で紛争が生じたとしても、一切責任を負いません。紛争の当事者同士で解決をお願いいたします。
                    </Typography>
                    <Typography gutterBottom>
                      5．当社は、本サービス上に他のインターネットサービスとのリンクを設けることができます。それらのインターネットサービスの利用に関し、当社は一切責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      6．当社は、本サービス上にプログラム等のダウンロードリンクを設けることができます。そのプログラム等を実行した結果、会員等のコンピュータや携帯電話上のプログラム・データ等が毀損する等の損害が生じたとしても、当社は一切責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      7．当社は、ハッキングその他の不正アクセスにより会員に被害が生ずることの
                      ないよう、SSL（Secure Sockets
                      Layer）の利用、ファイアウォール設置その他の合理的な措置を講じます。これらの措置にもかかわらず不正アクセスが行われ会員に損害が生じた場合、当社は責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      8．会員は、本サービスを利用するにあたっては、コンピュータウィルス等をチェックするための合理的な措置を講ずるものとします。当社は、コンピュータウィルス等による損害に関し、一切責任を負いません。
                    </Typography>
                    <Typography gutterBottom>
                      9．当社が責任を負う場合であっても、当社はその損害の発生につき故意または重過失がある場合に限り、その直接かつ現実に生じた通常の損害につき賠償する責任を負うものとします。
                    </Typography>
                    <Typography gutterBottom>第19条（秘密保持）</Typography>
                    <Typography gutterBottom>
                      会員は、本サービスに関連して当社が会員に対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第20条（個人情報の取扱い）
                    </Typography>
                    <Typography gutterBottom>
                      1.
                      当社による会員の利用者情報の取扱いについては、本規約及び別途当社が定める「個人情報の取り扱いについて」（リンクアドレスを記入してください）の定めによるものとし、会員はこれらの定めに従って当社が会員の利用者情報を取扱うことについて同意するものとします。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      当社は、会員が当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、会員はこれに異議を唱えないものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第21条（本規約等の変更）
                    </Typography>
                    <Typography gutterBottom>
                      当社は、本規約を変更できるものとします。本規約の変更は、変更後の本規約が当社ウェブサイト上に表示された時点でその効力を生じるものとします。本規約を変更後会員が本サービスを利用した場合または当社の定める期間内に退会の手続をとらなかった場合には、会員は本規約の変更に同意したものとみなします。
                    </Typography>
                    <Typography gutterBottom>第22条（連絡／通知）</Typography>
                    <Typography gutterBottom>
                      本サービスに関する問い合わせその他会員から当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から会員に対する連絡または通知は、当社の定める方法で行うものとします。
                    </Typography>
                    <Typography gutterBottom>第23条（責任）</Typography>
                    <Typography gutterBottom>
                      1．当社は会員の登録事項に従い事務を処理するものとし、会員は当社が会員の登録事項に従って行った事務処理に対し異議を述べないものとします。
                    </Typography>
                    <Typography gutterBottom>
                      2．当社スタッフ以外の第三者が物品、サービス、情報等に対して行った弊社の許可無き行為については一切の損害賠償責任その他いかなる責任も負いません。弊社は本サービスの利用により発生した利用者の損害(他者との間で生じたトラブルに起因する損害を含みます)及び本サービスを利用出来なかったことにより発生した利用者の損害に対し、損害賠償責任その他いかなる責任も負わないものとします。但し、当社に故意または重過失がある場合には、その直接かつ現実に生じた通常の損害につき賠償する責任を負うものとします。
                    </Typography>
                    <Typography gutterBottom>
                      3．利用者が本規約に違反したことにより、当社に損害が発生した場合、当社は利用者に対し、損害賠償請求をすることができるものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第24条（サービス利用契約上の地位の譲渡等）
                    </Typography>
                    <Typography gutterBottom>
                      1.
                      会員は、当社の書面による事前の承諾なく、サービス利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。また、当社はこれらの承諾の義務を負うものではありません。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴いサービス利用契約上の地位、本規約に基づく権利及び義務並びに会員の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
                    </Typography>
                    <Typography gutterBottom>第25条（分離可能性）</Typography>
                    <Typography gutterBottom>
                      本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
                    </Typography>
                    <Typography gutterBottom>
                      第26条（準拠法及び管轄裁判所）
                    </Typography>
                    <Typography gutterBottom>
                      1. 本規約及びサービス利用契約の準拠法は日本法とします。
                    </Typography>
                    <Typography gutterBottom>
                      2.
                      本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                    </Typography>
                    <Typography gutterBottom style={{ textAlign: "right" }}>
                      【2022年XX月XX日 施行】
                    </Typography>
                    {/* `,
                                            )
                                            .join('\n\r')} */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    onClick={handleClickOpen("paper")}
                    variant="contained"
                    color="primary"
                    // className={classes.buttons}
                  >
                    同意する
                  </Button>
                  <Button
                    onClick={handleClose}
                    onClick={handleClickOpen("paper")}
                    variant="contained"
                    color="primary"
                    // className={classes.buttons}
                  >
                    同意しない
                  </Button>
                </DialogActions>
              </Dialog>

              <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <Paper
                  style={{
                    position: "absolute",
                    overflow: "scroll",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    border: "none",
                  }}
                >
                  <LoginBeforeTermOfService />
                  <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Paper>
              </Modal>

              <Button
                className={classes.buttons}
                variant="contained"
                color="primary"
                type="submit"
                // onClick={pushSignUpBtn}
                // disabled={!formState.isValid}
              >
                新規登録する
              </Button>
              {/* </div>
                            <div style={{
                                textAlign: "center",
                                margin: "16px",
                            }} > */}
              <Button
                className={classes.buttons}
                variant="contained"
                color="primary"
                type="button"
                onClick={pushGoBackBtn}
                // disabled={!formState.isValid}
              >
                TOPページへ
              </Button>
              {/* </div> */}
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};
