import axios from "axios";
// CORS対策
if (process.env.REACT_APP_NODE_ENV === "development") {
  axios.defaults.withCredentials = true;
  console.log("process.env : ", process.env.REACT_APP_NODE_ENV);
}

// export const serverGet = async (url) => {
//   let res;
//   try {
//     res = await axios.get(url);
//     if (process.env.REACT_APP_NODE_ENV === "development")
//       console.log("axios get res: ", res); // res.data
//   } catch (e) {
//     alert(e.message);
//   }
//   return res;
// };

// 20231221 taniguchi add
export const serverGet = async (url) => {
  try {
    const res = await axios.get(url);
    if (process.env.REACT_APP_NODE_ENV === "development")
      console.log("Ajax.js res: ", res); // res.data
    return res; // データが正常に取得できた場合はレスポンスを返す
  } catch (e) {
    console.error(e); // エラーをコンソールに出力
    throw e; // エラーを再スローして呼び出し元で処理できるようにする
  }
};

export const serverPost = async (url, data) => {
  let res;
  try {
    res = await axios.post(url, data);
    if (process.env.REACT_APP_NODE_ENV === "development") console.log(res); // res.data
  } catch (e) {
    alert(e.message);
  }
  return res;
};
